import React from 'react';
import { SvgIcon } from '@mui/material';
const SvgFlagCn = (props) => (
  <SvgIcon {...props} title="FlagCn">
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#flagCN_svg__a)">
        <path
          d="M.125 3.25c0-1.726 1.4-3.125 3.125-3.125h7.5c1.726 0 3.125 1.4 3.125 3.125v7.5c0 1.726-1.4 3.125-3.125 3.125h-7.5A3.125 3.125 0 0 1 .125 10.75v-7.5Z"
          fill="#EE1C25"
        />
        <path
          d="M5.625 2.944 2.119 5.213 3.562 1.5 4.8 5.213l-3.3-2.27h4.125ZM6.478 1.012l1.25.612-1.308.225.847-.99-.081 1.33-.708-1.177ZM8.07 2.227l.914 1.05-1.293-.301 1.167-.583-.593 1.195-.194-1.36ZM8.359 4.22l.405 1.333-1.058-.804 1.304-.056-1.03.85.379-1.323ZM7.269 5.646l-.14 1.385-.665-1.15 1.223.452-1.277.386.859-1.073Z"
          fill="#FF0"
        />
      </g>
      <defs>
        <clipPath id="flagCN_svg__a">
          <path
            fill="#fff"
            transform="translate(.125 .125)"
            d="M0 0h13.75v13.75H0z"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
export default SvgFlagCn;
