import { useEffect, useState, useCallback } from 'react';
import { Box, Modal, Backdrop, Fade } from '@mui/material';

import { AccuisitionModalContent } from './AccuisitionModalContent';
import { recordAsModalSeen, hasSeenModal } from './utils';
import { CompanySlug } from './types';

import styles from './styles';

type Props = {
  consentCookieExists: boolean;
  companySlug?: CompanySlug;
  onClose?: () => void;
};

export const AcquisitionModal = ({
  consentCookieExists,
  companySlug,
  onClose
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const isHomepage = window.location.pathname === '/';

    if (
      isHomepage &&
      companySlug &&
      !hasSeenModal(companySlug) &&
      consentCookieExists
    ) {
      setShowModal(true);
    }
  }, [companySlug, consentCookieExists]);

  const onModalClose = useCallback(() => {
    if (companySlug) {
      recordAsModalSeen(companySlug);
    }

    setShowModal(false);
    onClose?.();
  }, [companySlug, onClose]);

  if (!companySlug) {
    return null;
  }

  return (
    <Modal
      className="acquisition-modal"
      aria-labelledby="accuisition-modal-title"
      open={showModal}
      onClose={onModalClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={showModal}>
        <Box sx={styles.modal}>
          <AccuisitionModalContent
            companySlug={companySlug}
            onClose={onModalClose}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
