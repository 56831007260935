import { useLocalStorage } from './useLocalStorage';

interface FinancialData {
  lumpSumPaymentYear: number | null;
  mortgageReason: string | null;
  lumpSumPayment: number | null;
  monthlyOverPayment: number | null;
  currencyType: string | null;
  mortgageType: string | null;
  mortgageTerm: number | null;
  interestRate: number | null;
  depositAmount: number | null;
}

interface WebInterest {
  seller: boolean;
  landlord: boolean;
  buyer: boolean;
  renter: boolean;
}

interface WebVerification {
  verified: boolean;
  verificationToken: string | null;
  unverifiedReason: string | null;
}

interface ContactAddress {
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  addressTown: string;
  addressCounty: string | null;
  addressPostcode: string;
  addressCountryCode: string;
}

interface Profile {
  termsAndConditionsStatus: string;
  updateAccess: string;
  trackingToken: string;
  financialData: FinancialData;
  promotions: any[];
  isEmployee: boolean;
  webInterest: WebInterest;
  webMeta: any | null;
  publicShareKey: string;
  status: string;
  contactNotes: string | null;
  webVerification: WebVerification;
  webUsername: string;
  emailAddress: string;
  contactAddress: ContactAddress;
  contactId: string;
  title: string;
  firstName: string;
  lastName: string;
  contactAnonymised: boolean;
  homeTelephone: string | null;
  workTelephone: string | null;
  mobileTelephone: string | null;
}

interface User {
  profile: Profile;
  accessToken: string;
  userName: string;
  contactId: string;
}

type UserData = Record<string, any>;

const useUserFromStorage = () => {
  const [value, setValue] = useLocalStorage<UserData | null>('foxtons', {
    parseJson: true,
    eventName: 'userChange'
  });

  const setUser = (user: UserData | null | ((val: User | null) => User)) => {
    if (typeof user === 'function') {
      const updatedUser = user(value?.user);

      setValue({ user: updatedUser });
    } else if (user === null) {
      setValue({ user: null });
    } else {
      setValue({ user });
    }
  };

  const safeValue = value?.user ? value.user : { user: null };

  return [safeValue, setUser];
};

export { useUserFromStorage };
