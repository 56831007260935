import { getMyFoxtonsPortalUrl } from '@foxtons/fdl/utils';

export const portalRoutes = {
  dashboard: '/dashboard',
  account: '/account',
  verification: '/account/validate',
  profile: '/account/profile',
  update_email: '/account/updateemail',
  update_password: '/account/updatepassword',
  overview: getMyFoxtonsPortalUrl(),
  alerts: '/account/subscriptions',
  logout: '/account/logout',
  propertyOverview: '/property-overview',
  accountValuation: '/account/valuation',
  termsAndConditionsDocument: 'terms-and-conditions-document'
};

export const authRoutes = {
  login: '/auth/login',
  auth: '/auth',
  registration: '/auth/registration',
  forgotpassword: '/auth/forgotpassword',
  resetPassword: '/auth/resetpassword',
  employeeLogin: '/auth/employee/login'
};

export const webRoutes = {
  home: '/',
  myFoxtons: '/myfoxtons',
  landlord: '/landlord',
  landlordLongLetForm: '/landlord/contact/find-tenants/lettings',
  landlordManagedLetForm: '/landlord/contact/find-tenants/managed',
  landlordShortLetForm: '/landlord/contact/find-tenants/short-lets',
  contactUs: '/contact/form/investments',
  valuation: '/valuations',
  instructForm: '/sell/contact/instruct/sales'
};

export const externalLinks = {
  alexanderHall: 'https://www.alexanderhall.co.uk/'
};

const paths = {
  ...authRoutes,
  ...portalRoutes,
  ...webRoutes
};

export default paths;
