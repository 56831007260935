export const lenderImages = [
  'abbey.jpg',
  'co-op.jpg',
  'hsbc.jpg',
  'nationwide.png',
  'securetrust.png',
  'accord_mortgages.jpg',
  'coutts.jpg',
  'hsbc.png',
  'natwest_international.png',
  'shawbrook.png',
  'affirmative.png',
  'coutts.png',
  'if.jpg',
  'natwest.jpg',
  'skipton_building_society.jpg',
  'ahli_united_bank.jpg',
  'coventry_building_society.jpg',
  'igroup.jpg',
  'natwest.png',
  'skipton_international.png',
  'aldermere.jpg',
  'coventry.png',
  'ingdirect.jpg',
  'newcastle_building_society.jpg',
  'spacer.gif',
  'aldermore.png',
  'creditsuisse.png',
  'investec.png',
  'newcastle.jpg',
  'spacer.jpg',
  'alexanderhall_feed.gif',
  'cumberland.jpg',
  'ipswich.png',
  'new_street.jpg',
  'spml.jpg',
  'alexanderhall.gif',
  'cynergy.png*',
  'irish_permanent.jpg',
  'northern_rock.jpg',
  'stafford_railway.png',
  'alliance_and_leicester.jpg',
  'darlington.jpg',
  'kensington_mortgages.jpg',
  'norwich_and_peterborough_building_society.jpg',
  'standard_chartered.png',
  'alrayan.png',
  'darlington.png',
  'kent_reliance.jpg',
  'nottingham_building_society.jpg',
  'standard_life_bank.jpg',
  'alternative.png',
  'derbyshire_building_society.jpg',
  'keystone.png',
  'nottingham.jpg',
  'state_bank_of_india.jpg',
  'amicus.png',
  'dudley.png',
  'kleinworthambros.png',
  'oblix.png',
  'swanseabs.png',
  'arbuthnotlatham.png',
  'earlshilton.png',
  'lambeth_building_society.jpg',
  'octane.png',
  'tandem_bank.jpg',
  'assetzcapital.png',
  'ecology.jpg',
  'landbay.png',
  'octopus.png',
  'teachers.jpg',
  'atomdigital.png',
  'edeus.jpg',
  'leeds_and_holbeck_building_society.jpg',
  'one_account.jpg',
  'tesco_bank.jpg',
  'axis.png',
  'efg.png',
  'leeds_building_society.jpg',
  'paragon.jpg',
  'the_mortgage_lender.png',
  'bank_and_clients.png',
  'family_building_society.jpg',
  'leeds.gif',
  'paragon_mortgages.jpg',
  'the_mortgage_works.jpg',
  'bank_of_china.jpg',
  'first_active.jpg',
  'leekunited.png',
  'pepper.png',
  'the_nottingham.png',
  'bank_of_ireland.jpg',
  'first_bank_of_nigeria.png',
  'lendinvest.png',
  'platform_home_loans.jpg',
  'the_tipton_coseley.jpg',
  'bank_of_scotland.jpg',
  'first_enterprise.jpg',
  'lloydscommercial.png',
  'platform.jpg',
  'tmb.jpg',
  'barclays_bank.jpg',
  'first_national.jpg',
  'lloydsprivatebank.jpg',
  'platform.png',
  'tsb.jpg',
  'bathbuildingsociety.png',
  'fleet_mortgages.png',
  'lloyds_tsb.jpg',
  'portman_building_society.jpg',
  'ubs.jpg',
  'beacon_homeloans.jpg',
  'foundation.png',
  'london_mortgage_company.jpg',
  'post_office.jpg',
  'ucb_home_loans.jpg',
  'bluestone.jpg',
  'future_mortgages.jpg',
  'magellan.png',
  'post_office.png',
  'utb.jpg',
  'bm_solutions.jpg',
  'gatehouse.png',
  'mansfield.jpg',
  'precise_mortgages.jpg',
  'vida_homeloans.jpg',
  'bnpparibas.jpg',
  'gmac_mortgages.jpg',
  'marketharborough.png',
  'precise.png',
  'virgin_money.jpg',
  'bradford_and_bingley.jpg',
  'godiva.png',
  'marsden.jpg',
  'preferred_mortgages.jpg',
  'west_bromwich_building_society.jpg',
  'bristol_and_west.jpg',
  'halifax.jpg',
  'masthaven.jpg',
  'principality.jpg',
  'west_one.jpg',
  'buckinghamshire.png',
  'halifax.png',
  'melton.png',
  'raw.png',
  'whiteaway.jpg',
  'butterfield.jpg',
  'hampshiretrust.jpg',
  'metro_bank.jpg',
  'royal_bank_of_canada.jpg',
  'woolwich.jpg',
  'callus.gif',
  'handelsbanken.jpg',
  'mortgage_express.jpg',
  'royal_bank_of_scotland.jpg',
  'world_first_logo.png',
  'cambridge.jpg',
  'hanley.jpg',
  'mortgage_intelligence.jpg',
  'saffron_building_society.jpg',
  'yorkshire_building_society.jpg',
  'chelsea_building_society.jpg',
  'hanley_logo.jpg',
  'mortgage_trust.jpg',
  'sainsburys_bank.jpg',
  'zephyr.jpg',
  'cheltenham_and_gloucester.jpg',
  'hinkley_and_rugby.png',
  'mortgage_works.jpg',
  'sainsburys_bank.png',
  'cheshire_building_society.jpg',
  'hodge.png',
  'mtfinance.png',
  'salt.jpg',
  'cheshire_mortgage.jpg',
  'holmesdale.png',
  'national-counties-building-society.png',
  'santander_for_intermediaries.jpg',
  'clydesdale.jpg',
  'hopecapital.png',
  'nationwide_bs.png',
  'scottish_widows_bank.jpg'
];
