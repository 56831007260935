export const variants = {
  h1: {
    fontSize: '3rem',
    lineHeight: 1.5,
    fontWeight: 700
  },
  h2: {
    fontSize: '2.5rem',
    lineHeight: 1.1,
    fontWeight: 600
  },
  h3: {
    fontSize: '1.8rem',
    lineHeight: 1.1,
    fontWeight: 500
  },
  h4: {
    fontSize: '1.5rem',
    lineHeight: 1.1,
    fontWeight: 500
  },
  h5: {
    fontSize: '1.38rem',
    lineHeight: 1.1,
    fontWeight: 500
  },
  subtitle1: {
    fontSize: '1.25rem',
    lineHeight: 1.5,
    fontWeight: 400
  },
  subtitle2: {
    fontSize: '0.85rem',
    lineHeight: 1.5,
    fontWeight: 400
  },
  caption: {
    fontSize: '1.125rem',
    lineHeight: 1.4
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.4,
    fontWeight: 400
  },
  body2: {
    fontSize: '0.75rem',
    lineHeight: 1.4,
    fontWeight: 400
  },
  button: {
    fontSize: '1.125rem',
    lineHeight: 1.2,
    fontWeight: 700,
    textTransform: 'none'
  }
};

export const labelVariants = {
  boldLarge: {
    fontWeight: '700',
    fontSize: '1.25rem'
  }
};
