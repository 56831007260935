export function getCookie(name: string) {
  if (typeof window !== 'undefined') {
    const start = document.cookie.indexOf(`${name}=`);
    const len = start + name.length + 1;

    if (!start && name !== document.cookie.substring(0, name.length))
      return null;
    if (start === -1) return null;
    let end = document.cookie.indexOf(';', len);

    if (end === -1) end = document.cookie.length;

    return decodeURIComponent(document.cookie.substring(len, end));
  }

  return '{}';
}

export function hasConsentCookie() {
  const consent = getCookie('cookie_preferences');

  return consent !== '{}' && consent !== null && consent !== undefined;
}

export function hasConsent() {
  const hasCookieConsent = !!JSON.parse(getCookie('cookie_preferences'))
    ?.necessary;

  return hasCookieConsent;
}

export function hasPerformanceCookieConsent() {
  return !!JSON.parse(getCookie('cookie_preferences'))?.performance;
}

export const getCookieFromPage = (name: string) => {
  const value = `; ${document.cookie}`;
  const separator = `; ${name}=`;
  const parts = value.split(separator);

  if (parts.length === 2) return parts.pop().split(';').shift();

  return undefined;
};

export const getParamsFromPage = () => {
  const params = {};
  const url = decodeURIComponent(window.location.search.substring(1));

  const pairs = url.split('&');

  // eslint-disable-next-line no-plusplus
  for (let i = 0, currentParam; i < pairs.length; i++) {
    // eslint-disable-next-line no-continue
    if (pairs[i].length < 1) continue;
    currentParam = pairs[i].split('=');

    params[currentParam[0]] =
      currentParam[1] === undefined ? true : currentParam[1];
  }

  return params;
};

export function deleteCookie(name: string) {
  if (typeof window === 'undefined') {
    return;
  }
  // explicit domain from certain services

  const hostname = window.location.hostname.replace('www', '');
  const firstDotIndex = hostname.indexOf('.');

  document.cookie = `${name}=; path=/; domain=.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie = `${name}=; path=/; domain=www.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie = `${name}=; path=/; domain=.www.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

  document.cookie = `${name}=; path=/; domain=${process.env.NEXT_PUBLIC_URL}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie = `${name}=; path=/; domain=.${process.env.NEXT_PUBLIC_URL}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

  document.cookie = `${name}=; path=/; domain=${hostname}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie = `${name}=; path=/; domain=www.${hostname}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie = `${name}=; path=/; domain=.${hostname}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

  if (firstDotIndex !== -1) {
    document.cookie = `${name}=; path=/; domain=.${hostname.substring(
      firstDotIndex + 1
    )}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }

  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}
