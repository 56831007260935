/*
Created a dedicated file for App (Web & MyFoxtons) related styles, specifically for size, variant, and color props.

Adding these styles directly to the app-specific theme file would have made it bulkier and harder to maintain due 
to the numerous props and media queries required.

Additionally, the theme file does not natively support sxProps properties, 
such as paddingX, breakpoints like md and xs, or nested properties like :hover
*/
import { SxProps } from '@mui/system';
import { isReactApp } from '../../utils/reactUtils';
import { lightTheme } from '../../theme/index';

const { palette } = lightTheme;

// isReactApp() ? MyFoxtonsStyles : WebStyles // Fxportal/React is serving the component if isReactApp is true
export const appStyles: Record<string, SxProps> = isReactApp()
  ? {
      common: {
        fontFamily: 'Bliss Pro',
        textAlign: 'center'
      },
      small: {
        fontWeight: '900',
        lineHeight: '0.75rem'
      },
      medium: {
        height: { md: '2.5rem', xs: '2.125rem' },
        paddingY: '0.625rem',
        fontSize: { md: '1rem', xs: '0.75rem' },
        fontStyle: 'normal',
        fontWeight: '800',
        lineHeight: '1.25rem'
      },
      large: {
        height: { xs: '2.125rem', md: '3rem' },
        fontSize: { md: '1rem', xs: '0.75rem' },
        lineHeight: '1.25rem'
      },
      primary: {
        border: 0
      },
      secondary: {
        padding: undefined
      },
      tertiary: {
        '.MuiButton-startIcon': {
          margin: 0,
          width: 0
        }
      },
      iconText: {
        padding: undefined
      },
      primaryInverse: {
        padding: undefined
      },
      secondaryInverse: {
        padding: undefined
      }
    }
  : {
      common: {
        textAlign: 'center' // <a> can be a button, so align center as like other buttons
      },
      small: {
        lineHeight: '1.2'
      },
      medium: {
        paddingY: '0.5rem'
      },
      large: {
        lineHeight: '1.2'
      },
      primary: {
        border: `0.0625rem solid ${palette.secondary.main}`
      },
      secondary: {
        paddingX: '1.2rem',
        paddingY: '0.5rem'
      },
      tertiary: {
        '.MuiButton-startIcon': {
          margin: 0,
          width: 0,
          '.svg-icon': {
            fontSize: '1rem'
          }
        }
      },
      iconText: {
        paddingX: '1.2rem',
        paddingY: '0.63rem'
      },
      primaryInverse: {
        paddingX: '1.2rem',
        paddingY: '0.63rem'
      },
      secondaryInverse: {
        paddingX: '1.2rem',
        paddingY: '0.63rem'
      }
    };
