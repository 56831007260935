/* api endpoint goes here */
// NOTE: Do no remove commmented APIs until regression testing is complete.
import { getEnvVarOrDefault } from '@foxtons/fdl/utils/httpRequest/utils';

const leadsBaseUrl = getEnvVarOrDefault(
  process.env.NEXT_PUBLIC_FXLEADS_BASE_URL
);
const searchBaseUrl = getEnvVarOrDefault(
  process.env.NEXT_PUBLIC_FXSEARCH_BASE_URL
);
const discoverBaseUrl = getEnvVarOrDefault(
  process.env.NEXT_PUBLIC_FXDISCOVER_BASE_URL
);
const contactsBaseUrl = getEnvVarOrDefault(
  process.env.NEXT_PUBLIC_FXCONTACTS_BASE_URL
);
const applicantCardBaseUrl = getEnvVarOrDefault(
  process.env.NEXT_PUBLIC_FXAPPLICANTCARD_BASE_UR
);
const propertyBaseUrl = getEnvVarOrDefault(
  process.env.NEXT_PUBLIC_FXPROPERTY_BASE_URL
);

const CMS_BASE_URL_POST_FIX = '/api';
const CMS_API_ENDPOINTS = {
  universityDetail: `${CMS_BASE_URL_POST_FIX}/university-details-page?populate=deep`,
  propertyDetail: `${CMS_BASE_URL_POST_FIX}/property-details-page?populate=deep`,
  propertyList: `${CMS_BASE_URL_POST_FIX}/property-list-page?populate=deep`,
  generalContactForm: `${CMS_BASE_URL_POST_FIX}/form?populate=deep`,
  newHomesInfo: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/new-homes-detail?populate=deep`,
  buildToRentInfo: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/build-to-rent-detail?populate=deep`,
  corporateContactForm: `${CMS_BASE_URL_POST_FIX}/corporate-form?populate=deep`,
  contactFeedbackForm: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/feedback-form?populate=deep`,
  landlordEnquiryForm: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/short-let-enquiry-form?populate=deep`,
  landlordEnquiryPropertyManagementForm: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/property-management-form?populate=deep`,
  sellEnquiryCallbackForm: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/sales-callback-enquiry-form?populate=deep`,
  sellEnquiryQuestionForm: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/sales-question-enquiry-form?populate=deep`,
  sellEnquiryInvestorForm: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/sales-investor-enquiry-form?populate=deep`,
  blogArticleDetail: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/article-detail-page?populate=deep`,
  soldHousePrices: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/city-sold-price?populate=deep`,
  interiorDetailPage: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/interior-design-image?populate=deep`,
  privateOfficeContact: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/private-office-contact?populate=deep`,
  newTenantFormPage: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/new-tenant-form?populate=deep`
};
const API_ENDPOINTS = {
  user: 'user',
  users: 'users',
  blogs: 'posts',
  account: 'account',
  register: 'register',
  login: 'login',
  loginEmployee: 'login-employee',
  api: 'api',
  verification: 'verification',
  resend: 'resend',
  changePassword: 'changePassword',
  verify: 'verify',
  verificationToken: '?verificationToken=',
  identity: 'identity',
  instruction: 'instruction',
  deactivate: 'deactivate',
  forgotPassword: 'forgotpassword',
  resetPassword: 'resetPassword',
  properties: 'properties',
  propertiesList: 'search/marketmeasures',
  recommendedProperties: 'search/similar/properties',
  marketMeasuresProperties: 'search/marketmeasures',
  postcode: 'postalcode',
  search: 'search',
  locations: 'locations',
  addresses: 'addresses',
  contact: 'contact',
  transport: 'transport',
  findTenants: 'findtenants',
  // pendingTasks: 'properties/pendingtasks',
  pendingTasks: 'contacts/properties/pendingtasks',
  getUserSubscriptions: 'subscriptions/users/:userId/communication',
  unsubUserSubscriptions: 'subscriptions/users/:userId/communication/unsub',
  stampDutyCalculationResult: 'calculator/stamp-duty',
  contacts: 'clientcontact',
  // contactUs: 'contact/contactus',
  contactUs: 'contactus/contactus/',
  corporateContactUs: 'contactus/services',
  lanlordEnquiry: 'contactus/landlord/enquiry',
  sellEnquiryCallback: 'valuation/booking',
  rentalEnquiry: 'contactus/rental/enquiry',
  valuation: 'valuation/instant',
  booking: 'valuation/booking',
  downloadDocument: 'document',
  valuationDetails: 'valuation',
  searchProperties: `${searchBaseUrl}search/properties`,
  getPropertyByMarketingId: `${searchBaseUrl}search/properties/marketing-id`,
  searchProperty: 'search/property',
  searchNewProperty: 'search/new-homes',
  searchNewHomesLinks: 'search/new-homes/locations',
  termsAndConditions: 'terms-and-conditions',
  tsAndCsLettings: 'ts_and_cs/lettings',
  applicantCards: 'applicant-cards',
  mortgageAdviceLead: 'leads/mortgage-advice',
  chineseContactUsForm: 'contactus/china-desk',
  underOffer: (contactId: string) => `valuation/${contactId}/applicant-enquiry`,
  iamNotReadyLandlordInstruct:
    'properties/{propertyId}/instructions/{instructionId}',
  getUserDocuments: 'document/properties',
  // stationBoundedBox: 'search/stations/boundedbox',
  stationBoundedBox: 'transport/stations/boundedbox',
  schoolBoundedBox: 'school/schools/boundedbox',
  schoolDiscover: 'school/schools',
  schoolList: 'school',
  sitemapStations: 'transport/stations/lines',
  stationConnectingLines: (stationName: string) =>
    `transport/stations/${stationName}/lines`,
  mortgageDeals: 'mortgage/deals',
  sitemapAreas: (type: 'areas' | 'postcodes') => `search/locations/${type}`,
  cms: {
    urlManagement: `${CMS_BASE_URL_POST_FIX}/url-managements`,
    megaMenu: `${CMS_BASE_URL_POST_FIX}/menus/${
      process.env.NEXT_PUBLIC_CMS_MEGAMENU_ID || '7'
    }?nested&populate=*`,
    footerMenu: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/footer-content?populate=deep`,
    valuationSEO: `${CMS_BASE_URL_POST_FIX}/valuation?&populate=deep`,
    sitemapGeneralStrapi: `${CMS_BASE_URL_POST_FIX}/get-strapi-content?pageUrl=sitemap`,
    sitemapStrapi: (proptType: string, dataType: string) =>
      `${CMS_BASE_URL_POST_FIX}/get-strapi-content?pageUrl=sitemap-${proptType}-${dataType}`,
    ...CMS_API_ENDPOINTS,
    getPropertyDevelopment: (propertyName: string) =>
      `${CMS_BASE_URL_POST_FIX}/get-strapi-content?pageUrl=developments/${propertyName}`,
    nearestOfficesContent: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/branch-finder?populate=deep`,
    blogListPageContent: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/article-list-page?populate=deep`,
    rentalHouseDetail: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/rental-report?populate=deep`,
    averageHouseDetail: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/house-price-detail?populate=deep`,
    schoolDetailsStrapi: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/school-detail?populate=deep`,
    schoolShortlistStrapi: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/schools-shortlist?populate=deep`,
    schoolDetailPage: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/school-details-page?populate=deep`,
    getStrapiData: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/interior-design?populate=deep`,
    getAwardsDetails: `${CMS_BASE_URL_POST_FIX}/get-single-type-content?strapiAPI=api/foxtons-award-detail?populate=deep`
  },
  propertyDocumentUpload: 'document/upload/property',
  fileUpload: 'document/file-upload/upload-token',
  documentCode: 'document-code',
  handpickedProperties:
    'applicant-cards/neg-recommendations?applicantCardType=Lettings',
  // savedProperties: 'applicant-cards/saved-properties',
  savedProperties: 'contacts/properties/saved',
  // deleteSavedProperty: (propertyRegCode: string, instructionType: string) =>
  //   'applicant-cards/saved-properties/${propertyRegCode}/instructions/${instructionType}`,
  deleteSavedProperty: (propertyRegCode: string, instructionType: string) =>
    `contacts/properties/saved/${propertyRegCode}/instructions/${instructionType}`,
  // hoiProperties: (hasVideo: boolean) =>
  //   `applicant-cards/hopefully-interested?applicantCardType=Lettings&hasVideo=${hasVideo}`,
  hoiProperties: (hasVideo: boolean) =>
    `property-recommendations/hopefully-interested?applicantCardType=Lettings&hasVideo=${hasVideo}`,
  ownerComments: 'love-living',
  getRequestNewEpcUrl: (propertyId: string, instructionId: string) =>
    `properties/${propertyId}/instructions/${instructionId}/certificates/request-epc`,
  countriesOptions: 'options/drop-down/COUNTRY/',
  localAuthoritiesOptions: 'options/local-authorities/',
  councilTaxesOptions: 'options/council-taxes/',
  utilitiesCompanies: 'utilities/companies/',
  propertyFactFind: (propertyId?: string, instructionId?: string) =>
    `properties/${propertyId}/instructions/${instructionId}/fact-find/lettings/`,
  propertyUtilities: (propertyId?: string) =>
    `properties/${propertyId}/utilities`,
  propertyAppliances: (propertyId?: string) =>
    `properties/${propertyId}/appliances`,
  getPropertyMarketingStatsApi: (propertyId?: string, instructionId?: string) =>
    `properties/${propertyId}/instructions/${instructionId}/marketing-stats`,
  getPropertyMarketingExposureApi: (
    propertyId?: string,
    instructionId?: string
  ) => `properties/${propertyId}/instructions/${instructionId}/exposure`,
  getPropertyMarketingEventsApi: (
    propertyId?: string,
    instructionId?: string
  ) => `properties/${propertyId}/instructions/${instructionId}/events`,
  universitiesListApi: `school/schools?schoolType=is_university&Page={page}&PageSize={countPerPage}`,
  getBlogsListApi:
    'articles?Page={page}&PageSize={countPerPage}&categories={category}',
  blogArticle: `${discoverBaseUrl}articles`,
  getInteriorDesignCategoriesApi: 'search/interiors',
  getUniversityDetailApi: (universityWebName: string) =>
    `school/school-details?schoolWebName=${universityWebName}`,
  getNearByPropertiesApi: (lat: string, lon: string, kmRadius: string) =>
    `search/properties-in-radius?Lat=${lat}&Lon=${lon}&radius=${kmRadius}&searchType=letting`,
  // getAllOffices: 'search/offices/all',
  getAllOffices: 'offices/all',
  getSchoolPerformanceTable: 'leas',
  getAssetsListWeb: 'leads/press/assets',
  getEstateAgents: 'search/estate-agents',
  nearestOffices: 'offices/nearby?searchTerm=',
  getNearbyAreas: (postcodeShort: string) =>
    `search/postcodes/nearby-places?postcodeShort=${postcodeShort}`,
  searchPostcode: 'search/postcodes/',
  // searchApplicantCardProperties: 'applicant-cards/search-properties',
  searchApplicantCardProperties: 'contacts/properties/search',
  viewings: 'viewings',
  validateProperty: (propertyRegCode: string, instructionType: string) =>
    `offers/property-reg-code/${propertyRegCode}/instruction-type/${instructionType}/lettings/validate`,
  createOffer: 'offers/lettings',
  viewing: 'viewings/',
  viewingFeedback: (viewingId: string) => `viewings/${viewingId}/feedback`,
  autoCompleteProperty: 'search/autocomplete?keyword=',
  offers: 'offers',
  // getPendingTasksHidden: (propertyId: string, instructionId: string) =>
  //   `properties/pendingtasks/all?propertyId=${propertyId}&applicantCardType=lettings&instructionIds=${instructionId}`,
  getPendingTasksHidden: (propertyId: string, instructionId: string) =>
    `contacts/properties/pendingtasks/all?propertyId=${propertyId}&applicantCardType=lettings&instructionIds=${instructionId}`,
  getLocalLife: (propertyId: string) =>
    `search/property/${propertyId}/locallife`,
  getInteriorImageDetail: (filename: string) => `search/interiors/${filename}`,
  contactUsForm: (formName: string) => `contactus/${formName}`,
  getNewHomes: `${searchBaseUrl}search/new-homes`,
  getNewHomesBySearchTerm: (searchTerm: string) =>
    `search/new-homes?searchTerm=${searchTerm}`,
  getOfficeDetails: (officeId: string) => `offices/${officeId}`,
  getPropertyStats: (officeCode: string, instructionType: string) =>
    `offices/${officeCode}/applicant-stats?instructionType=${instructionType}`,
  createAlerts: 'contacts/:contactId/subscriptions/web',
  getJumpToQueueList: 'applicant-cards/applicant-card-type/:type',
  contactSubscriptions:
    'subscriptions/users/:userId/communication/unsub/:categoryId',
  getLocalLifeCards: (page: number, seed: string) =>
    'search/poi?Seed=' +
    seed +
    '&Type=card&Source=foxtons&EncodedPolygon=gcqzHd|uAbqyAzyj@`ct@a`mF}tmC|`n@g_@fcrC&Page=' +
    page +
    '&PageSize=20',
  getTestimonials: (type: string) => `properties/${type}/testimonials`,
  getOfficeData: (officeCode: string) => `offices/${officeCode}/details`,
  getEstateAgentTestimonials: (officeCode: string) =>
    `offices/${officeCode}/testimonials`,
  getEstateAgentOfficeByName: () => `offices/names`,
  getEstateAgentByWebName: (webname: string) => `offices/web-name/${webname}`,
  getEstateAgentAreasCovered: (officeId: number) =>
    `offices/${officeId}/areas-covered`,
  getOfficeRegion: (region: string) => `offices/by-region?region=${region}`,
  getEstateAgentPostcode: (areaWebName: string) =>
    `search/estate-agents/${areaWebName}`,
  getEstateAgnetNearestStation: (
    lat: number,
    lon: number,
    distanceInMiles: number,
    take: number
  ) =>
    `transport/nearest-stations?lat=${lat}&lon=${lon}&distanceInMiles=${distanceInMiles}&take=${take}`,
  searchInBoundedBox: `search/properties`,
  getStrapiEstateAgentDetails:
    '/api/get-single-type-content?strapiAPI=api/estate-agent?populate=deep',
  getStrapiNewHomesEstateAgents: `/api/get-single-type-content?strapiAPI=api/new-homes-estate-agent?populate=deep`,
  getStrapiEstateAgentRegion: `/api/get-single-type-content?strapiAPI=api/estate-agent-london?populate=deep`,
  getStrapiEstateAgentPostCode: `/api/get-single-type-content?strapiAPI=api/estate-agent-post-code?populate=deep`,
  getImages: (propertyReference: string) =>
    `search/property/${propertyReference}/photos`,
  getLocationsInBoundingBox: (
    latitudeMin: number,
    latitudeMax: number,
    longitudeMin: number,
    longitudeMax: number,
    mapClassification: string,
    type: string
  ) =>
    `search/locations/living-in?latitudeMin=${latitudeMin}&latitudeMax=${latitudeMax}&longitudeMin=${longitudeMin}&longitudeMax=${longitudeMax}&classification=${mapClassification}&type=${type}`,
  getLocalLifeLocationDetail: (name: string) => `search/locations/${name}`,
  getLocalLifePoiDetails: (type: string, polygon: string, pageSize: string) =>
    `search/poi?type=${type}&encodedPolygon=${polygon}&page=1&pageSize=${pageSize}`,
  getIncreaseInSale: (name: string) =>
    `search/locations/${name}/marketing-figures`,
  getStrapiLocalLifeDetails:
    '/api/get-single-type-content?strapiAPI=api/local-life-detail?populate=deep',
  getPropertiesForLocalLife: (keyword: string) =>
    `${API_ENDPOINTS.autoCompleteProperty}${keyword}&className=place`,
  getUnitsForNewDevelopment: (propertyName: string) =>
    `search/new-homes/${propertyName}/units`,
  getDrawerData: (propertyId: string) => `search/poi/${propertyId}`,
  feedbackOfficeList: 'offices?page=1&pageSize=100',
  contactFeedback: 'offices/feedback',
  livingInPolygons: 'search/locations/living-in/polygons/postcodes',
  getLocations: (searchTerm: string) =>
    `search/locations/coordinates?searchTerm=${searchTerm}&latLonOnly=true`,
  getAveragePropertyPricesByArea: (areaName: string) =>
    `search/locations/${areaName}/house-prices-summary`,
  getLocationDetails: (areaName: string) => `search/locations/${areaName}`,
  getStrapiSoldPropertyDetailInfo:
    '/api/get-single-type-content?strapiAPI=api/sold-properties-detail?populate=deep',
  submitViewingEnquiry: `${leadsBaseUrl}contactus/viewings/enquiry`,
  getJobsFromEployByRoleName: (role: string) =>
    `discover/jobs?position=${role}`,
  getCareersPageContent: '/api/get-strapi-content?pageUrl=foxtons/careers',
  getOpportunityDetailPageContent:
    '/api/get-single-type-content?strapiAPI=api/job-opportunity-detail?populate=deep',
  getTestimonialPageContent:
    '/api/get-single-type-content?strapiAPI=api/staff-testimonial-detail?populate=deep',
  getAllJobsFromEploy: 'discover/jobs',
  createBidX1Session: `${propertyBaseUrl}bidxauction/bidx/create`,
  changeOfOccupancy: leadsBaseUrl + 'contactus/change-of-occupancy',
  getWebTest: 'webtest',
  getWebTestPing: 'webtest/ping',
  setWebTestVerify: 'webtest/verify',
  setWebTestUnverify: 'webtest/unverify',
  getWebTestBosWsiHost: 'webtest/boswsihost',
  accountLogin: 'account/login',
  getUserByContactId: (contactId: string) => `user/${contactId}`,
  // TODO: Need to confirm why these are commented before removing
  // livingInPolygons: 'search/locations/living-in/polygons/postcodes',
  // livingInPolygons: leadsBaseUrl + 'search/locations/living-in/polygons/postcodes',
  // getStrapiSoldPropertyDetailInfo: `/api/get-single-type-content?strapiAPI=api/sold-properties-detail?populate=deep`
  getPreQualificationQuestions: ({
    contactId,
    applicantCardId,
    applicantCommunicationId,
    timestamp
  }: {
    contactId: string;
    applicantCardId: string;
    applicantCommunicationId: string;
    timestamp?: string;
  }) => {
    const queryParams = new URLSearchParams();
    queryParams.append('applicantCommunicationId', applicantCommunicationId);
    if (timestamp) {
      queryParams.append('t', timestamp);
    }
    return `applicant-cards/prequalification/${contactId}/${applicantCardId}?${queryParams.toString()}`;
  },
  savePreQualificationAnswers: ({
    contactId,
    applicantCardId
  }: {
    contactId: string;
    applicantCardId: string;
  }) => `applicant-cards/prequalification/${contactId}/${applicantCardId}`,
  getIsPrequalified: (
    contactId: string,
    applicantCardId: string,
    applicantCommunicationId: string,
    officeCode: string,
    instructionId?: string,
    timestamp?: string
  ) => {
    const queryParams = new URLSearchParams();

    if (instructionId) {
      queryParams.append('instructionId', instructionId);
    }
    if (timestamp) {
      queryParams.append('t', timestamp);
    }

    return `applicant-cards/${applicantCardId}/contacts/${contactId}/communications/${applicantCommunicationId}/offices/${officeCode}?${queryParams.toString()}`;
  },
  privateOfficeContact: `${leadsBaseUrl}privateofficecontact/privateoffice/contact`,
  saveClientContactActivity: `${contactsBaseUrl}clientcontact/activity/`,
  getRecommendedInstructions: (
    applicantCardId: string,
    instructionId: string
  ) =>
    `${applicantCardBaseUrl}applicant-cards/${applicantCardId}/instructions/${instructionId}/recommended-instructions`,
  submitInstructionVote: (
    applicantCardId: string,
    instructionId: string,
    instructionVote: string
  ) =>
    `${applicantCardBaseUrl}applicant-cards/${applicantCardId}/instructions/${instructionId}/instruction-vote/${instructionVote}`,
  bosRecommendedProperties: (
    propertyReference: string,
    instructionType: string
  ) =>
    searchBaseUrl +
    `search/${propertyReference}/${instructionType}/recommended-properties`
};

export default API_ENDPOINTS;
