/* eslint-disable no-useless-catch */
/**
 * This file contains the fetchClient function which is used to make API calls
 */

import { setUserIntoStorage } from '../index';
import { ResponseData } from './types';
import { replaceWithServerURL } from './utils';

/**
 * @param url
 * @param options
 */
const fetchData = async (
  url: RequestInfo,
  options: RequestInit | undefined
): Promise<ResponseData | Response> => {
  const originalFetch = typeof window !== 'undefined' ? window?.fetch : fetch;

  try {
    const response = await originalFetch(replaceWithServerURL(url), options);

    // Check if the response status is not OK (e.g., 404, 500, etc.)

    if (!response.ok) {
      // You can throw a custom error or handle it in a way that suits your needs
      throw new Error(`Request failed with status: ${response?.status}`);
    } else if (response.status === 401) {
      setUserIntoStorage({});
      localStorage.removeItem('user_verified');
      throw new Error('Forbidden');
    } else if (options.signal && options.signal.aborted) {
      throw new DOMException('Request cancelled', 'AbortError');
    }

    const respText = await response?.text();

    if (respText) {
      return {
        data: await JSON.parse(respText),
        ok: response.ok,
        status: response.status
      };
    }

    return response;
  } catch (error) {
    // Handle the error globally
    // Rethrow the error to propagate it down the chain
    throw error;
  }
};

/**
 * Usage information
 *     GET:
 *     const response = await fetchClient(
 *       `${process.env.NEXT_PUBLIC_CMS_BASE_URL}${API_ENDPOINTS.cms.urlManagement}`,
 *       {}
 *     );
 *     response.data
 *
 *     POST, PUT, Delete
 *     const options: RequestInit = {
 *        method: 'GET',
 *        headers: {
 *           Authorization: `Bearer ${isLoggedIn}`
 *        }
 *      };
 *      const url = `${
 *         API_ENDPOINTS.stampDutyCalculationResult
 *      }?PurchasePrice=${price}&IsUkResident=${false}&IsFirstTimeBuyer=${false}&IsSecondHome=${false}`;
 *
 *      const response = await fetchClient(
 *        `${process.env.NEXT_PUBLIC_PORTAL_BASE_URL}${url}`,
 *         options
 *      );
 */

export default fetchData;
