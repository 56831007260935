import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import Button from '@foxtons/fdl/components/Button/index';
import type { CompanySlug } from './types';
import styles from './styles';

type Props = {
  companySlug: CompanySlug;
  onClose: () => void;
};

const getContentBySlug = (companySlug: CompanySlug) => {
  if (companySlug === 'pillars') {
    return (
      <Box>
        <Typography variant="h3" component="h2" id="accuisition-modal-title">
          We&apos;re delighted to welcome &apos;Pillars&apos; into the Foxtons
          family
        </Typography>
        <Typography variant="body1">
          Existing customers can continue to reach their Pillars contact as
          normal.
        </Typography>
        <Typography variant="body1">
          For any new enquiries please call 01483 43 44 45.
        </Typography>
      </Box>
    );
  } else if (companySlug === 'stonesresidential') {
    return (
      <Box>
        <Typography variant="h3" component="h2" id="accuisition-modal-title">
          We&apos;re delighted to welcome &apos;Stones Residential&apos; into
          the Foxtons family
        </Typography>
        <Typography variant="body1">
          Existing customers can continue to reach their Stones Residential
          contact as normal.
        </Typography>
        <Typography variant="body1">
          For any new enquiries please call 020 8954 0045.
        </Typography>
      </Box>
    );
  } else if (companySlug === 'gordonandco') {
    return (
      <Box>
        <Typography variant="h3" component="h2" id="accuisition-modal-title">
          We&apos;re delighted to welcome &apos;Gordon &amp; Co&apos; into the
          Foxtons family
        </Typography>
        <Typography variant="body1">
          You can contact your team on the following numbers:
        </Typography>
        <ul>
          <li>Battersea - Foxtons Battersea 020 7801 1111</li>
          <li>
            Elephant &amp; Castle - Foxtons Elephant &amp; Castle 020 7703 7088
          </li>
          <li>Norbury - Foxtons Norbury 020 8679 1666</li>
          <li>Tower Bridge - Foxtons London Bridge 020 7386 6500</li>
        </ul>
      </Box>
    );
  } else if (companySlug === 'atkinsonmcleod') {
    return (
      <Box>
        <Typography variant="h3" component="h2" id="accuisition-modal-title">
          We&apos;re delighted to welcome &apos;Atkinson Mcleod&apos; into the
          Foxtons family
        </Typography>
        <Typography variant="body1">
          You can contact your team on the following numbers:
        </Typography>
        <ul>
          <li>Canary Wharf - Foxtons Canary Wharf 020 7133 7777</li>
          <li>Hackney - Foxtons Hackney 020 7386 6565</li>
          <li>Kennington - Foxtons Kennington 020 7840 3200</li>
          <li>Wapping - Foxtons Wapping 020 3728 2222</li>
        </ul>
      </Box>
    );
  } else if (companySlug === 'ludlowthompson') {
    return (
      <Box>
        <Typography variant="h3" component="h2" id="accuisition-modal-title">
          We&apos;re delighted to welcome &apos;Ludlow Thompson&apos; into the
          Foxtons family
        </Typography>
        <Typography variant="body1">
          Existing landlords can continue to use their LT account.
        </Typography>
        <Typography variant="body1">To log-in use the link below:</Typography>
        <Typography variant="body1">
          <a
            href="https://www.ludlowthompson.com/myaccount/login.htm"
            rel="noreferrer"
            target="_blank"
          >
            My LT Account
          </a>
        </Typography>
      </Box>
    );
  } else if (companySlug === 'londonstone') {
    return (
      <Box>
        <Typography variant="h3" component="h2" id="accuisition-modal-title">
          We&apos;re delighted to welcome &apos;London Stone Properties&apos;
          into the Foxtons family
        </Typography>
        <Typography variant="body1">
          Existing customers can continue to reach their LSP contact as normal.
        </Typography>
        <Typography variant="body1">
          For any new Woolwich enquiries please call 020 8855 2155.
        </Typography>
      </Box>
    );
  } else if (companySlug === 'imagine') {
    return (
      <Box>
        <Typography variant="h3" component="h2" id="accuisition-modal-title">
          We&apos;re delighted to welcome &apos;Imagine&apos; to the Foxtons
          family
        </Typography>
        <Typography variant="body1">
          Covering Watford, Hemel Hempstead and Bushey
        </Typography>
      </Box>
    );
  }
};

export const AccuisitionModalContent = ({ companySlug, onClose }: Props) => {
  const content = useMemo(() => getContentBySlug(companySlug), [companySlug]);

  return (
    <Box sx={styles.modalContent}>
      {content}
      <Box sx={styles.footer}>
        <Button variant="primary-dark" color="primary" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default AccuisitionModalContent;
