import { lazy, useEffect, useState } from 'react';
import { useRouter as useNextRouter } from 'next/navigation';
import MuiLink from '@mui/material/Link';

// This function checks if the current environment is a React app (Vite) or Next App
export const isReactApp = () => {
  // Check if running in a Vite environment
  if (typeof import.meta !== 'undefined' && import.meta.env) {
    return import.meta.env.VITE_REACT_APP === 'react';
  }

  // If not Vite, assume it's Next.js
  return false;
};

export const getEnvVar = (name: string) => {
  if (typeof import.meta !== 'undefined' && import.meta.env) {
    // Use Vite's import.meta.env to get environment variables
    return import.meta.env[`VITE_${name}`];
  }

  // Use Next.js's process.env to get environment variables
  return process.env[`NEXT_PUBLIC_${name}`];
};

// Dynamic import function to handle component loading based on the environment
export const dynamicImport = (loader, ssr = false) => {
  if (isReactApp()) {
    // Use React.lazy for React environment (Vite)
    return lazy(loader);
  }

  // Use Next.js dynamic import for Next.js environment
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const dynamic = require('next/dynamic');

  return dynamic(loader, { ssr });
};

/**
 * Custom hook to dynamically import the correct Link component (React Router or Next.js)
 * @returns {React.Component|null} The Link component or null if it's still loading
 */
export const useLink = () => {
  const [LinkComponent, setLinkComponent] = useState(() => MuiLink);

  useEffect(() => {
    const loadLinkComponent = async () => {
      try {
        if (isReactApp()) {
          // Dynamically import React Router's Link for React
          const { Link } = await import('react-router-dom');

          setLinkComponent(() => Link);
        } else {
          // Dynamically import Next.js's Link for Next.js
          const { default: Link } = await import('next/link');

          setLinkComponent(() => Link);
        }
      } catch (error) {
        // Optionally, handle the error or set a fallback component
      }
    };

    loadLinkComponent();
  }, []);

  return LinkComponent;
};

export const useRouter = () => {
  if (isReactApp()) {
    return null;
  }

  return useNextRouter;
};
