import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgArrowLeft = (props) => (
  <SvgIcon {...props} title="ArrowLeft">
    <svg
      viewBox="0 0 20 12"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.786 6.79H3.178l3.974 3.857a.78.78 0 0 1 0 1.122.828.828 0 0 1-1.148 0L.638 6.56a.77.77 0 0 1 0-1.114L5.995.23a.828.828 0 0 1 1.148 0 .77.77 0 0 1 0 1.114L3.178 5.21h15.608c.448 0 .814.355.814.79 0 .435-.366.79-.814.79Z" />
    </svg>
  </SvgIcon>
);

export default SvgArrowLeft;
