/**
 * This file should host all the API calls being made to backend
 * All functions should host the API call and return the response
 */
import {
  FoxtonsResponse,
  GenesysGetAnalyticsResponse,
  RequestConfig,
  TrackCounterType,
  ValuationInterface
} from './types';
import {
  CMS_API_ENDPOINTS,
  FX_API_ENDPOINTS,
  PORTAL_API_ENDPOINTS
} from './api-constants';
import { httpRequest } from './http-clients';

export const inverseInstructionMappings: { [key: string]: string } = {
  sale: 'SAL',
  letting: 'LON',
  short_letting: 'SHO'
};

// APIs ---------------------------------------------------------------------------------------------

export async function getPropertiesAutoComplete(
  payload: string,
  signal?: AbortSignal
) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: `${CMS_API_ENDPOINTS.autoCompleteProperty}${payload}&postcodeOnly=true`,
    signal
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: 'data' in response ? response?.data : response
    };
  } catch (error) {
    return { success: false, response: error }; // call reducer to match case of fail and update store
  }
}

export async function getPropertiesByPostCode(
  payload: string,
  signal?: AbortSignal
) {
  // Request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: `${CMS_API_ENDPOINTS.search}/${CMS_API_ENDPOINTS.locations}/${payload}/${CMS_API_ENDPOINTS.addresses}`,
    signal
  };

  try {
    const response = await httpRequest(config);

    return {
      response: 'data' in response ? response?.data?.data : response,
      success: true
    };
  } catch (error) {
    return { response: error, success: true };
  }
}

export async function postValuation(payload: Partial<ValuationInterface>) {
  const config: RequestConfig = {
    method: 'POST',
    url:
      payload.valuationMethod === 'online'
        ? `${CMS_API_ENDPOINTS.valuation}`
        : `${CMS_API_ENDPOINTS.booking}`,

    data: payload
  };

  try {
    const response = await httpRequest(config);

    return { response, success: true };
  } catch (error) {
    return { response: error, success: false };
  }
}

export const trackCounter = ({ ir, it, pt }: TrackCounterType) => {
  const now = new Date();
  const randTime = now.getTime();
  const params = `?ir=${ir}&it=${it}&pt=${pt}&rand=${randTime}`;

  const url = `${process.env.NEXT_PUBLIC_COUNTER_TRACK}${
    CMS_API_ENDPOINTS.counterSVG
  }${params.replace(/,+/g, ',')}`;

  return url;
};

// logoutUser makes logout user http request
export const logoutUser = async () => {
  const url = PORTAL_API_ENDPOINTS.logoutUser;
  const options = {
    method: 'GET',
    url
  };

  try {
    const response = await httpRequest(options);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: false,
      response: null,
      error
    };
  }
};

export const getUserDetail = async () => {
  const url = PORTAL_API_ENDPOINTS.user;
  const options = {
    method: 'GET',
    url
  };

  try {
    const response = await httpRequest(options);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

// Fetching User Saved Properties: Access-token is necesssary
export async function getUserFavoritePropertiesList() {
  const config: RequestConfig = {
    method: 'GET',
    url: CMS_API_ENDPOINTS.savedProperties
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}

// Toggling save status on property for a user
export const togglePropertyFavorite = async (
  instructionType: string,
  propertyReference: string,
  isPropertyFavorite: boolean
) => {
  const config: RequestConfig = {
    method: isPropertyFavorite ? 'DELETE' : 'PUT',
    url: isPropertyFavorite
      ? `${CMS_API_ENDPOINTS.deleteSavedProperty(
          propertyReference,
          inverseInstructionMappings[instructionType]
        )}`
      : `${CMS_API_ENDPOINTS.savedProperties}`,
    data: !isPropertyFavorite
      ? {
          propertyRegCode: propertyReference,
          instructionType: inverseInstructionMappings[instructionType]
        }
      : undefined
  };

  try {
    const response = await httpRequest(config);

    return { success: true, response, error: null };
  } catch (error) {
    return { success: false, response: null, error };
  }
};

export const getMySavedPlaces = async (contactId: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: CMS_API_ENDPOINTS.mySavedPlaces(contactId)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export const getMySavedSchools = async (contactId: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: CMS_API_ENDPOINTS.mySchools(contactId)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export async function getAreaPostcode(keyword: string) {
  // request method and endpoint configuration
  const config: RequestConfig = {
    method: 'GET',
    url: CMS_API_ENDPOINTS.autoCompleteProperty + keyword
  };

  try {
    const response = await httpRequest(config);

    return response;
  } catch (error) {
    return { success: false, response: error };
  }
}

export const getOfficesList = async () => {
  const config: RequestConfig = {
    method: 'GET',
    url: CMS_API_ENDPOINTS.getAllOffices
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export const toggleSaveSchools = async (contactId: string, savedSchoolList) => {
  const config: RequestConfig = {
    method: 'POST',
    url: CMS_API_ENDPOINTS.saveDeleteSchools(contactId),
    data: savedSchoolList
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export async function getPropertiesNearByCord(lat: number, lon: number) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${CMS_API_ENDPOINTS.getNearbyAreasByCord(lat, lon)}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data
    };
  } catch (error) {
    return { success: false, response: error };
  }
}

export const getAnyUserSavedSchools = async (shareKey: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: CMS_API_ENDPOINTS.anySavedSchoolList(shareKey)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export const getPostalCodeLocations = async (searchTerm: string) => {
  const config: RequestConfig = {
    method: 'GET',
    url: CMS_API_ENDPOINTS.getLocations(searchTerm)
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export const getGenesysQueueStatus = async (
  queueName: string,
  signal?: AbortSignal
): Promise<FoxtonsResponse<GenesysGetAnalyticsResponse>> => {
  const config: RequestConfig = {
    method: 'GET',
    url: FX_API_ENDPOINTS.genesysQueueStatus(queueName),
    signal
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response: response?.data as GenesysGetAnalyticsResponse,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
};

export async function getMortgageLenders(
  mortgageType: string,
  expectedValuation: string,
  numberOfItems: string,
  term: string,
  paymentMethod: string,
  fixed: string,
  overpaymentsAllowed: string,
  reasonForMortgage: string,
  applicantType: string
) {
  const config: RequestConfig = {
    method: 'GET',
    url: `${CMS_API_ENDPOINTS.mortgageDeals}?mortgageType=${mortgageType}&expectedValuation=${expectedValuation}&numberOfItems=${numberOfItems}&term=${term}&paymentMethod=${paymentMethod}&fixed=${fixed}&overpaymentsAllowed=${overpaymentsAllowed}&reasonForMortgage=${reasonForMortgage}&applicantType=${applicantType}`
  };

  try {
    const response = await httpRequest(config);

    return {
      success: true,
      response,
      error: null
    };
  } catch (error) {
    return {
      success: true,
      response: null,
      error
    };
  }
}
