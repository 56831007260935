import React from 'react';
import { lightTheme } from '../../theme/index';
import BarVertical from '../../icons/BarVertical';
import SvgArrowLeft from '../../icons/ArrowLeft';
import SvgArrowRight from '../../icons/ArrowRight';
import FlagCn from '../../icons/FlagCn';
import FlagEn from '../../icons/FlagEn';
import styles from './styles';
import { isReactApp } from '../../utils/reactUtils';

const {
  back,
  primary,
  secondary,
  tertiary,
  borderedInverse,
  primaryInverse,
  secondaryInverse,
  tertiaryInverse,
  iconTextInverse,
  neutralInverse,
  primaryDark
} = styles;

const { palette } = lightTheme;

export const iconColor = (color?: string) => {
  if (color && palette[color]) {
    return palette[color].main;
  }

  if (color && color === 'white') {
    return '#fff';
  }

  return palette.primary.main;
};

export const getBtnStyles = (
  variant: string,
  color: string,
  theme?: string,
  startIcon?: React.ReactNode
) => {
  if (variant?.indexOf('switch-lang-') > -1) {
    return styles.languageSwitchButtonStyles; // language switch styles
  }

  if (variant === 'back') {
    return back;
  }

  if (color === 'secondary' || theme === 'dark') {
    if (startIcon !== undefined) {
      return iconTextInverse;
    }

    if (variant === 'primary') {
      return primaryInverse;
    }

    if (variant === 'bordered') {
      return borderedInverse;
    }

    if (variant === 'secondary') {
      return secondaryInverse;
    }

    if (variant === 'tertiary') {
      return tertiaryInverse;
    }

    return {};
  }

  if (startIcon !== undefined) {
    return secondary;
  }

  if (variant === 'primary' && color === 'primary') {
    return primary;
  }

  if (variant === 'neutral') {
    return neutralInverse;
  }

  if (variant === 'primary-dark') {
    return primaryDark;
  }

  if (variant === 'secondary' && color === 'primary') {
    return secondary;
  }

  if (variant === 'tertiary' && color === 'primary') {
    return tertiary;
  }

  return {};
};

export const getIcon = (
  variant: string,
  isHover: boolean,
  color: string,
  StartIcon: React.ReactNode,
  size: string
) => {
  if (StartIcon !== undefined) {
    return StartIcon;
  }

  // china flag icon
  if (variant === 'switch-lang-cn') {
    return FlagCn({
      sx: { position: 'relative', left: '0' }
    });
  }

  // english flag icon
  if (variant === 'switch-lang-en') {
    return FlagEn({
      sx: { position: 'relative', left: '0' }
    });
  }

  if (variant === 'tertiary') {
    if (isHover) {
      return SvgArrowRight({
        color: iconColor(color),
        sx: { position: 'relative', left: '-1.15rem' },
        className: isReactApp() ? undefined : 'svg-icon'
      });
    }

    if (size === 'large') {
      return BarVertical({
        color: iconColor(color),
        sx: { position: 'relative', left: '-1.47rem', height: '1rem' }
      });
    }

    if (size === 'medium') {
      return BarVertical({
        color: iconColor(color),
        sx: {
          position: 'relative',
          left: '-1.5rem',
          fontSize: isReactApp() ? undefined : '1rem'
        },
        className: isReactApp() ? undefined : 'svg-icon'
      });
    }

    if (size === 'small') {
      return BarVertical({
        color: iconColor(color),
        sx: { position: 'relative', left: '-1.25rem' }
      });
    }
  }

  if (variant === 'back') {
    return SvgArrowLeft({});
  }

  return undefined;
};
