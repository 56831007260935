import React from 'react';

interface ComponentWrapperProps {
  children: JSX.Element | null;
  otherProps: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

function ComponentWrapper({ children }: ComponentWrapperProps) {
  return children;
}

export default React.memo(ComponentWrapper, (prev, next) => {
  const props = Object.keys(prev.otherProps);
  let shouldRerender = false;

  props.forEach((propKey) => {
    if (prev.otherProps[propKey] !== next.otherProps[propKey]) {
      shouldRerender = true;
    }
  });

  return !shouldRerender;
});
