import { SxProps } from '@mui/material';
import { lightTheme, FONT_FAMILY } from '../../theme/index';

const { palette, spacing } = lightTheme;

const foxtonsBtn = {
  color: palette.primary.main,
  backgroundColor: palette.primary['400'],
  border: `0.063rem solid ${palette.primary['400']}`,
  borderRadius: '0.25rem',
  width: '100%'
};

const popoverBtn = {
  textTransform: 'none',
  color: palette.grey.main,
  width: '100%',
  justifyContent: 'flex-start',
  ':hover': {
    background: 'transparent'
  },
  fontFamily: `${FONT_FAMILY} !important`
};
const iconButtonClose = {
  display: {
    lg: 'none',
    xs: 'flex'
  },
  backgroundColor: palette.primary[400],

  marginLeft: '0.5rem',
  borderRadius: '25%',
  color: palette.primary.main,
  ':hover': {
    backgroundColor: palette.primary[400]
  }
};

const styles: Record<string, SxProps> = {
  signOutButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },

  signOutButtonWrapperPortal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: '1rem'
  },

  signoutBtn: {
    ...popoverBtn,
    padding: '0.625rem 0.5rem 0.375rem 0.5rem',
    color: palette.bloodRed[100]
  },

  menuArea: {
    padding: '0.5rem 0rem'
  },

  btnPopoverDefault: {
    ...popoverBtn,
    fontWeight: 400, // overriding button's font weight
    padding: spacing(0.4), // 8px: overriding button's padding
    '&:hover': {
      background: palette.primary['400'],
      borderRadius: '0'
    }
  },

  signoutBtnWeb: {
    paddingLeft: spacing(0.4), // 8px: overriding button's padding left
    textTransform: 'none',
    mt: '0.5rem',
    color: palette.bloodRed.main,
    width: '100%',
    justifyContent: 'flex-start',
    ':hover': {
      background: 'transparent'
    }
  },

  signoutArrow: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    position: 'relative',
    top: '0.125rem',

    svg: { path: { fill: palette.darkestBlack[100] } }
  },

  loggedInUserText: {
    color: palette.grey[300],
    '& span': {
      color: palette.grey.main
    }
  },

  rightBtnLinkBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      width: {
        xs: '100%',
        md: 'auto'
      }
    }
  },

  valuationButton: { textTransform: 'none', height: '2.5rem' },

  webBox: {
    display: 'flex',
    gap: '1rem'
  },

  link: {
    display: { xs: 'none', lg: 'flex' },
    alignItems: 'center',
    textDecoration: 'none',
    width: {
      xs: '100%',
      md: 'auto'
    }
  },

  linkRightBtns: {
    display: {
      xs: 'none',
      lg: 'flex'
    },
    alignItems: 'center',
    textDecoration: 'none',
    width: {
      xs: '100%',
      md: 'auto'
    }
  },

  btnMyfoxton: {
    ...foxtonsBtn,
    height: '2.5rem',
    border: `1px solid ${palette.secondary['600']}`
  },

  btnLogin: {
    ...foxtonsBtn,
    backgroundColor: palette.grey['50'],
    border: `1px solid ${palette.grey['50']}`,
    fontWeight: '900',
    color: palette.grey.main
  },
  btnLoggedIn: {
    ...foxtonsBtn,
    backgroundColor: palette.lightishAqua.main,
    color: palette.primary.main,
    border: `1px solid ${palette.grey['50']}`,
    borderRadius: '50%',
    padding: '0.75rem',
    minWidth: '2.5rem',
    cursor: 'pointer'
  },

  btnPopover: {
    ...foxtonsBtn,
    display: 'flex',
    backgroundColor: palette.lightishAqua.main,
    color: palette.primary.main,
    border: `0.063rem solid ${palette.secondary['600']}`,
    fontSize: { xs: '0.75rem', md: '0.875rem' },
    fontWeight: '800',
    width: 'auto',
    minWidth: '2.5rem',
    cursor: 'pointer',
    padding: '0.8rem 1rem',
    height: { xs: '2rem', md: '2.5rem' },
    alignItems: 'center'
  },

  // popover for web onlicking login button/user avatar
  btnPopoverWeb: {
    ...foxtonsBtn,
    display: 'flex',
    backgroundColor: palette.lightishAqua.main,
    color: palette.primary.main,
    fontWeight: '900',
    width: 'auto',
    minWidth: {
      xs: '1rem',
      md: '2.5rem'
    },
    cursor: 'pointer',
    height: '2.5rem',
    mb: {
      md: '0.25rem'
    },
    padding: {
      xs: '0.75rem 0.8rem',
      md: '0.75rem 1.5rem'
    },
    border: `1px solid ${palette.secondary['600']}`
  },

  popperBtnBoxWeb: {
    display: 'flex',
    alignItems: 'center',
    mt: { xs: '0', md: '0.25rem' }
  },

  ValuationDrawer: {
    width: '100%',
    fontSize: '0.875rem'
  },

  arrowDown: {
    marginLeft: '0.25rem',
    width: '1.25rem',
    height: '1.25rem',
    border: 'none',
    color: palette.primary.main,
    transform: 'rotate(90deg)'
  },

  arrowDownPortal: {
    marginLeft: '0.5rem',
    width: '0.75rem',
    height: '0.75rem',
    border: 'none',
    color: palette.primary.main,
    transform: 'rotate(90deg)'
  },

  iconButtonClose: {
    ...iconButtonClose
  },

  iconButtonClosePortal: {
    ...iconButtonClose,
    height: { xs: '2rem', md: '2.5rem' },
    width: { xs: '2rem', md: '2.5rem' },
    svg: {
      width: { xs: '1.25rem', md: '1.5rem' },
      height: { xs: '1.25rem', md: '1.5rem' }
    }
  },

  popperBox: {
    padding: '0.75rem 1rem 0.5rem 1rem',
    border: `0.063rem solid ${palette.primary[50]}`,
    boxShadow: `0rem 0.241rem 0.968rem 0rem ${palette.brightGreen[50]}`
  },
  popperBoxWeb: {
    transform: 'translateY(1.25rem)',
    padding: '0.75rem 1rem 0.5rem 1rem',
    border: `0.063rem solid ${palette.primary[400]}`,
    width: '14rem'
  },

  userDetailText: {
    fontSize: '0.875rem',
    fontWeight: '800',
    color: palette.grey.main,
    px: '0.5rem',
    letterSpacing: 0
  },

  userEmailTextWeb: {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '0.75rem',
    color: palette.grey.main,
    marginBottom: '1rem',
    px: '0.5rem'
  },

  userEmailText: {
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '1.25rem',
    color: palette.primary.main,
    marginBottom: '0.75rem',
    px: '0.5rem',
    letterSpacing: 0
  },
  foxtonsText: {
    color: palette.grey.main,
    marginBottom: '0.75rem',
    px: '0.5rem'
  },

  notificationIconBox: {
    padding: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: palette.lightishAqua.main,

    '.MuiBadge-badge': {
      backgroundColor: palette.primary.main
    }
  },

  dividerWeb: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '0.25rem',
    borderColor: palette.grey['100']
  },

  divider: {
    marginLeft: '-1rem',
    marginRight: '-1rem',
    borderColor: palette.platinum.main
  },

  btnsWrapper: {
    display: {
      xs: 'none',
      lg: 'flex'
    },
    gap: '1rem'
  },
  loggedinTitle: {
    color: palette.grey.main
  },
  loggedinEmail: {
    color: palette.primary.main
  },
  btnBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: {
      xs: 'flex-start',
      sm: 'space-between'
    },
    alignItems: 'center',
    width: '100%',
    minWidth: {
      xs: '0',
      sm: '11rem'
    },
    maxWidth: {
      xs: '9.5rem',
      sm: '14rem'
    },
    cursor: 'pointer'
  }
};

export default styles;
