import React from 'react';
import { SvgIcon } from '@mui/material';

function SvgFoxtonsFilled(props) {
  return (
    <SvgIcon {...props} title="FoxtonsFilled">
      <svg
        viewBox="0 0 18 18"
        fill="#000"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx={9} cy={9} r={9} fill="#017163" />
        <path
          d="M8.5 14.209V9.985h1.735l.831-2.094H8.5v-1.91h2.53l.976-1.946H6.332v10.174H8.5Z"
          fill="#FFF000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.129 16.396c4.125 0 7.47-3.334 7.47-7.446 0-4.113-3.345-7.447-7.47-7.447s-7.47 3.334-7.47 7.447c0 4.112 3.345 7.446 7.47 7.446Zm0 .601c4.459 0 8.073-3.603 8.073-8.047 0-4.445-3.615-8.048-8.073-8.048-4.459 0-8.073 3.603-8.073 8.048 0 4.444 3.614 8.047 8.073 8.047Z"
          fill="#FFF000"
        />
      </svg>
    </SvgIcon>
  );
}

export default SvgFoxtonsFilled;
