import { lightTheme, globalSectionPadding } from '@foxtons/fdl/theme/index';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import colorPalette from './colorPalette';
import { labelVariants, variants } from './variants';

import deepmerge from '@mui/utils/deepmerge';

const FONT_FAMILY = 'var(--open-sans)'; // From: _global.css
const btnMinWidth = '200px';

const themeOptions = {
  typography: {
    fontFamily: FONT_FAMILY,
    'light-italic': {
      fontStyle: 'italic',
      fontWeight: 300
    },
    /* Targets <Typography component="h1"> */
    ...variants
  },
  palette: colorPalette,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: FONT_FAMILY
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...variants.body2
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: FONT_FAMILY,
          color: colorPalette.primary.main,
          ...variants.button
        }
      },
      variants: [
        {
          props: { variant: 'text' },
          style: { color: colorPalette.primary.main }
        },
        {
          props: { variant: 'primary' },
          style: { minWidth: btnMinWidth }
        },
        {
          props: { variant: 'primary', color: 'secondary' },
          style: { minWidth: btnMinWidth }
        },
        {
          props: { variant: 'primary-dark' },
          style: { minWidth: btnMinWidth }
        }
      ]
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: variants.subtitle2
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: variants.subtitle2
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: variants.subtitle2
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: FONT_FAMILY
        }
      },
      variants: [
        {
          props: {
            variant: 'bold-large' // custom variant for input label
          },
          style: labelVariants.boldLarge //styles for custom variant of input label
        }
      ]
    },
    MuiListItemText: {
      styleOverrides: {
        root: variants.body1
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: variants.subtitle2
      }
    },
    MuiSelect: {
      styleOverrides: {
        outlined: variants.subtitle2
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...variants.body1,
          fontWeight: 400,
          lineHeight: '1.4'
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: FONT_FAMILY
      },

      /* Targets <Typography variant="h1"> */
      variants: [
        {
          props: { variant: 'h1' },
          style: variants.h1
        },
        {
          props: { variant: 'h2' },
          style: variants.h2
        },
        {
          props: { variant: 'h3' },
          style: variants.h3
        },
        {
          props: { variant: 'subtitle2' },
          style: variants.subtitle2
        },
        {
          props: { variant: 'body1' },
          style: variants.body1
        },
        {
          props: { variant: 'body2' },
          style: variants.body2
        },

        /* Custom variants */
        {
          props: { variant: 'light-italic' },
          style: {
            fontStyle: 'italic',
            fontWeight: 300
          }
        },
        {
          props: { variant: 'medium-italic' },
          style: {
            fontStyle: 'italic',
            fontWeight: 400
          }
        },
        {
          props: { variant: 'regular' },
          style: { fontWeight: 400 }
        },
        {
          props: { variant: 'medium' },
          style: { fontWeight: 500 }
        },
        {
          props: { variant: 'bold' },
          style: { fontWeight: 700 }
        },
        {
          props: { variant: 'extra-bold' },
          style: { fontWeight: 800 }
        },
        {
          props: { variant: 'heavy' },
          style: { fontWeight: 800 }
        }
      ]
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      ss: 410,
      sm: 600,
      smd: 760,
      md: 900,
      mm: 1050,
      lg: 1200,
      lg_header: 1280,
      ml: 1400,
      xl: 1536,
      xxl: 1800
    }
  }
};

let theme = createTheme(deepmerge(lightTheme, themeOptions));

theme = responsiveFontSizes(theme, {
  breakpoints: [
    'xs',
    'ss',
    'sm',
    'smd',
    'md',
    'mm',
    'lg',
    'lg_header',
    'ml',
    'xl',
    'xxl'
  ],
  variants: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  factor: 2.5
});

export default theme;

export { globalSectionPadding };

export interface ResponsiveProps {
  xl?: string;
  lg?: string;
  xs?: string;
  ss?: string;
  sm?: string;
  md?: string;
  mm?: string;
  ml?: string;
}
