import { NavigateNext } from '@mui/icons-material';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  Typography
} from '@mui/material';
import Link from 'next/link';
import styles, { getStyles } from './styles';
import { BreadcrumbItem } from '../../types/common';

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  theme?: 'dark' | 'light';
}

export default function Breadcrumbs({
  items,
  theme = 'dark'
}: BreadcrumbsProps) {
  return (
    <MuiBreadcrumbs
      separator={<NavigateNext fontSize="small" />}
      sx={getStyles(theme)}
      aria-label="breadcrumb"
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1;

        if (isLast) {
          return (
            <Typography
              key={item.label}
              variant="subtitle2"
              component="span"
              sx={styles.lastItem}
            >
              {item.label}
            </Typography>
          );
        }

        return (
          <MuiLink
            key={item.label}
            underline="hover"
            color="inherit"
            component={Link}
            prefetch={false}
            href={item.href}
            variant="subtitle2"
          >
            {item.label}
          </MuiLink>
        );
      })}
    </MuiBreadcrumbs>
  );
}
