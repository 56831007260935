import { lenderImages } from './Constants/lenderImages';
import propertyListPagePathConstant from './Constants/propertyListPagePathConstant';
import { getEnvVar, isReactApp } from './reactUtils';
import { getCookie } from '../components/CookieModal/consent';

export const camelize = (s: string) =>
  s.replace(/-./g, (x) => x[1].toUpperCase());
export default {
  camelize
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renameKeys = (obj: object): any =>
  Object.fromEntries(
    Object.entries(obj).map(([key, val]) => [
      key
        .trim()
        .replace(/_/g, '')
        .replace(key[0], key[0].toLowerCase())
        .replace(/[A-Z]{2,}/g, (match) => match.toLowerCase()),
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      processVal(val)
    ])
  );

const processVal = (val: unknown) => {
  if (typeof val !== 'object' || val === null || val === undefined) {
    return val;
  }

  if (Array.isArray(val)) {
    return val.map(renameKeys);
  }

  return renameKeys(val);
};

// Function to convert price numbers into string with comma-seperated
export const numberWithCommas = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const photoStringPropertyPics = (
  pic: string,
  imgName: string,
  baseUrl: string
) => {
  const revString = pic
    .slice(pic.length - 7, pic.length)
    .split('')
    .reverse()
    .join('');

  const refactoredNum = `${baseUrl}${revString.slice(0, 2)}/${revString.slice(
    2,
    4
  )}/${revString.slice(4, 7)}/w/480/${imgName.replace('edited_', '')}`;

  return refactoredNum;
};

export const setUserIntoStorage = (userData: Record<string, unknown>) => {
  if (typeof window === 'undefined') {
    return;
  }

  const foxtonsStr = localStorage.getItem('foxtons');
  let foxtonsObj = !foxtonsStr ? null : JSON.parse(foxtonsStr);

  if (!foxtonsObj) {
    foxtonsObj = { user: userData };
  } else {
    foxtonsObj = { ...foxtonsObj, user: userData };
  }

  localStorage.setItem('foxtons', JSON.stringify(foxtonsObj));
};

export const getUserFromStorage = () => {
  if (typeof window === 'undefined') {
    return { user: null };
  }

  const isLoggedIn = getCookie('is_logged_in') === 'True';

  const foxtonsStr = localStorage.getItem('foxtons');

  const userObj = JSON.parse(foxtonsStr)?.user || {};

  // return isLoggedIn if isLoggedIn cookie is "True"
  // return user other information if available
  // return default values for profile, username, and contact so that page don't crash if optional chaining is not used
  return {
    ...(JSON.parse(foxtonsStr) || {}),
    user: {
      profile: {},
      userName: '',
      contactId: '',
      ...(JSON.parse(foxtonsStr)?.user || {}), // override above values if avialble
      isLoggedIn: userObj?.isLoggedIn || isLoggedIn
    }
  };
};

export const caseInsensitiveFullPoscodeUKRegex =
  /^(([A-Za-z]{1,2}[0-9][A-Za-z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Za-z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Za-z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i;

export const postcodeOutCodeRegex =
  /^([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA)/;

const ukShortPostCodeRegex =
  /^([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA)$/;

export const ukLongPostCodeRegex =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const ukFullPostCodeRegex =
  /^([A-Za-z]{1,2}[0-9][A-Z0-9a-z]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA|[Gg][Ii][Rr] 0[Aa]{2}|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2}|([A-Z]{1,2}\d{1,2}[A-Za-z]?)))?$/;

// exclude all special characters except comma(,) period(.) and(&) hyphen(-) apostrophe(') exclamation(!) at-rate(@) question-mark(?) white-space( ) lower/upper case alphabets and numbers
export const searchSpecialCharsRegex = /[^\dA-Za-z\s]/g;
export const freeTextFeildSpecialCharsRegex = /^[^<>{}[\]~|]*$/;
export const invalidCharsRegexForSearch = /[<>{}[\]~|]/g;
// Regex to detect emojis (disabling no-misleading eslint error as the regex is correct and it serves its purpose)
// eslint-disable-next-line no-misleading-character-class
export const emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\u200d\uFE0F]/u;

export const hasNoSpecialChars = (value: string) => {
  // remove numbers and # as emoji regex is restricting them
  const refinedValue = value.replace(/[0-9#]/g, '');

  return (
    freeTextFeildSpecialCharsRegex.test(refinedValue) &&
    !emojiRegex.test(refinedValue)
  );
};

export const convertPrice = (price: string, float?: boolean) => {
  const convertedPrice = parseFloat(price);

  const pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: float ? 2 : 0,
    minimumFractionDigits: float ? 2 : 0
  });

  return pounds.format(convertedPrice as number);
};

export const photoAssetUrl = (
  baseUrl: string,
  pic: string,
  imgName: string,
  timestamp: string,
  width = '480'
) => {
  if (!pic) return '';
  const assetUrl = `${baseUrl}/w/${width}/${timestamp}/${imgName.replace(
    'edited_',
    ''
  )}`;

  return assetUrl;
};

export const photoAssetUrlNew = (
  pic: string,
  imgName: string,
  timestamp: string,
  width = '480',
  baseUrl?: string
) => {
  if (!pic) return '';

  const assetsUrl = process.env.NEXT_PUBLIC_FOXTONS_ASSET_URL || '';

  const assetUrl = `${
    baseUrl ?? assetsUrl
  }/w/${width}/${timestamp}/${imgName.replace('edited_', '')}`;

  return assetUrl;
};

export const randomStr = (length: number, characters: string) => {
  let result = '';
  const charactersLength = characters.length;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getSiteSection = (paths: string[]) => {
  if (paths[0] === '') {
    return 'home';
  }

  return paths.length > 1 ? paths[0] : undefined;
};

export function saveAuthActionInSession(action: {
  hasLoggedIn: boolean;
  redirectUrl: string;
  actionName: string;
  formData?: unknown;
  propertyData?: {
    postcodeShort: string;
    locationName: string;
    streetName: string;
    isSneakPeek: boolean;
    price: string;
    bath: string;
    bed: string;
    area: string;
    image: string;
  };
  schoolData?: {
    name: string;
    level: string;
    address: string;
    area?: string;
    webName: string;
    leaWebName: string;
  };
}) {
  const {
    hasLoggedIn,
    propertyData,
    redirectUrl,
    actionName,
    formData,
    schoolData
  } = action;

  const dataToSave = {
    hasLoggedIn,
    redirectUrl,
    action: actionName,
    formData,
    schoolData: !schoolData
      ? undefined
      : {
          name: schoolData.name,
          level: schoolData.level,
          address: schoolData.address,
          area: schoolData?.area,
          webName: schoolData?.webName,
          leaWebName: schoolData?.leaWebName
        },
    propertyData: !propertyData
      ? undefined
      : {
          image: propertyData.image,
          price: propertyData.price,
          bath: propertyData.bath,
          bed: propertyData.bed,
          area: propertyData.area,
          isSneakPeek: propertyData.isSneakPeek,
          address: `${propertyData.streetName}, ${propertyData.locationName}, ${propertyData.postcodeShort}`
        }
  };

  sessionStorage.setItem('propertyAction', JSON.stringify(dataToSave));
}

export const getAuthActionFromSessionStorage = () => {
  const propertyAction =
    typeof window === 'undefined'
      ? undefined
      : JSON.parse(sessionStorage.getItem('propertyAction') || '{}');

  const propertyData = propertyAction
    ? propertyAction?.propertyData
    : undefined;

  const action = propertyAction ? propertyAction?.action : undefined;
  const hasLoggedIn = propertyAction?.hasLoggedIn || false;
  const redirectUrl = propertyAction?.redirectUrl || '';
  const formData = propertyAction?.formData || undefined;
  const schoolData = propertyAction?.schoolData || undefined;

  return {
    action,
    propertyData,
    hasLoggedIn,
    redirectUrl,
    formData,
    schoolData
  };
};

// Fees-may-apply Calculator
export const feesCalc = (propType: string, price: string | number) => {
  const numnericPrice = Number(price);
  let calcPrice = 0;

  if (propType === 'letting') {
    calcPrice = numnericPrice * 5;

    return numberWithCommas(calcPrice);
  }

  calcPrice = numnericPrice > 700 ? numnericPrice : 700;

  return numberWithCommas(calcPrice);
};

export const bindComponentOnClickHandler = (href: string) => {
  const componentPath = href.replace(/component:/, '');
  const [componentName, propertyName] = componentPath.split('/');

  sessionStorage.setItem(
    'foxtonsComponentStates',
    JSON.stringify({ key: [componentName], value: true })
  );

  if (componentName === 'ChineseContactForm' && propertyName) {
    sessionStorage.setItem('selectedMarketingPropertyName', propertyName);
  }

  window.dispatchEvent(new Event('storage'));
};

export const setLanguage = (lang: string) => {
  sessionStorage.setItem('foxtonsLanguage', lang);
  window.dispatchEvent(new Event('storage'));
};

export const getLanguage = () => {
  const lang = sessionStorage.getItem('foxtonsLanguage');

  return lang;
};

export const isChristmas = () => {
  const currentDate = new Date();
  const formattedDate =
    (currentDate.getMonth() + 1) * 100 + currentDate.getDate();

  const isChristmasTime = formattedDate > 1200 || formattedDate < 105;

  return isChristmasTime;
};

export const getInstructionType = (
  searchType: 'sale' | 'letting' | 'short_letting' | 'null'
) => {
  switch (searchType) {
    case 'sale':
      return 'SS';
    case 'letting':
      return 'LL';
    case 'short_letting':
      return 'SH';
    default:
      return 'SS';
  }
};

// disabling lint for map api function
/* eslint-disable */
export function decodePolyline(polyline: string) {
  const _ = {};

  _.Ya = function (a, b, c) {
    b != null && (a = Math.max(a, b));
    c != null && (a = Math.min(a, c));

    return a;
  };

  _.Za = function (a, b, c) {
    c -= b;

    return ((((a - b) % c) + c) % c) + b;
  };

  _.w = function (a) {
    return a ? a.length : 0;
  };

  _.E = function (a, b, c) {
    a -= 0;
    b -= 0;
    c || ((a = _.Ya(a, -90, 90)), b != 180 && (b = _.Za(b, -180, 180)));

    this.lat = function () {
      return a;
    };

    this.lng = function () {
      return b;
    };
  };

  function decodePath(a) {
    for (
      var b = _.w(a),
        c = Array(Math.floor(a.length / 2)),
        d = 0,
        e = 0,
        f = 0,
        g = 0;
      d < b;
      ++g
    ) {
      let h = 1;
      let l = 0;
      var n;

      do (n = a.charCodeAt(d++) - 63 - 1), (h += n << l), (l += 5);
      while (n >= 31);
      e += h & 1 ? ~(h >> 1) : h >> 1;
      h = 1;
      l = 0;
      do (n = a.charCodeAt(d++) - 63 - 1), (h += n << l), (l += 5);
      while (n >= 31);
      f += h & 1 ? ~(h >> 1) : h >> 1;
      c[g] = new _.E(1e-5 * e, 1e-5 * f, !0);
    }

    c.length = g;

    return c;
  }

  const result = decodePath(polyline).map((el) => ({
    lat: Number(el.lat().toFixed(5)),
    lng: Number(el.lng().toFixed(5))
  }));

  return result;
}
/* eslint-enable */
// enabling list after map api function

export const getOfficeImage = (officeName: string, size = 480) =>
  `${process.env.NEXT_PUBLIC_ASSET_URL}/img/offices/${size}/${
    officeName || ''
  }.webp`;

export const getOfficeCardLink = (officeName: string) =>
  `/contact/${(officeName || '')
    .toLowerCase()
    .replace(/['&]/g, '')
    .replace(/\s+/g, '_')}_estate_agents`;

export const getOfficeFullAddress = (
  address: string | { lines: string[]; postcode: string }
) => {
  if (typeof address === 'string') {
    return address;
  }

  return `${address?.lines?.[0] || ''}, ${address?.lines?.[1] || ''}, ${
    address?.postcode || ''
  }`;
};

export const getWebNameFromOfficeName = (name) =>
  name
    .replace('Foxtons ', '')
    .toLowerCase()
    .replace(/['&]/g, '')
    .replace(/ +/g, '-');

export const getWebNameFromLocationName = (name) => {
  if (typeof name !== 'string' || !name.length) return '';

  return name.toLowerCase().replace(/['&]/g, '').replace(/ +/g, '-');
};

export const isUKLongPostCode = (value: string) => {
  if (!value) {
    return false;
  }

  return ukLongPostCodeRegex.test(value.replaceAll('-', ''));
};

export const capitalizeAndUpperUKPostcodes = (inputString: string) =>
  inputString
    ?.replace(/[- _]/g, ' ')
    ?.split(' ')
    .map((word) => {
      if (isUKLongPostCode(word) || isUkShortPostCode(word)) {
        // Convert UK short postcode to uppercase
        return word.toUpperCase();
      }

      if (
        word.length >= 7 &&
        /^[A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s?\d[A-Za-z]{2}$/.test(word)
      ) {
        // Convert UK long postcode to uppercase
        return word.toUpperCase();
      }

      // Capitalize the first letter of each word
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

/**
 * The function `getSpecialCharError` checks if a given string contains special characters based on the
 * specified field type and returns an error message if it does.
 * @param {string} value - The value is the string that you want to check for special characters.
 * @param {string} fieldType - The `fieldType` parameter is a string that specifies the type of field
 * being checked for special characters. It can have one of the following values: phone, postCode, name
 * @param [regex] - The `regex` parameter is an optional parameter that allows you to provide a custom
 * regular expression pattern to match against the `value` string. If a `regex` value is provided, it
 * will override the default regular expression pattern for special characters.
 * @returns The function `getSpecialCharError` returns a string.
 */
export const getSpecialCharError = (
  value: string,
  fieldType: string,
  regex = ''
): string => {
  let specialCharReg = /[~`#$%^&*+=\\[\]\\;/{}|\\":<>()_]/g;
  let errorMsg = 'Special characters are not allowed';

  if (fieldType === 'phone') {
    specialCharReg = /[~`!@#$%^&*=\\[\]\\;/{}|\\":<>()_-]/g;
    errorMsg = 'Special characters are not allowed except +';
  }

  if (fieldType === 'postCode') {
    specialCharReg = /[~`@#!$%^&*+=\\[\]\\;/{}|\\":<>()_-]/g;
    errorMsg = 'Special characters are not allowed';
  }

  if (fieldType === 'name') {
    specialCharReg = /[~`@#!$%^*+=\\[\]\\;/{}|\\":<>()_]/g;
    errorMsg = 'Special characters are not allowed except - &';
  }

  if (fieldType === 'email') {
    specialCharReg = /[~`#$%^*=\\[\]\\;/{}|\\":<>()]/g;
    errorMsg = 'Special characters are not allowed except + _ &';
  }

  if (regex) {
    specialCharReg = new RegExp(regex, 'g');
  }

  return specialCharReg.test(value) ? errorMsg : '';
};

export const getLenderImage = (lenderName: string, lenderCode: string) => {
  let SmallImageSecureUrl = '';

  let imgUrl;
  const imgName = lenderName.toLowerCase().replace(/ /g, '_');
  const baseImgUrl = isReactApp()
    ? getEnvVar('ASSET_URL')
    : process.env.NEXT_PUBLIC_ASSET_URL;

  if (lenderImages.indexOf(imgName.concat('.png')) !== -1) {
    imgUrl = `${baseImgUrl}/img/logos/alexander_hall/lenders/${imgName.concat(
      '.png'
    )}`;
  } else if (lenderImages.indexOf(imgName.concat('.jpg')) !== -1) {
    imgUrl = `${baseImgUrl}/img/logos/alexander_hall/lenders/${imgName.concat(
      '.jpg'
    )}`;
  }

  if (imgUrl) {
    SmallImageSecureUrl = imgUrl;
  } else {
    SmallImageSecureUrl = lenderCode
      ? `https://images.twenty7tec.com/images/small-logos/${lenderCode}.gif`
      : "[% i('/mortgages/featured_mortgage.gif') %]";
  }

  return SmallImageSecureUrl;
};

export const checkMortgageData = (price: string) => {
  if (typeof window === 'undefined') {
    return null;
  }

  // Retrieve data from local storage
  const storedData = localStorage.getItem('mortgage');

  // Check if data is present
  if (storedData) {
    // Parse the JSON string to get the object
    const mortgageData = JSON.parse(storedData);
    const mortgageYears = mortgageData?.mortgage_years;
    const mortgageRate = mortgageData?.mortgage_rate;
    const mortgageDeposit = mortgageData?.mortgage_deposit;
    const mortgageType = mortgageData?.mortgage_type;
    const mortgageOverPay = mortgageData?.mortgage_overpay;
    const mortgageLumpSum1 = mortgageData?.mortgage_lumpsum1;

    if (mortgageType === 'Interest-only') {
      const loan = Number(price) - Number(mortgageDeposit);
      let repay = parseFloat(
        (loan * mortgageYears * (mortgageRate / 100)) / (12 * mortgageYears)
      ).toFixed(2);

      repay = repay < 0 ? 0 : repay;

      return `${convertPrice(repay, true)} monthly mortgage`;
    }

    const rate = Number(mortgageRate);
    const ratePerMonth = rate / 1200; // decimal rate per month
    const p = Number(price) - Number(mortgageDeposit);
    const m = Number(mortgageYears) * 12; // term in months
    const loan = (1 + ratePerMonth) ** m;
    const repaymentPerMonth = Math.ceil(p / ((1 - 1 / loan) / ratePerMonth));

    if (mortgageOverPay) {
      const overpay = Number(mortgageOverPay);
      const lumpsum1 = Number(mortgageLumpSum1);
      const overPaymentPerMonth = overpay;
      let repaymentPerMonthWithOverpay = repaymentPerMonth;

      if (overPaymentPerMonth > 0 || lumpsum1 > 0) {
        repaymentPerMonthWithOverpay = repaymentPerMonth + overpay;

        return `£${repaymentPerMonthWithOverpay} monthly mortgage`;
      }
    }

    return `£${repaymentPerMonth} monthly mortgage`;
  }

  return 'Calculate my mortgage payments';
};

export const getMortgageData = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  // Retrieve data from local storage
  const storedData = localStorage.getItem('mortgage');

  if (storedData) {
    const mortgageData = JSON.parse(storedData);

    return mortgageData;
  }

  return null;
};

export const sortFilters = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;

  return 0;
};

export const isSearchPage = (pathname: string) => {
  const urlPath = pathname?.split('/');

  urlPath.shift();

  return (
    urlPath.length === 2 &&
    Object.values(propertyListPagePathConstant).some(
      (item) => urlPath[0] === item
    )
  );
};

export const getBlogUrlPath = (heading = '', publishedDate = '') => {
  const pathname = heading
    ?.replace(/[’”“]/g, 'a')
    ?.replace(/[£]/g, 'A')
    .replace(/[^a-z0-9 /]/gi, '')
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/\s/g, '-')
    .toLowerCase();
  const datePublished = new Date(publishedDate);
  const publishedYear = datePublished?.getFullYear();
  const publishedMonth = datePublished
    ? `${
        datePublished.getMonth() < 9
          ? `0${datePublished.getMonth() + 1}`
          : datePublished.getMonth() + 1
      }`
    : undefined;

  return `/discover/${publishedYear}/${publishedMonth}/${pathname}`;
};

// function to handle schoolType for API
export const labelConversion = (label: string) => {
  switch (label) {
    case 'nursery':
    case 'nursery-schools':
      return 'nursery';
    case 'primary':
    case 'primary-schools':
      return 'primary';
    case 'secondary':
    case 'secondary-schools':
      return 'secondary';
    case 'sixth':
    case 'sixth-forms-and-colleges':
      return 'sixth';
    case 'university':
    case 'universities':
      return 'university';
    default:
      return 'nursery';
  }
};

export const stripURLSpecialChars = (inputValue: string) =>
  inputValue
    ?.replace(/&/g, '')
    .replace(/\s+/g, ' ')
    .replace(/[^a-z0-9 /]/gi, '')
    .trim()
    .replace(/\s/g, '-')
    .replace(/'/g, '')
    .toLowerCase() || '';

export const getAlternateCardsLayout = (
  id: number,
  isDesktopScreen: boolean
) => {
  // if mobile screen, return normal column arrangement
  if (!isDesktopScreen) {
    return 'column';
  }

  // if desktop, alternate between values of row
  return id % 2 === 0 ? 'row' : 'row-reverse';
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
  context: any = this
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

const isUkShortPostCode = (value: string) =>
  ukShortPostCodeRegex.test(value?.toUpperCase());

export const formatSearchTerm = (slug = '') => {
  const postCodeSearchTermRegex =
    /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;
  const splitSearchTerm = slug.split('-');
  const postCodeSearchTerm = slug.replaceAll('-', ' ').toUpperCase();

  // if search term is a postcode or a short postcode
  if (
    postCodeSearchTermRegex.test(postCodeSearchTerm) ||
    isUkShortPostCode(postCodeSearchTerm)
  ) {
    return postCodeSearchTerm;
  }

  // if last part of search term is short post code
  if (isUkShortPostCode(splitSearchTerm[splitSearchTerm.length - 1])) {
    const shortPostCode = splitSearchTerm.pop();

    return `${splitSearchTerm
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')}, ${shortPostCode?.toUpperCase()}`;
  }

  // Capitalize the first letter of each word
  return splitSearchTerm
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getMyFoxtonsPortalUrl = () =>
  isReactApp() || process.env.NEXT_PUBLIC_PORTAL_VERSION === 'new'
    ? '/account/overview'
    : '/account/';

export const getSearchType = (typeProperty: string) => {
  if (
    typeProperty === 'properties-for-sale' ||
    typeProperty === 'properties-for-auction' ||
    typeProperty === 'houses-for-sale' ||
    typeProperty === 'flats-for-sale' ||
    typeProperty === 'apartments-for-sale' ||
    typeProperty === 'houses-for-auction' ||
    typeProperty === 'apartments-for-auction' ||
    typeProperty === 'flats-for-auction'
  ) {
    return 'SS';
  }

  if (
    typeProperty === 'properties-to-rent' ||
    typeProperty === 'flats-to-rent' ||
    typeProperty === 'apartments-to-rent' ||
    typeProperty === 'houses-to-rent'
  ) {
    return 'LL';
  }

  if (
    typeProperty === 'short-let-properties' ||
    typeProperty === 'short-let-flats' ||
    typeProperty === 'short-let-apartments' ||
    typeProperty === 'short-let-houses'
  ) {
    return 'SH';
  }

  return '';
};

/* eslint-disable no-useless-escape */
export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3,4}\)?)\s?-?\s?(\(?\d{4}\)?)$/;

export const phoneRegexValidator = (phone: string) => {
  const specialCharReg = /[~`#$%^&*=\\[\]\\;/{}|\\":<>()_]/g;
  let error = '';

  if (!phoneRegex.test(phone)) {
    error = 'Valid phone number required';
  }

  if (specialCharReg.test(phone ?? '')) {
    error = 'Special characters are not allowed, except +';
  }

  return error;
};

export const propertiesFor = (slugString: string | string[]) => {
  const typeProp = slugString;
  const {
    propertiesForAuction,
    propertiesForLongLet,
    propertiesForSale,
    propertiesForShortLet,
    housesForSale,
    flatsForSale,
    apartmentsForSale,
    housesForLongLet,
    flatsForLongLet,
    apartmentsForLongLet,
    housesForShortLet,
    flatsForShortLet,
    apartmentsForShortLet
  } = propertyListPagePathConstant;

  switch (typeProp) {
    case propertiesForLongLet:
    case housesForLongLet:
    case flatsForLongLet:
    case apartmentsForLongLet:
    case 'letting':
    case 'LL':
      return 'letting';
    case propertiesForShortLet:
    case housesForShortLet:
    case flatsForShortLet:
    case apartmentsForShortLet:
    case 'short_letting':
    case 'SL':
      return 'short_letting';
    case propertiesForSale:
    case housesForSale:
    case apartmentsForSale:
    case flatsForSale:
    case 'sale':
    case propertiesForAuction:
    case 'SS':
      return 'sale';
    default:
      return 'sale';
  }
};

export const getPropertyTypeForTitle = (
  propertyTypeInUrl: string,
  plural = false
): string => {
  let propertyType = '';

  if (
    propertyTypeInUrl.startsWith('apartments') ||
    propertyTypeInUrl.startsWith('flats') ||
    propertyTypeInUrl.endsWith('apartments') ||
    propertyTypeInUrl.endsWith('flats')
  ) {
    propertyType = plural ? 'Flats & Apartments' : 'Flat & Apartment';
  } else if (
    propertyTypeInUrl.startsWith('houses') ||
    propertyTypeInUrl.endsWith('houses')
  ) {
    propertyType = plural ? 'Houses' : 'House';
  } else {
    propertyType = plural ? 'Properties' : 'Property';
  }

  return propertyType;
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
