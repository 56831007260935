import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styles from './styles';
import { Box, Button } from '@mui/material';
import useClientOnly from '@utils/customHooks/ClientOnly';

export const usePreviewBar = () => {
  const [isExitingPreviewMode, setIsExitingPreviewMode] = useState(false);
  const router = useRouter();

  const exitPreviewMode = async () => {
    if (isExitingPreviewMode) {
      return;
    }

    setIsExitingPreviewMode(true);
  };

  const removePreviewCookie = async () => {
    const response = await fetch('/api/exit-preview', {
      cache: 'no-cache'
    });

    setIsExitingPreviewMode(false);

    if (response) {
      if (router.asPath.indexOf('/preview') !== 0) {
        router.reload();
      } else {
        const path = router.asPath.replace('/preview', '').replace('/exit', '');

        router.push(path);
      }
    }
  };

  useEffect(() => {
    if (isExitingPreviewMode) {
      removePreviewCookie();
    }
  }, [isExitingPreviewMode]);

  return { isExitingPreviewMode, exitPreviewMode };
};

const PreviewBar = () => {
  const router = useRouter();
  const { exitPreviewMode } = usePreviewBar();
  const { hasMounted } = useClientOnly();

  useEffect(() => {
    if (router.asPath.indexOf('/preview') !== 0 && router.isPreview) {
      exitPreviewMode();
    }
  }, [router.asPath, hasMounted]);

  return !router.isPreview ||
    router.asPath.indexOf('/preview/exit') === 0 ||
    router.asPath.indexOf('/preview') !== 0 ||
    !hasMounted ? null : (
    <Box sx={styles.previewModeLabel}>
      <Box>You are currently viewing in Preview Mode.</Box>
      &nbsp;
      <Button sx={styles.exitPreviewButton} onClick={exitPreviewMode}>
        Turn Off Preview Mode
      </Button>
    </Box>
  );
};

export default PreviewBar;
