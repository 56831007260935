/**
 * This file should host the API Request handlers for both
 * * Portal API
 * * CMS API
 */
import { isReactApp } from '../reactUtils';
import fetchData from './fetch-client';
import { RequestConfig, ResponseData } from './types';

const canCache: boolean =
  !isReactApp() &&
  process.env.CONNECT_TO_REDIS === 'true' &&
  process.env.NODE_ENV === 'production' &&
  process.env.NEXT_PHASE !== 'phase-production-build'; // do not request from redis in phase production build

const defaultBaseUrl = isReactApp()
  ? import.meta.env.VITE_PORTAL_BASE_URL
  : process.env.NEXT_PUBLIC_PORTAL_BASE_URL;

/*
// function to call http request. config contains method and url api endpoint
httpRequest(config)
config: {method: 'GET | POST | DELETE | PUT', url: `api_endpoint_string`}
*/
export const httpRequest = async (
  config: RequestConfig
): Promise<ResponseData | Response> => {
  const options: RequestInit = {
    body: config?.data ? JSON.stringify(config.data) : undefined,
    method: config?.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...config?.headers,
      Authorization:
        config.headers?.Authorization?.indexOf('authenticated_user') > -1 ||
        config.headers?.Authorization?.indexOf('Bearer undefined') > -1 // keep this check as it is as new BE is setting cookie same as old BE.
          ? ''
          : config.headers?.Authorization || ''
    },
    signal: config?.signal,
    credentials: 'include'
  };

  return fetchData(
    `${config.url.startsWith('http') ? '' : defaultBaseUrl}${config.url}`,
    options
  );
};

export const httpCMSRequest = async (
  config: RequestConfig
): Promise<ResponseData | Response> => {
  const cacheKey = `STRAPI - ${config.url}`;
  const IsDraftPage = config.url?.indexOf('get-draft-content?pageUrl=') > -1;

  if (canCache && !IsDraftPage) {
    /**
     * Check if the data is available in redis
     * if available return the data
     */

    try {
      const fetchFromRedis = await fetchData(
        `${process.env.NEXT_LOCAL_REDIS_URL}/fx-cache/keys/get?secret=${process.env.NEXT_PUBLIC_SECRET}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ key: cacheKey })
        }
      );

      if (fetchFromRedis.data) {
        return fetchFromRedis;
      }
    } catch (getError) {
      // eslint-disable-next-line no-console
      console.log({ getError: getError.message });
    }
  }

  /**
   * Fetch data from CMS API
   * if data is fetched successfully, send it to redis
   */
  const options: RequestInit = {
    body: config?.data ? JSON.stringify(config.data) : undefined,
    method: config?.method || 'GET',
    headers: {
      ...config?.headers,
      Authorization: process.env.NEXT_PUBLIC_CMS_API_TOKEN
        ? `Bearer ${process.env.NEXT_PUBLIC_CMS_API_TOKEN}`
        : ''
    }
  };

  const data = await fetchData(
    isReactApp()
      ? `${import.meta.env.VITE_CMS_BASE_URL}${config.url}`
      : `${process.env.NEXT_PUBLIC_CMS_BASE_URL}${config.url}`,
    options
  );

  if (canCache && !IsDraftPage) {
    /**
     * Send data to redis
     * if data is sent successfully, return the data
     */

    try {
      await fetchData(
        `${process.env.NEXT_LOCAL_REDIS_URL}/fx-cache/keys/set?secret=${process.env.NEXT_PUBLIC_SECRET}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            key: cacheKey,
            data: data.data
          })
        }
      );
    } catch (setError) {
      // eslint-disable-next-line no-console
      console.log({ setError: setError.message });
    }
  }

  return data;
};

export const bosWsiRequest = async (
  config: RequestConfig
): Promise<ResponseData | Response> => {
  const options: RequestInit = {
    body: config?.data ? JSON.stringify(config.data) : undefined,
    method: config?.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...config?.headers
    },
    signal: config?.signal
  };

  return fetchData(
    `${config.url.startsWith('http') ? '' : defaultBaseUrl}${config.url}`,
    options
  );
};

export const isRequestCancelled = (error: Error): boolean =>
  error.name === 'AbortError';
