import Script from 'next/script';

/* 
  This is a custom web component that will be used to render the login button/Email dropdown in the header
  before React hydration. This is to ensure that the user interactions are being dispathed as custom events
  and React can behave according to user's interactions and expectations after hydration.

  Important element IDs:
  - loggedin-dropdown-wc: The dropdown menu that appears when the user clicks on the email
  - logout-btn-wc: The logout button in the dropdown menu

  List of events that are being dispatched for React to listen to:
  - Dropdown open/close change using the 'authDropdownChange' event
  - Logout button click event using the 'authLogoutClick' event
*/

function AuthWebComponentScript() {
  return (
    <Script strategy="beforeInteractive" id="login-web-component">
      {`
        (function() {
          // login styles
          const styles = document.createElement('template');
          styles.innerHTML = \`
            <style>
              .loggedin-container {
                position: relative;                
              }
              #loggedin-email-container {
                display: flex;
                flex-direction: row;
                -webkit-box-align: center;
                align-items: center;
                width: 100%;
                cursor: pointer;

                -webkit-box-pack: justify;
                justify-content: space-between;
                min-width: 0;
                max-width: 9.5rem;
                margin-top: 0.25rem;
              }

              @media (min-width: 600px) {
                #loggedin-email-container {
                  min-width: 11rem;
                  max-width: 14rem;
                }
              }

              .loggedin-label {
                margin: 0;
                font-family: var(--open-sans);
                font-size: 0.75rem;
                line-height: 1.4;
                font-weight: 400;
                color: #333333;
              }
              .loggedin-email {
                margin: 0;
                line-height: 1.5;
                font-size: 0.85rem;
                font-weight: 400;
                color: #017163;
              }
              .loggedin-arrowIcon {
                -webkit-user-select: none; 
                user-select: none;
                display: inline-block;
                flex-shrink: 0;
                font-size: 1.5rem;
                fill: currentcolor;
                margin-left: 0.25rem;
                width: 1.25rem;
                height: 1.25rem;
                color: rgb(1, 113, 99);
                transform: rotate(90deg);
                transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1);
              }
              .login-button {
                display: inline-flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                position: relative;
                box-sizing: border-box;
                -webkit-tap-highlight-color: transparent;
                cursor: pointer;
                -webkit-user-select: none; 
                user-select: none;
                vertical-align: middle;
                appearance: none;
                min-width: 64px;
                font-family: var(--open-sans);
                font-size: 1.125rem;
                line-height: 1.2;
                box-shadow: none;
                text-transform: none;
                color: rgb(51, 51, 51);
                letter-spacing: 0px;
                text-align: center;
                padding: 0.5rem 1.2rem;
                background-color: rgb(255, 255, 255);
                width: 100%;
                font-weight: 900;
                outline: 0px;
                margin: 0px;
                text-decoration: none;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
                border-radius: 0.25rem;
                border: 1px solid rgb(255, 255, 255);
              }
              .loggedin-dropdown {
                display: none;
                position: absolute;
                top: 2.4rem;
                right: 0;
                background-color: #fff;
                color: rgba(0, 0, 0, 0.87);
                -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                border-radius: 4px;
                box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                -webkit-transform: translateY(1.25rem);
                -moz-transform: translateY(1.25rem);
                -ms-transform: translateY(1.25rem);
                transform: translateY(1.25rem);
                padding: 0.75rem 1rem;
                border: 0.063rem solid #E6F6F6;
                width: 12rem;
              }
              .loggedin-dropdown.open {
                display: block;
              }
              .loggedin-dropdown .menu-title {
                display: inline-block;
                text-decoration: none;
                color: rgb(51, 51, 51);
                font-size: 0.85rem;
                line-height: 1.5;
                width: 100%;
              }
              .loggedin-dropdown button {
                background-color: transparent;
                cursor: pointer;
                font-family: var(--open-sans);
                font-size: 0.85rem;
                line-height: 1.2;
                color: rgb(215, 0, 26);
                text-align: left;
                width: 100%;
                margin: 0;
                border: 0;
                outline: 0;
                padding: 0;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
              }
              .loggedin-dropdown hr {
                margin: 0.8rem -1rem;
                border-width: 0px 0px thin;
                border-style: solid;
                border-color: rgb(229, 229, 229);
              }

              .login-button-title {
                margin: 0;
                font-weight: 700;
                font-size: 1.125rem;
                line-height: 1.2;
                text-transform: none;
              }

              @keyframes loader-spin {to{transform: rotate(1turn)}}
              .loader {
                width: 10px;
                aspect-ratio: 1;
                border-radius: 50%;
                border: 3px solid #90CCC5;
                border-right-color: #017163;
                animation: loader-spin 1s infinite linear;
                display: none;
                float: right;
                filter: grayscale(1);
              }
              
              #logout-btn-wc.pending {
                cursor: not-allowed;
                color: #ccc;
              }
              #logout-btn-wc.pending .loader {
                display: inline-block;
              }
            </style>
          \`;

          const getClippedEmail = (email) => {
            if (email.length > 16) {
              return \`\${email.substring(0, 14)}...\`;
            }

            return email;
          };

          // Utility function to get login state from cookie
          function getLoginStateFromCookie() {
            if (typeof window === 'undefined') {
              return false;
            }

            const cookie = document.cookie.split(';').find((cookie) => 
              cookie.trim().startsWith('is_logged_in')
            );

            return cookie 
              ? cookie.split('=')[1].toLowerCase() === 'true' 
              : false;
          }

          
          function createLoggedInElement() {
            const wrapper = document.createElement('div');
            const email = JSON.parse(localStorage.getItem('foxtons'))?.user?.userName;

            wrapper.innerHTML = \`
              <div class="loggedin-container">
                <div id="loggedin-email-container">
                  <div class="MuiBox-root">
                    <p class="MuiTypography-root MuiTypography-body2 loggedin-label">You are logged in as</p>
                    <h6 class="MuiTypography-root MuiTypography-subtitle2 loggedin-email">
                      \${getClippedEmail(email)}
                    </h6>
                  </div>
                  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium loggedin-arrowIcon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowForwardIosRoundedIcon"><path d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"></path></svg>
                </div>
                
                <div class="loggedin-dropdown" id="loggedin-dropdown-wc">
                  <div class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1">
                    <div class="MuiBox-root">
                      <a class="menu-title MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways" href="/account/overview" target="_blank">
                        My Foxtons
                      </a>
                      <hr class="MuiDivider-root MuiDivider-middle">
                      <button id="logout-btn-wc" class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary" tabindex="0" type="button">
                        Logout
                        <span class="loader"></span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>
                    </div>
                  </div>
                <div>
              </div>
            \`;
            return wrapper.firstElementChild;
          }

          function createLoggedOutElement() {
            const wrapper = document.createElement('div');
            wrapper.innerHTML = \`
              <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways fxt-osw6sx" href="/auth/login">
                <button class="login-button MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary" tabindex="0" type="button">
                  <span class="login-button-title MuiTypography-root MuiTypography-button">Login</span>
                  <span class="MuiTouchRipple-root fxt-w0pj6f"></span>
                </button>
              </a>
            \`;
            return wrapper.firstElementChild;
          }

          // Custom Web Component
          class AuthWebComponent extends HTMLElement {
            constructor() {
              super();
              const shadow = this.attachShadow({ mode: 'open' });

              // Determine login state
              const isLoggedIn = getLoginStateFromCookie();

              // Create and append appropriate element
              const element = isLoggedIn 
                ? createLoggedInElement() 
                : createLoggedOutElement();

              shadow.appendChild(element);
              shadow.appendChild(styles.content.cloneNode(true));

              const emailContainer = shadow.querySelector('#loggedin-email-container');
              const logoutBtn = shadow.querySelector('#logout-btn-wc');
              const dropdown = shadow.querySelector('#loggedin-dropdown-wc');

              if(emailContainer) {
                emailContainer.addEventListener('click', () => {
                  dropdown.classList.toggle('open');
                  
                  const event = new CustomEvent('authDropdownChange', {
                    detail: { isOpen: dropdown.classList.contains('open') },
                  });

                  window.dispatchEvent(event);
                });
              }

              if(logoutBtn) {
                logoutBtn.addEventListener('click', () => {
                  logoutBtn.disabled = true;
                  logoutBtn.classList.add('pending');

                  const event = new CustomEvent('authLogoutClick');

                  window.dispatchEvent(event);
                });
              }
            }
          }

          customElements.define('auth-component', AuthWebComponent);
        })();
      `}
    </Script>
  );
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'auth-component': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export default AuthWebComponentScript;
