// This is a duplicate of packages\fdl\src\components\Header\rightSideNavigation.tsx
// with the portal specific code removed. Until I can rewrite the code to
// be more efficient.
import { useState } from 'react';
import { Box, IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { Close } from '@mui/icons-material';
import { useLink } from '@foxtons/fdl/utils/reactUtils';

// src
import { Button as MuiButton } from '@foxtons/fdl/components/Button/index';
import Login from './login';
import {
  AUTH_CONTAINER_ID,
  getAuthContainer,
  useAuthDropdownChange
} from '@foxtons/fdl/components/Header/AuthWebComponent/index';

// styles
import styles from '@foxtons/fdl/components/Header/rightSideNavigationStyles';
import useClientOnly from '@foxtons/fdl/utils/customHooks/useClientOnly/index';

interface RightSideNavigationProps {
  userDetails?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  valuation: {
    label: string;
    href: string;
  };
  login: {
    label: string;
    href: string;
  };
  handleSignout: () => void;
  handleDrawerToggle: () => void;
  drawerState?: boolean;
  handleDrawerClose: () => void;
  hasNotifications?: boolean;
  onMouseEnter?: () => void;
}

function RightSideNavigation({
  userDetails,
  valuation,
  login,
  handleSignout,
  handleDrawerToggle,
  drawerState,
  handleDrawerClose,
  onMouseEnter
}: RightSideNavigationProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(
    getAuthContainer
  );

  const { hasMounted } = useClientOnly();
  const Link = useLink();

  useAuthDropdownChange((isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  });

  const canBeOpen = isDropdownOpen && Boolean(anchorEl);
  const idPopper = canBeOpen ? 'emailDropdownPopper' : undefined;

  // Toggling state of Login dropdown
  const handleClickDropdown = (event: React.MouseEvent<HTMLElement>) => {
    // condition to close drawer if opened in mobile view
    if (drawerState === true) {
      handleDrawerClose();
    }

    setAnchorEl(event.currentTarget);
    setIsDropdownOpen((previousOpen) => !previousOpen);
  };

  // Clickaway closing action
  const handleClickAway = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }} onMouseEnter={onMouseEnter}>
      <Box sx={styles.signOutButtonWrapper} gap="0.5rem">
        <Box sx={styles.rightBtnLinkBox}>
          <Box sx={styles.btnsWrapper}>
            {/* Valuation Button */}
            <Link prefetch={false} target="_blank" href={valuation?.href}>
              <MuiButton
                color="primary"
                variant="primary-dark"
                sx={styles.valuationButton}
              >
                {valuation?.label}
              </MuiButton>
            </Link>
          </Box>

          {/* Debugging tip: replace the whole <Box id={AUTH_CONTAINER_ID}> with following line */}
          {/* <auth-component /> */}

          <Box id={AUTH_CONTAINER_ID}>
            {!hasMounted ? (
              <auth-component />
            ) : (
              <Login
                userDetails={userDetails}
                login={login}
                handleSignoutLogin={handleSignout}
                handleClickLogin={handleClickDropdown}
                handleClickAwayLogin={handleClickAway}
                isPopperOpen={isDropdownOpen}
                isPopperId={idPopper}
                anchorElPopper={anchorEl}
              />
            )}
          </Box>
        </Box>
      </Box>

      {/* HamburgerIcon to toggle drawer state in mobile view */}
      <IconButton
        sx={styles.iconButtonClose}
        onClick={handleDrawerToggle}
        aria-label="menu"
      >
        {drawerState ? <Close /> : <MenuIcon />}
      </IconButton>
    </Box>
  );
}

export default RightSideNavigation;
