import React from 'react';
import { SvgIcon } from '@mui/material';
const SvgLoader = (props) => (
  <SvgIcon {...props} title="Loader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        display: 'block',
        shapeRendering: 'auto'
      }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <g transform="translate(90 50)">
        <circle r={6} fill="#017163">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-1.2499999999999998s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="-1.2499999999999998s"
          />
        </circle>
      </g>
      <g transform="rotate(45 -55.355 133.64)">
        <circle r={6} fill="#017163" fillOpacity={0.875}>
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-1.0714285714285714s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="-1.0714285714285714s"
          />
        </circle>
      </g>
      <g transform="rotate(90 -20 70)">
        <circle r={6} fill="#017163" fillOpacity={0.75}>
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.8928571428571428s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.8928571428571428s"
          />
        </circle>
      </g>
      <g transform="rotate(135 -5.355 43.64)">
        <circle r={6} fill="#017163" fillOpacity={0.625}>
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.7142857142857142s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.7142857142857142s"
          />
        </circle>
      </g>
      <g transform="rotate(180 5 25)">
        <circle r={6} fill="#017163" fillOpacity={0.5}>
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.5357142857142857s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.5357142857142857s"
          />
        </circle>
      </g>
      <g transform="rotate(-135 15.355 6.36)">
        <circle r={6} fill="#017163" fillOpacity={0.375}>
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.3571428571428571s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.3571428571428571s"
          />
        </circle>
      </g>
      <g transform="rotate(-90 30 -20)">
        <circle r={6} fill="#017163" fillOpacity={0.25}>
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.17857142857142855s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.17857142857142855s"
          />
        </circle>
      </g>
      <g transform="rotate(-45 65.355 -83.64)">
        <circle r={6} fill="#017163" fillOpacity={0.125}>
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            values="0.02 0.02;1 1"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.4285714285714284s"
            repeatCount="indefinite"
            values="1;0"
            begin="0s"
          />
        </circle>
      </g>
    </svg>
  </SvgIcon>
);
export default SvgLoader;
