import React from 'react';
import { SvgIcon } from '@mui/material';

function SvgBarVertical(props) {
  return (
    <SvgIcon {...props} title="BarVertical">
      <svg
        viewBox="0 0 2 20"
        fill="#000"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect width={2} height={20} rx={1} />
      </svg>
    </SvgIcon>
  );
}

export default SvgBarVertical;
