import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgFoxtonsWhite = (props) => (
  <SvgIcon {...props} title="FoxtonsWhite">
    <svg
      viewBox="0 0 121 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M90.43 8.643h-3.708v20.705h4.502V14.909c1.324-1.09 3.178-2.452 5.297-2.452 1.853 0 2.913 1.362 2.913 4.36v12.259h4.767V15.182c0-5.449-3.443-7.356-6.091-7.356-2.384 0-4.502.817-6.886 2.724l-.795-1.907ZM53.059 29.348l-7.086-10.76 6.824-10.762h-5.25l-4.199 6.513-4.199-6.513H33.9l6.824 10.761-7.086 10.76h5.249l4.461-6.795 4.462 6.796h5.249ZM.624.652v28.696h4.967V16.777h7.726l1.655-4.373H5.591V4.752h10.485l1.38-4.1H.624Z"
        fill="#fff"
      />
      <path
        d="M54.074 12.655v9.539c0 5.298 3.493 7.153 6.18 7.153 1.075 0 2.419-.265 3.494-.53l1.612-4.769c-1.075.53-2.419 1.325-3.762 1.325-1.881 0-2.956-1.325-2.956-4.24v-8.212h4.568l1.344-3.71h-5.912V3.912l-4.837 2.385V9.21l-2.687 3.71h2.956v-.266ZM119.618 8.916c-1.796-.817-3.078-1.09-5.131-1.09-4.36 0-7.182 2.452-7.182 5.994 0 4.086 3.078 5.448 5.387 6.538 1.795.817 3.335 1.362 3.335 2.997 0 1.362-1.283 2.18-3.335 2.18-1.796 0-3.591-.818-4.617-1.363l-1.283 3.814c1.796.818 3.848 1.362 5.9 1.362 4.874 0 7.695-2.452 7.695-6.538s-3.078-5.449-5.643-6.538c-1.796-.818-3.335-1.363-3.335-2.725 0-1.634 1.539-1.907 2.822-1.907 1.796 0 3.591.545 5.387 1.362V8.916ZM74.746 7.826c-5.835 0-9.387 4.904-9.387 10.897 0 6.266 3.299 10.625 9.387 10.625 5.836 0 9.387-4.631 9.387-10.897 0-5.721-3.551-10.625-9.387-10.625Zm0 17.708c-3.551 0-4.82-3.541-4.82-6.81 0-3.27 1.015-6.811 4.82-6.811 3.552 0 4.82 3.541 4.82 6.81 0 3.27-1.268 6.811-4.82 6.811ZM24.9 7.826c-5.834 0-9.386 4.904-9.386 10.897 0 6.266 3.298 10.625 9.387 10.625 5.835 0 9.387-4.631 9.387-10.897 0-5.721-3.552-10.625-9.387-10.625Zm0 17.708c-3.551 0-4.82-3.541-4.82-6.81 0-3.27 1.015-6.811 4.82-6.811 3.553 0 4.821 3.541 4.821 6.81 0 3.27-1.268 6.811-4.82 6.811Z"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
);

export default SvgFoxtonsWhite;
