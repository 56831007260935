import { Component, ErrorInfo, ReactNode } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import NotFoundIcon from '../../icons/BrokenComputer';
import { Button } from '../Button';

import styles from './styles';

interface Props {
  children?: ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  // getDerivedStateFromError does recieve error as an argument

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to Sentry here
    // eslint-disable-next-line no-console
    console.error('Error caught by Error Boundary:', error, errorInfo);
  }

  render() {
    // Check if the error is thrown
    const { hasError } = this.state;
    const { children, buttonText, onButtonClick } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Box sx={styles.root}>
          <NotFoundIcon
            /* Props for hiding the bigger red sphere and making size smaller */
            sx={styles.iconStyling}
            width="60"
            height="60"
            viewBox="20 20 75 75"
          />

          <Typography variant="h2" sx={styles.mainHeading}>
            Oops, looks like something went wrong
          </Typography>
          <Typography sx={styles.subHeading}>
            We&apos;re working to resolve this error and should have it fixed
            shortly.
          </Typography>
          <Box mt={2}>
            <Grid container spacing={0.5} sx={styles.ctaWrapper}>
              <Grid item>
                <Button
                  onClick={() => {
                    this.setState({ hasError: false });

                    if (onButtonClick) {
                      onButtonClick();
                    }
                  }}
                  variant="primary-dark"
                  sx={styles.button}
                >
                  {buttonText || 'Try again?'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }

    // Return children components in case of no error

    return children;
  }
}

export default ErrorBoundary;
