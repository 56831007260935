// This is a duplicate of packages\fdl\src\components\Header\login.tsx
// with the portal specific code removed. Until I can rewrite the code to
// be more efficient.
import React from 'react';

import {
  Box,
  Typography,
  Link,
  Paper,
  Divider,
  ClickAwayListener
} from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Button } from '@foxtons/fdl/components/Button/index';

// styles
import styles from '@foxtons/fdl/components/Header/rightSideNavigationStyles';
import { getMyFoxtonsPortalUrl } from '@foxtons/fdl/utils/index';
import TinyPopper from '@foxtons/fdl/components/Header/TinyPopper';

// src

interface LoginProps {
  userDetails?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  login: {
    label: string;
    href: string;
  };
  handleSignoutLogin: () => void;
  handleClickLogin: (event: React.MouseEvent<HTMLElement>) => void;
  handleClickAwayLogin: () => void;
  isPopperOpen: boolean;
  isPopperId: string;
  anchorElPopper: HTMLElement;
}

function Login({
  userDetails,
  login,
  handleSignoutLogin,
  handleClickLogin,
  handleClickAwayLogin,
  isPopperOpen,
  isPopperId,
  anchorElPopper
}: LoginProps) {
  // if email is longer then clipping it (shows on sign up when name is not available)
  const getClippedEmail = (email: string) => {
    if (email.length > 16) {
      return `${email.substring(0, 14)}...`;
    }

    return email;
  };

  return userDetails?.email ? (
    <ClickAwayListener onClickAway={handleClickAwayLogin}>
      <Box>
        <Box sx={styles.popperBtnBoxWeb}>
          <Box onClick={handleClickLogin} sx={styles.btnBox}>
            <Box>
              <Typography sx={styles.loggedinTitle} variant="body2">
                You are logged in as
              </Typography>
              <Typography sx={styles.loggedinEmail} variant="subtitle2">
                {getClippedEmail(userDetails?.email)}
              </Typography>
            </Box>

            <ArrowForwardIosRoundedIcon
              sx={{
                ...styles.arrowDown,
                transform: isPopperOpen ? 'rotate(-90deg)' : 'rotate(90deg)'
              }}
            />
          </Box>
        </Box>

        <TinyPopper
          id={isPopperId}
          open={isPopperOpen}
          anchorEl={anchorElPopper}
          placement="bottom-end"
        >
          <Paper sx={styles.popperBoxWeb}>
            <Box>
              <Link href={getMyFoxtonsPortalUrl()} target="_blank">
                <Typography
                  variant="subtitle2"
                  component="span"
                  sx={styles.foxtonsText}
                >
                  My Foxtons
                </Typography>
              </Link>
              <Divider variant="middle" sx={styles.divider} />
              <Button
                sx={styles.signoutBtnWeb}
                onClick={handleSignoutLogin}
                variant="text"
              >
                <Typography variant="subtitle2" component="span">
                  Logout
                </Typography>
              </Button>
            </Box>
          </Paper>
        </TinyPopper>
      </Box>
    </ClickAwayListener>
  ) : (
    <Link href={login?.href} sx={styles.link}>
      <Button sx={styles.btnLogin}>
        <Typography variant="button" component="span">
          {login?.label}
        </Typography>
      </Button>
    </Link>
  );
}

export default Login;
