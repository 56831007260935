import { isReactApp } from '../reactUtils';

export function replaceWithServerURL(url: RequestInfo): RequestInfo {
  if (typeof window === 'undefined' && typeof url === 'string') {
    const serverURL = process.env.NEXT_PRIVATE_SERVER_BASE_URL;

    if (!serverURL) {
      throw new Error('Server URL is not defined in environment variables');
    }

    return url.replace(process.env.NEXT_PUBLIC_PORTAL_BASE_URL, serverURL);
  }

  return url;
}

export function getEnvVarOrDefault(variable: string | undefined): string {
  // this function is being used in API ENDPOINTs and where some APIS are being used in property card
  // and property card is being used on boths sides Website and Portal.
  // Portal does not read environment variables like process.env which is why we need to add the isReactApp check
  if (variable !== undefined) {
    return variable;
  }

  if (!isReactApp()) {
    if (process.env.NEXT_PUBLIC_PORTAL_BASE_URL !== undefined) {
      return process.env.NEXT_PUBLIC_PORTAL_BASE_URL as string;
    }
  } else {
    return '';
  }

  throw new Error(
    `Environment variable ${variable} is not defined and no fallback provided`
  );
}
