import {
  Box,
  ButtonProps,
  Button as MuiButton,
  SxProps,
  Typography
} from '@mui/material';
import deepmerge from '@mui/utils/deepmerge';
import React, { useState } from 'react';
import styles from './styles';
import { getBtnStyles, getIcon } from './utility';
import { bindComponentOnClickHandler, setLanguage } from '../../utils/index';
import { isReactApp } from '../../utils/reactUtils';
import { lightTheme } from '../../theme/index';

type ButtonVariant =
  | 'text'
  | 'outlined'
  | 'bordered'
  | 'contained'
  | 'neutral'
  | 'primary'
  | 'primary-dark'
  | 'secondary'
  | 'tertiary'
  | 'back'
  | 'switch-lang-cn'
  | 'switch-lang-en';

export interface LibButtonProps extends ButtonProps {
  theme?: 'dark' | 'light';
  isNotButton?: boolean;
  variant?: ButtonVariant;
  iconColor?: string;

  /**
   * Pass sx of container if the variant of a button depends on the container
   * for example, if the background color of the container is white,
   * the button should be `primary-dark` if `primary` is passed as variant
   */
  containerSxForVariant?: SxProps;
}

const getSwitchedLang = (variant: string) => {
  switch (variant) {
    case 'switch-lang-cn':
      return 'cn';
    case 'switch-lang-en':
    default:
      return 'en';
  }
};

const isLanguageSwitchButton = (variant: LibButtonProps['variant']) => {
  if (variant === 'switch-lang-en' || variant === 'switch-lang-cn') {
    return true;
  }

  return false;
};

const getVariantFromContainerSx = (
  variant?: ButtonVariant,
  containerSx?: SxProps
) => {
  if (variant === 'primary' && containerSx) {
    const sxObject = Array.isArray(containerSx)
      ? containerSx.reduce((acc, cur) => ({ ...acc, ...cur }), {})
      : containerSx;

    // if the background color of the container is not white, return primary
    return (sxObject.backgroundColor &&
      sxObject.backgroundColor !== lightTheme.palette.grey['50']) ||
      (sxObject.background &&
        sxObject.background !== lightTheme.palette.grey['50']) ||
      (sxObject.bgcolor && sxObject.bgcolor !== lightTheme.palette.grey['50'])
      ? 'primary'
      : 'primary-dark';
  }

  return variant;
};

function LibButton({
  theme = 'light',
  color = 'primary',
  size = isReactApp() ? 'large' : 'medium',
  iconColor = 'primary',
  variant: _variant,
  containerSxForVariant,
  ...props
}: LibButtonProps) {
  const variant = getVariantFromContainerSx(_variant, containerSxForVariant);
  const [isHover, setIsHover] = useState(false);

  const hasComponentBinding = props?.href?.indexOf('component:') > -1;

  const getButtonBinding = (e) => {
    bindComponentOnClickHandler(props?.href);
    props?.onClick?.(e);
  };

  const getClickHandler = (e) => {
    if (hasComponentBinding) {
      return getButtonBinding(e);
    }

    if (isLanguageSwitchButton(variant)) {
      return setLanguage(getSwitchedLang(variant));
    }

    return props?.onClick ? props?.onClick?.(e) : undefined;
  };

  if (props?.isNotButton) {
    const buttonStyles = {
      ...getBtnStyles(variant, color, theme, props.startIcon)
    };

    delete buttonStyles[':hover'];

    return (
      <Box
        color={color}
        sx={deepmerge(
          { ...styles.common },
          deepmerge(
            { ...styles[size] },
            deepmerge({ ...buttonStyles }, { ...props.sx })
          )
        )}
      >
        {isReactApp() ? (
          props.children || variant
        ) : (
          <Typography variant="button">{props.children || variant}</Typography>
        )}
      </Box>
    );
  }

  return (
    <MuiButton
      variant={isReactApp() ? undefined : variant}
      onMouseOver={variant === 'tertiary' ? () => setIsHover(true) : undefined}
      onMouseLeave={
        variant === 'tertiary' ? () => setIsHover(false) : undefined
      }
      {...props}
      href={
        hasComponentBinding || isLanguageSwitchButton(variant)
          ? undefined
          : props?.href
      }
      onClick={(e) => getClickHandler(e)}
      color={color}
      startIcon={getIcon(
        variant,
        isHover,
        iconColor || color,
        props.startIcon,
        size
      )}
      sx={deepmerge(
        { ...styles.common },
        deepmerge(
          { ...styles[size] },
          deepmerge(
            { ...getBtnStyles(variant, color, theme, props.startIcon) },
            { ...props.sx }
          )
        )
      )}
    >
      {props.children || variant}
    </MuiButton>
  );
}

export const Button = React.memo(LibButton) as typeof LibButton;

export default Button;
