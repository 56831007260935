import { SxProps } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { lightTheme } from '../../theme/index';
import { appStyles } from './buttonTheme';

const { palette } = lightTheme;

const styles: Record<string, SxProps> = {
  common: deepmerge(
    {
      boxShadow: 'none',
      textTransform: 'none',
      borderRadius: '0.25rem',
      color: palette.primary.main,
      letterSpacing: '0',
      ':disabled': {
        color: palette.grey['300'],
        svg: {
          fill: palette.grey['300']
        }
      }
    },
    appStyles?.common || {}
  ),
  small: deepmerge(
    {
      height: '2.125rem',
      paddingX: '1rem',
      paddingY: '0.75rem',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      svg: {
        width: '0.75rem'
      }
    },
    appStyles?.small || {}
  ),
  medium: deepmerge(
    {
      paddingX: '1.2rem',
      svg: {
        width: '1rem'
      }
    },
    appStyles?.medium || {}
  ),
  large: deepmerge(
    {
      paddingY: '0.875rem',
      paddingX: '1.5rem',
      fontStyle: 'normal',
      fontWeight: { xs: '900', md: '800' },
      svg: {
        width: '1rem'
      }
    },
    appStyles?.large || {}
  ),
  back: {
    border: 'none',
    color: palette.grey['400'],
    svg: {
      fill: palette.grey['400']
    },
    ':hover': {
      boxShadow: 'none'
    }
  },
  primary: deepmerge(
    {
      backgroundColor: palette.secondary.main,
      color: palette.grey.main,
      ':hover': {
        backgroundColor: {
          xs: palette.secondary.main,
          md: palette.secondary['300']
        },
        borderColor: {
          xs: palette.secondary.main,
          md: palette.secondary['300']
        },
        boxShadow: 'none',
        color: palette.grey['500']
      },
      ':disabled': {
        backgroundColor: palette.grey['100']
      }
    },
    appStyles?.primary || {}
  ),
  neutralInverse: {
    backgroundColor: palette.grey[50],
    border: `0.063rem solid ${palette.grey.main}`,
    color: palette.grey.main,
    ':hover': {
      backgroundColor: palette.grey.main,
      color: palette.grey[50],
      boxShadow: 'none'
    },
    ':disabled': {
      backgroundColor: palette.grey['100']
    }
  },
  primaryDark: {
    backgroundColor: palette.primary.main,
    color: palette.grey[50],
    ':hover': {
      backgroundColor: palette.primary['600']
    },
    ':disabled': {
      backgroundColor: palette.grey['100']
    }
  },
  secondary: deepmerge(
    {
      border: `0.0625rem solid ${palette.secondary['600']}`,
      backgroundColor: palette.primary['400'],
      color: palette.primary.main,
      boxShadow: 'none',
      svg: {
        transition: '0.1s',
        fill: palette.primary.main,
        width: '1rem'
      },
      ':disabled': {
        backgroundColor: palette.grey['100']
      },
      ':hover': {
        backgroundColor: palette.primary.main,
        color: palette.grey['50'],
        boxShadow: 'none',
        svg: {
          transition: '0.1s',
          fill: palette.grey['50']
        }
      }
    },
    appStyles?.secondary || {}
  ),
  borderedInverse: {
    border: `0.119rem solid ${palette.primary.main}`,
    color: palette.primary.main,
    boxShadow: 'none',
    svg: {
      transition: '0.1s',
      fill: palette.primary.main,
      width: '1rem'
    },
    ':disabled': {
      backgroundColor: palette.grey['100']
    },
    ':hover': {
      backgroundColor: palette.primary.main,
      color: palette.grey['50'],
      boxShadow: 'none',
      svg: {
        transition: '0.1s',
        fill: palette.grey['50']
      }
    }
  },
  tertiary: deepmerge(
    {
      border: 'none',
      backgroundColor: 'transparent',
      color: palette.grey.main,
      boxShadow: 'none',
      paddingLeft: '1.5rem',
      paddingRight: '0',
      ':hover': {
        boxShadow: 'none',
        border: 'none',
        background: 'transparent'
      }
    },
    appStyles?.tertiary || {}
  ),
  iconText: deepmerge(
    {
      border: `0.0625rem solid ${palette.primary['200']}`,
      backgroundColor: palette.grey['50'],
      color: palette.primary.main,
      boxShadow: 'none',
      svg: {
        transition: '0.1s',
        fill: palette.grey.main,
        width: '1rem'
      },
      ':hover': {
        boxShadow: 'none',
        backgroundColor: palette.primary['100'],
        color: palette.primary.main,
        svg: {
          transition: '0.1s',
          fill: palette.primary.main
        }
      }
    },
    appStyles?.iconText || {}
  ),
  primaryInverse: deepmerge(
    {
      backgroundColor: palette.grey['50'],
      border: `0.0625rem solid ${palette.grey['500']}`,
      color: palette.grey['500']
    },
    appStyles?.primaryInverse || {}
  ),
  secondaryInverse: deepmerge(
    {
      backgroundColor: palette.grey['50'],
      border: `0.0625rem solid ${palette.primary['200']}`,
      color: palette.primary.main
    },
    appStyles?.secondaryInverse || {}
  ),
  tertiaryInverse: {
    backgroundColor: palette.grey['50'],
    border: 'none',
    color: palette.secondary.main,
    ':hover': {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: palette.grey['50']
    }
  },
  iconTextInverse: {
    border: 'none',
    backgroundColor: palette.grey['50'],
    color: palette.primary.main,
    boxShadow: 'none',
    svg: {
      transition: '0.1s',
      fill: palette.primary.main,
      width: '1rem'
    },
    ':hover': {
      backgroundColor: palette.grey['50']
    }
  },
  languageSwitchButtonStyles: {
    width: {
      xs: '100%',
      md: 'auto'
    },
    paddingX: '1.2rem',
    paddingY: '0.63rem',
    fontStyle: 'normal',
    lineHeight: '1.2',
    color: palette.primary.main,
    borderRadius: '0.25rem',
    backgroundColor: palette.grey['50'],
    border: `0.0625rem solid ${palette.grey['500']}`,
    '&:hover': {
      backgroundColor: palette.grey['50']
    },
    '.MuiSvgIcon-root': {
      fontSize: '1rem'
    }
  }
};

export default styles;
