// libs
import { useState, lazy, Suspense } from 'react';
import Header from '../Header/index';
import FoxtonsFilled from '@foxtons/fdl/icons/FoxtonsFilled';
import FoxtonsWhite from '@foxtons/fdl/icons/FoxtonsWhite';

import paths from '@paths/index';
import { useRouter } from 'next/router';
import API_ENDPOINTS from '@utils/httpRequestConfigs/apiEndpoints';
import { useUserFromStorage } from '@foxtons/fdl/utils/customHooks/useUserFromStorage';
import { useAuthLogoutClick } from '@foxtons/fdl/components/Header/AuthWebComponent';

const SignoutDrawer = lazy(() => import('@components/SignoutDrawer'));

interface HeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerProps?: any;
}

function GetHeader(props: HeaderProps) {
  const [showSignout, setShowSignout] = useState(false);
  const [mountDrawer, setMountDrawer] = useState(false);

  const router = useRouter();
  const [user] = useUserFromStorage();

  useAuthLogoutClick(() => {
    setShowSignout(true);
  });

  const toggleSignoutDrawer = () => {
    setShowSignout((prevState) => !prevState);
  };

  return (
    <>
      <Header
        iconMain={<FoxtonsFilled />}
        titleMainLogo={<FoxtonsWhite />}
        pathName={paths.home}
        pathContactUs={paths.contactUs}
        pathLogin={paths.login}
        pathValuation={paths.valuation}
        isHomePage={router.asPath === '/'}
        onSignoutClick={toggleSignoutDrawer}
        user={{
          email: user?.userName || '',
          lastName: user?.profile?.lastName,
          firstName: user?.profile?.firstName
        }}
        propsHeader={props.headerProps}
        apiPropertySearchWidget={{
          baseUrl: process.env.NEXT_PUBLIC_PORTAL_BASE_URL || '',
          endpoint: API_ENDPOINTS.autoCompleteProperty || ''
        }}
        onMouseNearAuthElements={() => setMountDrawer(true)}
      />

      {/* Signout Drawer */}
      {mountDrawer && (
        <Suspense fallback={null}>
          <SignoutDrawer isOpen={showSignout} onClose={toggleSignoutDrawer} />
        </Suspense>
      )}
    </>
  );
}

export default GetHeader;
