import { SxProps } from '@mui/material';
import theme from '@theme/index';

const { palette } = theme;
const styles: Record<string, SxProps> = {
  previewModeLabel: {
    zIndex: '9999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0.125rem 0.5rem',
    backgroundColor: palette.primary.main,
    color: palette.secondary.main,
    position: 'fixed',
    top: '0.1rem',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '0.85rem',
    lineHeight: '0.95rem',
    fontWeight: 700
  },
  exitPreviewButton: {
    cursor: 'pointer',
    fontWeight: 700,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    padding: '0',
    border: 'none',
    color: palette.secondary.main
  }
};

export default styles;
