import { useLayoutEffect } from 'react';

/* If Auth Dropdown is openned in the Web Component, this event will be dispatched by AuthWebComponent.
   React can listen to this event and update the state accordingly. */
export const useAuthDropdownChange = (callback: (isOpen: boolean) => void) => {
  useLayoutEffect(() => {
    const handleAuthDropdownChange = (event: CustomEvent) => {
      callback(event.detail.isOpen);
    };

    window.addEventListener(
      'authDropdownChange',
      handleAuthDropdownChange as EventListener
    );

    return () => {
      window.removeEventListener(
        'authDropdownChange',
        handleAuthDropdownChange as EventListener
      );
    };
  }, [callback]);
};

/* If Auth Logout is clicked in the Web Component, this event will be dispatched by AuthWebComponent.
     React can listen to this event and update the state accordingly. */
export const useAuthLogoutClick = (callback: () => void) => {
  useLayoutEffect(() => {
    window.addEventListener('authLogoutClick', callback as EventListener);

    return () => {
      window.removeEventListener('authLogoutClick', callback as EventListener);
    };
  }, [callback]);
};
