import { useEffect } from 'react';
import { hasConsent } from '../../components/CookieModal/consent';
import { getCookiePermission } from '../../components/CookieModal/utils';

const noop = () => {};

const useGoogleTagManager = ({
  gtmId,
  environment,
  afterScriptLoads
}: {
  gtmId: string;
  environment: string;
  afterScriptLoads?: () => void;
}) => {
  useEffect(() => {
    if (!environment || environment === 'local' || environment === 'dev') {
      return noop;
    }

    if (!(typeof gtmId === 'string' && gtmId.length)) {
      return noop;
    }

    if (!hasConsent()) {
      window.cookie_dependant.push(() =>
        addGTMScript(gtmId).then(afterScriptLoads)
      );

      return noop;
    }

    addGTMScript(gtmId).then(afterScriptLoads);

    return noop;
  }, [gtmId]);
};

const addGTMScript = async (gtmId: string) =>
  new Promise((resolve) => {
    if (!getCookiePermission('performance')) {
      resolve();

      return;
    }

    // Check if the script is already injected
    if (
      document.getElementById('gtm-script') ||
      document.querySelectorAll(
        '[src^="https://www.googletagmanager.com/gtm.js"]'
      ).length
    ) {
      resolve();

      return;
    }

    // After the gtm script has loaded, set the window.gtab function
    // and add google analytics tag - but only if we have an ENV var for it
    const gtmPostScript = () => {
      window.gtag = function () {
        window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
      };

      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied'
      });

      window.gtag('js', new Date());

      if (typeof process.env.NEXT_PUBLIC_GTAG === 'string') {
        window.gtag('config', process.env.NEXT_PUBLIC_GTAG, {
          send_page_view: true
        });
      }

      resolve();
    };

    // Add the GTM script content
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; // eslint-disable-line no-param-reassign

      if (!w[l].some((e) => e['gtm.start'])) {
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      }

      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';

      j.async = true;
      j.onload = gtmPostScript;
      j.id = 'gtm-script';
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i + dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmId);

    // Cleanup function to remove the script if needed
  });

export default useGoogleTagManager;
