const propertyListPagePathConstant = {
  propertiesForSale: 'properties-for-sale',
  propertiesForShortLet: 'short-let-properties',
  propertiesForLongLet: 'properties-to-rent',
  propertiesForAuction: 'properties-for-auction',
  recommendedRentals: 'recommended-rentals',
  recommendedShortLets: 'recommended-short-lets',
  housesForSale: 'houses-for-sale',
  housesForAuction: 'houses-for-auction',
  housesForLongLet: 'houses-to-rent',
  housesForShortLet: 'short-let-houses',
  flatsForAuction: 'flats-for-auction',
  flatsForSale: 'flats-for-sale',
  flatsForShortLet: 'short-let-flats',
  flatsForLongLet: 'flats-to-rent',
  apartmentsForAuction: 'apartments-for-auction',
  apartmentsForSale: 'apartments-for-sale',
  apartmentsForLongLet: 'apartments-to-rent',
  apartmentsForShortLet: 'short-let-apartments'
};

export default propertyListPagePathConstant;
