/**
 * This file will contain all the URL for the API endpoints
 * Whether it is a CMS API or Portal API,
 * These should be grouped based on the API they belong to
 */

import { getEnvVarOrDefault } from './utils';
import { isReactApp } from '../reactUtils';

const propertyBaseUrl = getEnvVarOrDefault(
  isReactApp()
    ? import.meta.env.VITE_FXPROPERTY_BASE_URL
    : process.env.NEXT_PUBLIC_FXPROPERTY_BASE_URL
);

export const CMS_API_ENDPOINTS = {
  autoCompleteProperty: 'search/autocomplete?keyword=',
  postcode: 'postalcode',
  search: 'search',
  locations: 'locations',
  addresses: 'addresses',
  feesAndTerms: '/let/fees_and_terms',
  valuation: 'valuation/instant',
  booking: 'valuation/booking',
  getAllOffices: 'offices/all',
  savedProperties: 'contacts/properties/saved',
  mySavedPlaces: (contactId: string) => `contacts/${contactId}/addressbook`,
  mySchools: (contactId: string) => `contacts/${contactId}/schools`,
  saveDeleteSchools: (contactId: string) => `contacts/${contactId}/schools`,
  counterSVG: '/counter.svg',
  getNearbyAreasByCord: (lat: number, lon: number) =>
    `search/locations/postcode?lat=${lat}&lon=${lon}`,
  anySavedSchoolList: (sharedKey) =>
    `contacts/shared-data/schools/${sharedKey}`,
  deleteSavedProperty: (propertyRegCode: string, instructionType: string) =>
    `contacts/properties/saved/${propertyRegCode}/instructions/${instructionType}`,
  getLocations: (searchTerm: string) =>
    `search/locations/coordinates?searchTerm=${searchTerm}&latLonOnly=true`,
  mortgageDeals: 'mortgage/deals'
};

export const PORTAL_API_ENDPOINTS = {
  user: 'user',
  logoutUser: 'account/logout'
};

export const FX_API_ENDPOINTS = {
  genesysQueueStatus: (queueName) =>
    `${propertyBaseUrl}genesys/genesys/queue/analytics/${queueName}`
};
