import { CompanySlug } from './types';

export const recordAsModalSeen = (slug: CompanySlug) => {
  const expiry = new Date();
  expiry.setTime(+expiry + 400 * 86400000); // 400 is max cookie expiry for Chrome

  document.cookie = `${slug}Accepted=true; expires=${expiry.toUTCString()}; path=/`;
};

export const hasSeenModal = (slug: CompanySlug): boolean => {
  if (typeof document === 'undefined') {
    return false;
  }

  if (typeof slug === 'string' && slug.length) {
    return document.cookie.includes(`${slug}Accepted=true`);
  }

  return false;
};
