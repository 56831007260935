/* eslint-disable */

import { randomStr } from '../../utils/index';
import { getCookie, getCookieFromPage, hasConsent } from './consent';

let today = new Date();

const cookieTypeMappings = {
  session: 'necessary',
  foxtons_session: 'necessary', // needed for myfoxtons login
  foxtons_session_dev: 'necessary', // needed for myfoxtons login
  _access_token_auth_token_u: 'necessary', // needed for myfoxtons login
  visit_secure_token2: 'necessary', // needed for myfoxtons login
  is_logged_in: 'necessary',
  cookie_preferences: 'necessary',
  'marketing-prefs-set': 'necessary', //check whether we should query the logged in users marketing preferences
  '/^preprotog_visibility_[a-z0-9]+$/': 'necessary',
  preferences: 'functional',
  search: 'necessary',
  ipo_restricted: 'FIXME',
  ipo_accessed: 'FIXME',
  // The following are not "real" cookies, but used for determining if e.g. google analytics are allowed

  vimeo: 'functional', // used to determine if we tell vimeo to disable cookies
  londonstoneAccepted: 'functional', // redirect
  pillarsAccepted: 'functional', // redirect
  stonesresidentialAccepted: 'functional', // redirect
  gordonandcoAccepted: 'functional', // redirect

  _ga: 'performance',
  '/^_ga_[a-zA-Z0-9]+$/': 'performance',
  _gat: 'performance',
  _gid: 'performance',
  _gat_UA: 'performance',
  tmx: 'performance',
  tmxx: 'performance',

  tbp: 'performance',
  twp2: 'performance',
  twp: 'performance',
  tws: 'performance',
  tco: 'performance',
  utm_campaign: 'performance',
  utm_source: 'performance',
  utm_medium: 'performance',
  utm_term: 'performance',
  utm_content: 'performance',
  /*Addthis cookies */
  _atssc: 'performance',
  _atuvc: 'performance',
  _atuvs: 'performance',
  /* Crazyegg cookies */
  _ceir: 'performance',
  is_returning: 'performance',
  _CEFT: 'performance',
  '_ceg.s': 'performance',
  '_ceg.u': 'performance',
  s: 'performance',
  u: 'performance',
  'cer.s': 'performance',
  'cer.v': 'performance',
  /*LeanConvert Campaign Cookies*/
  'lc-sp-uid': 'performance',
  /*Referrer tracking*/
  referrer: 'performance',
  /*xandr cookies*/
  uuid2: 'performance',
  anj: 'performance',
  /* Convert cookies */
  _conv_v: 'performance',
  _conv_s: 'performance',
  _conv_r: 'performance',
  _conv_sptest: 'performance'
};

const pendingCookieKey = 'pending_cookies';
if (typeof window !== 'undefined' && window[pendingCookieKey] === undefined) {
  window[pendingCookieKey] = [];
}

const cookieDependantKey = 'cookie_dependant';

if (typeof window !== 'undefined' && window[cookieDependantKey] === undefined) {
  window[cookieDependantKey] = [];
}

const delayedLocalStorageKey = 'delayed_local_storage';
if (typeof window !== 'undefined') {
  window[delayedLocalStorageKey] = [];
}

function setExpireDate(value) {
  let expireDate = null;
  if (value['functional']) {
    expireDate = new Date(today.getTime() + 30 * 86400000); // 30 days
  }
  if (value['performance']) {
    expireDate = new Date(today.getTime() + 3650 * 86400000); // 10 years
  }

  return expireDate;
}

function _getCookieRegexList() {
  let _cookieTypeRegexList = null;
  return function () {
    if (!_cookieTypeRegexList) {
      _cookieTypeRegexList = Object.keys(cookieTypeMappings)
        .filter(_isCookieTypeMappingKeyRegex)
        .map((it) => ({ regexString: it, regex: new RegExp(it.slice(1, -1)) }));
    }
    return _cookieTypeRegexList;
  };
}

function getCookieLevel(name) {
  let cookie_level = cookieTypeMappings[name];

  if (!cookie_level) {
    const regexObj = _getCookieRegexList()().find(({ regex }) =>
      regex.test(name)
    );
    if (regexObj) {
      cookie_level = cookieTypeMappings[regexObj.regexString];
    }
  }

  // return cookie level or throw an error if not defined
  if (cookie_level) {
    return cookie_level;
  } else {
    throw new Error(
      'Cookie permission level for cookie ' + name + ' has not been defined'
    );
  }
}

export function getCookiePermission(level) {
  if (level == 'necessary') {
    return true;
  }
  let allowed_levels = JSON.parse(getCookie('cookie_preferences')) || {};

  return !!allowed_levels[level];
}

function setCookie(name, value, expires, path = '/', domain = '', secure = '') {
  let expiryDate = expires;
  if (expiryDate === null || expiryDate === undefined) {
    expiryDate = new Date(today.getTime() + 3650 * 86400000).toUTCString(); // 10 yrs
  } else if (expiryDate instanceof Date) {
    expiryDate = expires.toUTCString();
  }

  if (hasConsent() || name == 'cookie_preferences') {
    let cookie_level = getCookieLevel(name);

    if (!getCookiePermission(cookie_level)) {
      console.log(
        'Cookie ' +
          name +
          ' does not have a permission mapping and cannot be created'
      );
      return;
    }

    if (!domain) {
      domain = location.hostname;
    }

    path = '/';
    document.cookie =
      name +
      '=' +
      encodeURIComponent(value) +
      (expires ? ';expires=' + expiryDate : '') +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      (secure ? ';secure' : '');

    /* If we're creating the cookie_preferences cookie, also create any pending cookies */
    // if (name === 'cookie_preferences')
    // 	setPendingCookies();
  } else {
    let index = -1;
    for (let i = 0; i < window[pendingCookieKey].length; i++) {
      if (window[pendingCookieKey][i].name == name) {
        index = i;
        break;
      }
    }

    /* Prevent multiple entries of cookies with the same name in the array*/
    let cookie = {
      name: name,
      value: value,
      expiryDate: expiryDate,
      path: path,
      domain: domain,
      secure: secure
    };
    if (index == -1) {
      window[pendingCookieKey].push(cookie);
    } else {
      window[pendingCookieKey][index] = cookie;
    }
  }
}

function _isCookieTypeMappingKeyRegex(type) {
  return type.startsWith('/') && type.endsWith('/');
}

function _deleteCookie(name) {
  // explicit domain from certain services

  const hostname = window.location.hostname.replace('www', '');
  const firstDotIndex = hostname.indexOf('.');

  document.cookie =
    name +
    '=; path=/; domain=.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  document.cookie =
    name +
    '=; path=/; domain=www.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  document.cookie =
    name +
    '=; path=/; domain=.www.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;';

  document.cookie =
    name +
    `=; path=/; domain=${process.env.NEXT_PUBLIC_URL}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie =
    name +
    `=; path=/; domain=.${process.env.NEXT_PUBLIC_URL}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;

  document.cookie =
    name +
    `=; path=/; domain=${hostname}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie =
    name +
    `=; path=/; domain=www.${hostname}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  document.cookie =
    name +
    `=; path=/; domain=.${hostname}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  if (firstDotIndex !== -1) {
    document.cookie =
      name +
      `=; path=/; domain=.${hostname.substring(
        firstDotIndex + 1
      )}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
  /* For testing, replace robdev with your machine name to test cookie deletion
      document.cookie = name+"=; path=/; domain=.www.robdev.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie = name+"=; path=/; domain=.www.robdev.foxtons.co.uk; expires=Thu, 01 Jan 1970 00:00:00 UTC;";*/
  // ours
  document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
}

function deleteCookie(name) {
  if (_isCookieTypeMappingKeyRegex(name)) {
    // loop all items in cookies and delete all cookies that match the regex
    const regExp = new RegExp(name.slice(1, -1));
    document.cookie.split(/; */).forEach((it) => {
      const cookieKey = it.split('=')[0];
      if (cookieKey && regExp.test(cookieKey)) {
        _deleteCookie(cookieKey);
      }
    });
  } else {
    _deleteCookie(name);
  }
}

function deleteCookiesByPermissions(permission_name) {
  let cookiesToDelete = [];
  let mappings = cookieTypeMappings;

  Object.keys(mappings).forEach(function (item) {
    if (mappings[item] == permission_name) {
      cookiesToDelete.push(item);
    }
    if (item === '_ga' && mappings[item] == permission_name) {
      const cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.startsWith(item)) {
          cookiesToDelete.push(cookie);
        }
      }
    }
  });

  cookiesToDelete.forEach(
    function (cookie) {
      deleteCookie(cookie);
    }.bind(this)
  );
}

function deleteCookiePermission(permission_name) {
  let value = JSON.parse(getCookie('cookie_preferences')) || {};
  delete value[permission_name];
  let update_cookies_level = value;
  let expire = setExpireDate(update_cookies_level);
  setCookie('cookie_preferences', JSON.stringify(update_cookies_level), expire);
  deleteCookiesByPermissions(permission_name);
}

export function setPendingCookies() {
  if (hasConsent()) {
    window[pendingCookieKey].forEach(
      function (cookie) {
        setCookie(
          cookie.name,
          cookie.value,
          cookie.expires,
          cookie.path,
          cookie.domain,
          cookie.secure
        );
      }.bind(this)
    );

    window[cookieDependantKey].forEach(function (fn) {
      fn();
    });

    // array of pending jobs is empty
    window[pendingCookieKey] = [];
    window[cookieDependantKey] = [];

    // if we try and queue anything more, immediately run it
    window[pendingCookieKey].push = function (cookie) {
      setCookie(
        cookie.name,
        cookie.value,
        cookie.expires,
        cookie.path,
        cookie.domain,
        cookie.secure
      );
    }.bind(this);
    window[cookieDependantKey].push = function (fn) {
      fn();
    };

    while (window[delayedLocalStorageKey].length > 0) {
      let callback = window[delayedLocalStorageKey].shift();
      callback();
    }
  }
}

/**
 * To avoid confusion, always provide all 3 values, even if you only want to change one
 */
interface SetAllCookiesArgs {
  performance: boolean;
  functional: boolean;
  necessary: boolean;
}

export const setAllCookies = (newValues?: SetAllCookiesArgs) => {
  const hasAcceptedPerformanceCookies = newValues
    ? newValues.necessary && newValues.performance
    : hasConsent() && getCookiePermission('performance');

  if (hasAcceptedPerformanceCookies) {
    generateFastlyCookies();
    GoogleAnalyticsConsent.grantAll();

    if (!getCookieFromPage('referrer')) {
      let referrer = document.referrer || 'none';
      if (
        referrer === 'none' ||
        referrer.match(/^https?%3A\/\/.+\.foxtons\.co\.uk/) ||
        referrer.match(/^https?:\/\/.+\.foxtons\.co\.uk/)
      ) {
        referrer = 'N%2FA';
      }

      setCookie('referrer', referrer, 0, '/');
    }
  } else if (newValues && !hasAcceptedPerformanceCookies) {
    GoogleAnalyticsConsent.revokeAll();
  }
};

// toggle for cookies permission, depends on users choices
export function setCookiePermissions(newValues: {
  functional: boolean;
  performance: boolean;
}) {
  let value = JSON.parse(getCookie('cookie_preferences')) || { necessary: 1 };
  Object.keys(newValues).forEach((key) => {
    const allowed = newValues[key];
    value[key] = allowed ? 1 : 0;
    if (!allowed) {
      deleteCookiesByPermissions(key);
    }
  });

  const expire = setExpireDate(value);
  setCookie('cookie_preferences', JSON.stringify(value), expire);
  setAllCookies({
    necessary: true,
    functional: newValues.functional,
    performance: newValues.performance
  });
}

export const allowAll = () => {
  setCookiePermissions({
    functional: true,
    performance: true
  });
  setPendingCookies();
  setAllCookies({ functional: true, necessary: true, performance: true });
  sessionStorage.setItem('cookiesUpdated', 'true');
  window.dispatchEvent(new Event('storage'));
};

export function setConsent() {
  if (!hasConsent()) {
    var expires_long = new Date(today.getTime() + 3650 * 86400000); // 10 yrs
    // set necessary cookies that are always active
    var necessary = { necessary: 1 };
    setCookie(
      'cookie_preferences',
      JSON.stringify(necessary),
      expires_long,
      '/'
    );
  }
}

const createCookieFromUrlParameter = (
  name,
  path,
  expires,
  domain = '',
  secure = ''
) => {
  var query = window.location.search.substring(1);
  var vars = query.split('&');

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == name) {
      setCookie(name, pair[1], expires, path, domain, secure);
    }
  }
};

const generateUTMCookies = () => {
  let thirtyDaysExpiry = new Date(today.getTime() + 30 * 86400000);
  let utmCookies = Object.keys(cookieTypeMappings).filter(function (key) {
    if (key.indexOf('utm_') === 0) {
      return true;
    } else {
      return false;
    }
  });

  utmCookies.forEach(function (cookie) {
    createCookieFromUrlParameter(cookie, '/', thirtyDaysExpiry);
  });
};

export const generateFastlyCookies = function () {
  generateUTMCookies();

  let url = window.location.href;
  let fastlyCookies = [];
  let longExpiry = new Date(today.getTime() + 1825 * 86400000);
  let twp2 = getCookie('twp2') || null;
  let fastly_value =
    getCookie('twp') ||
    randomStr(8, '0123456789abcdef') +
      '-' +
      randomStr(4, '0123456789abcdef') +
      '-4' +
      randomStr(3, '0123456789abcdef') +
      '-' +
      randomStr(1, '89ab') +
      randomStr(3, '0123456789abcdef') +
      '-' +
      randomStr(12, '0123456789abcdef');

  if (!twp2) {
    fastlyCookies.push({
      name: 'twp2',
      value: fastly_value,
      path: '/',
      expires: longExpiry
    });
  }

  let tws = getCookie('tws') || null;

  if (!tws) {
    fastlyCookies.push({ name: 'tws', value: fastly_value, path: '/' });
  }

  let tbpParamValue = url.match('tbp=([0-9A-Fa-f]{32})');

  if (tbpParamValue) {
    let tco = getCookie('tco') || null;

    if (!tco || tco !== 'login') {
      fastlyCookies.push({
        name: 'tbp',
        value: tbpParamValue[1],
        path: '/',
        expires: longExpiry
      });
      fastlyCookies.push({ name: 'tco', value: 'link', path: '/' });
    }
  }

  fastlyCookies.forEach((cookie) => {
    setCookie(
      cookie.name,
      cookie.value,
      cookie.expires,
      cookie.path,
      cookie.domain,
      cookie.secure
    );
  });
};

enum GoogleAnalyticsConsentAttribute {
  ad_user_data = 'ad_user_data',
  ad_personalization = 'ad_personalization',
  ad_storage = 'ad_storage',
  analytics_storage = 'analytics_storage'
}

export const GoogleAnalyticsConsent = {
  grantAll: function () {
    if (window.gtag && hasConsent() && getCookiePermission('performance')) {
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
    }
  },
  revokeAll: function () {
    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied'
      });
    }
  },
  update: function (
    attribute: GoogleAnalyticsConsentAttribute,
    consent: boolean
  ) {
    if (window.gtag) {
      window.gtag('consent', 'update', {
        [attribute]: consent ? 'granted' : 'denied'
      });
    }
  }
};
