import { PaletteColorOptions } from '@mui/material/styles';

export const boxShadow = {
  main: '0rem 0.25rem 0.75rem rgba(51, 51, 51, 0.1)',
  50: 'rgb(0 0 0 / 22%) 0 1.25rem 1.25rem 0.3125rem',
  100: '0 0 0.964rem rgba(119, 126, 144, 0.12)',
  150: '0px 0px 15.419217109680176px 0px rgba(119, 126, 144, 0.12)',
  200: '0rem 0.25rem 4rem rgba(51, 51, 51, 0.1)',
  250: '0px 4px 40px 0px rgba(0, 0, 0, 0.1)',
  300: '0px 3.8548px 15.41922px 0px rgb(151 151 151 / 16%)',
  400: '0rem 1.5rem 2rem 0rem #97979766',
  500: '0px 4px 40px 0px #0000001A'
};

const colorPalette: Record<string, PaletteColorOptions> = {
  // example custom color codes
  gradient: {
    main: '#017163',
    50: 'gradient(78.48% 78.48% at 50.11% 50.11%, rgba(0, 205, 179, 0.4) 0%, rgba(0, 255, 223, 0) 66.71%), #017163',
    100: 'linear-gradient(125.51deg, #F36374 0%, rgba(243, 99, 116, 0) 88.03%)',
    200: 'linear-gradient(125.51deg, rgba(243, 99, 116, 0.1) 0%, rgba(243, 99, 116, 0) 88.03%)',
    300: 'linear-gradient(0deg, rgba(1, 113, 99, 0.1) 0%, rgba(1, 113, 99, 0) 100%)',
    400: 'radial-gradient(59.97% 107.94% at 0% 100%, #01713B 0%, #017163 100%), radial-gradient(44.44% 46.78% at 85.17% 17.03%, rgba(112, 255, 237, 0.3) 0%, rgba(2, 114, 100, 0.3) 100%), radial-gradient(61% 61% at 38.72% 75%, #06B374 0%, #017163 100%)',
    500: 'linear-gradient(270deg, #FF6C2C1a 0%, rgba(255, 108, 44, 0) 78.52%)',
    600: 'linear-gradient(360deg, #F4FAFB 0%, rgba(244, 250, 251, 0.8) 51.87%, rgba(244, 250, 251, 0) 100%)',
    700: 'linear-gradient(180deg, #E6F6F6 0%, rgba(230, 246, 246, 0) 100%)',
    800: 'linear-gradient(272deg, #ffffff 0%, #f4fafb00 100%)',
    900: 'linear-gradient(95deg, rgba(0, 0, 0, 0.75) 39.54%, rgba(0, 0, 0, 0.00) 95.35%)',
    A100: 'linear-gradient(75deg, #004f49 30%, #12776d 100%)',
    A200: 'linear-gradient(to right, #053a38, #086e61)',
    A400: 'linear-gradient(135deg, rgba(1,113,99,1) 0%, rgba(1,72,63,1) 100%)'
  },
  primary: {
    main: '#017163',
    500: '#027365',
    400: '#E6F6F6',
    300: '#80B8B1',
    200: '#CCE3E0',
    100: '#E6F1EF',
    50: '#F2F8F7',
    600: '#03676B',
    700: '#FAFAFB',
    800: '#70ffed4d',
    900: '#0272644d',
    A100: '#0171634d',
    A200: '#01716366',
    A400: '#01716300',
    A700: '#01716333',
    mangoTango: '#F98237',
    dark: '#01664D',
    light: '#4DB6AC'
  },
  secondary: {
    main: '#FFF000',
    400: '#FFD600', // Rob - Do we need to regenerate these color values?
    300: '#FFF774',
    200: '#FFF3CC',
    500: '#34B551',
    600: '#90CCC5',
    700: '#FFF9E8'
  },
  grey: {
    main: '#333333',
    50: '#FFFFFF',
    100: '#F6F6F6',
    200: '#E0E0E0',
    300: '#999999',
    400: '#666666',
    500: '#000000',
    600: '#777E90',
    700: '#C4C4C4',
    800: '#424B5A',
    900: '#56978F',
    A100: '#0000001a',
    A200: '#FFFFFF1a',
    A400: '#000000a6',
    A700: '#00000040',
    light: '#E5E3DF'
  },
  seaGreen: {
    main: '#00BFA5',
    100: '#089b051a'
  },
  lightSeaGreen: {
    main: '#e5f9f6'
  },
  lavenderGray: {
    main: '#BBC2CE'
  },
  jasperOrange: {
    main: '#E79647'
  },
  torchRed: {
    main: '#F36374',
    100: '#D23C0D',
    200: '#DC2008'
  },
  errorRed: {
    main: '#E33045'
  },
  vineGreen: {
    main: '#34B550',
    100: '#01641C',
    200: '#047C10'
  },
  mustardYellow: {
    main: '#EAAF18',
    50: '#FDF7E8'
  },
  darkishBlue: {
    main: '#0077CD'
  },
  lightishBlue: {
    main: '#009CCD'
  },
  lightishAqua: {
    main: '#E6F6F6', // Old color = #F4FAFB
    100: '#F4FAFB80',
    200: '#15796C',
    300: '#0E838A',
    400: '#F4FAFB',
    500: '#F5FAFB'
  },
  darkestBlack: {
    main: '#3A3A3A'
  },
  bluishGrey: {
    main: '#777E90',
    50: '#bfbfbf'
  },
  darkAqua: {
    main: '#E6F6F6',
    100: '#15796C',
    200: '#017264'
  },
  faintAqua: {
    main: '#CCE3E0'
  },
  bloodRed: {
    main: '#D7001A',
    100: '#D7001A',
    200: '#d7001a0d',
    300: '#FBE6E9'
  },
  brightRed: {
    main: '#E11900',
    900: '#F9E2E5'
  },
  lightishPink: {
    main: '#FFE9EB',
    100: '#D60C6D'
  },
  lightishBluish: {
    main: '#E2EBF0'
  },
  darkGreen: {
    main: '#01713B'
  },
  seaBlue: {
    main: '#71FFEE'
  },
  paleBlue: {
    main: '#007163'
  },
  brightGreen: {
    main: '#06B374',
    50: '#97979729'
  },
  paleLightBlue: {
    main: '#00706440'
  },
  transparentPaleBlue: {
    main: '#E6F6F61a'
  },
  transparentLightBlue: {
    main: '#E6F6F4eb'
  },
  transparent: {
    main: '#00000000'
  },
  transparentWhite: {
    main: '#f4fafb00'
  },
  semiTransparentWhite: {
    main: '#fffffff2',
    800: '#ffffffcc',
    700: '#ffffffb0'
  },
  transparentBlackishGrey: {
    main: '#000000a6'
  },
  transparentGrey: {
    main: '#33333380'
  },
  pinkishRed: {
    main: '#F64A4A',
    100: '#D23C0D'
  },
  darkGray: {
    main: '#F4F6F8'
  },
  spanishGray: {
    main: '#9e9e9e'
  },
  chineseSilver: {
    main: '#C9C9C9'
  },
  brightGray: {
    main: '#EEEEEE'
  },
  platinum: {
    main: '#E5E5E5'
  },
  graniteGray: {
    main: '#616161'
  },
  gainsboro: {
    main: '#DADADA'
  },
  sonicSilver: {
    main: '#757575'
  },
  grayX11: {
    main: '#BDBDBD'
  },
  cultured: {
    main: '#F5F5F5'
  },
  darkerGray: {
    main: '#EFF1F5'
  },
  greenishSky: {
    main: 'hsla(170.4, 27.66%, 86.45%, 1)'
  },
  darkGreenishSky: {
    main: 'hsla(174, 99%, 22%, 1)'
  },
  blackOlive: {
    main: '#404040'
  },
  robinEggBlue: {
    main: '#00E1C6'
  },
  vividCerulean: {
    main: '#0099EF'
  },
  electricIndigo: {
    main: '#6200EE'
  },
  lightGray: {
    main: '#d9d9d9',
    50: '#00000029',
    100: '#0000000D',
    200: '#E5E7EB'
  },
  brightBlue: {
    main: '#2B59FF',
    100: '#0400C1',
    50: '#F1F1FF'
  },
  darkCharcoal: {
    main: '#2F2F2F',
    400: '#2F2F2F66'
  },
  lightBrown: {
    main: '#AB6612'
  },
  mediumAppleRed: {
    main: '#DF002C'
  },
  yellowPantone: {
    main: '#F7DC00'
  },
  greenLaSalle: {
    main: '#0D6928'
  },
  pinkAmaranth: {
    main: '#F5A6B3'
  },
  auroMetalSaurus: {
    main: '#767B7F'
  },
  roseGarnet: {
    main: '#8B004C'
  },
  catalinaBlue: {
    main: '#002D73'
  },
  oceanBoatBlue: {
    main: '#0076BD'
  },
  pearlAqua: {
    main: '#89CBC1'
  },
  greenMunsell: {
    main: '#00A77E'
  },
  cadmiumOrange: {
    main: '#F58538'
  },
  libertyViolet: {
    main: '#624EA0',
    100: '#9D2CD2'
  },
  appleGreen: {
    main: '#60BB46'
  },
  mangoTango: {
    main: '#F98237'
  }
};

export default colorPalette;
