import { SxProps } from '@mui/material';
import theme from '@theme/index';

const { palette } = theme;

const styles: Record<string, SxProps> = {
  root: {
    position: 'absolute',
    top: '5.625rem',
    left: 0,
    right: { xs: 0, md: 'auto' },
    zIndex: 99,
    padding: '0.25rem 1rem',
    color: palette.common.white,
    background: palette.grey.A400,
    height: '1.875rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap'
    },
    '& .MuiBreadcrumbs-li': {
      flexShrink: 0
    },
    '& .MuiBreadcrumbs-li a': {
      padding: 0,
      display: 'unset'
    },
    '& .MuiBreadcrumbs-li:last-child': {
      overflow: 'hidden',
      flexShrink: 1
    }
  },
  lastItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

export const getStyles = (breadcrumbTheme: 'dark' | 'light') => ({
  ...styles.root,
  color: breadcrumbTheme === 'light' ? palette.grey.main : palette.common.white,
  background:
    breadcrumbTheme === 'light'
      ? {
          xs: palette.semiTransparentWhite[800],
          md: `linear-gradient(270deg, transparent 0, ${palette.semiTransparentWhite[800]} 3rem)`
        }
      : {
          xs: palette.transparentGrey.main,
          md: `linear-gradient(270deg, transparent 0, ${palette.transparentGrey.main} 3rem)`
        },
  paddingRight: { xs: '1rem', md: '3rem' }
});

export default styles;
