import React from 'react';
import { Backdrop, Box } from '@mui/material';
import Loader from '../../icons/Loader';
import styles from './styles';
import { lightTheme } from '../../theme/index';

export interface LibFoxtonsLoaderProps {
  open?: boolean;
  hasBackdrop?: boolean;
  darkBg?: boolean;
}

function LibFoxtonsLoader({
  open = false,
  hasBackdrop = true,
  darkBg
}: LibFoxtonsLoaderProps): JSX.Element {
  const { palette } = lightTheme;

  return hasBackdrop ? (
    <Backdrop
      component="main"
      sx={{
        ...styles.layoutBackdrop,
        zIndex: (theme) => theme.zIndex.drawer + 999,
        backgroundColor: darkBg
          ? `00${palette.semiTransparentWhite.main}`
          : palette.semiTransparentWhite.main
      }}
      open={open}
    >
      <Box sx={styles.loaderSize}>
        <Loader sx={styles.loaderSize} />
      </Box>
    </Backdrop>
  ) : (
    open && (
      <Box sx={styles.layoutSimple}>
        <Box sx={styles.loaderSize}>
          <Loader sx={styles.loaderSize} />
        </Box>
      </Box>
    )
  );
}

export const FoxtonsLoader = React.memo(
  LibFoxtonsLoader
) as typeof LibFoxtonsLoader;

export default FoxtonsLoader;
