import {
  useEffect,
  useRef,
  useState,
  type CSSProperties,
  type ReactNode
} from 'react';

interface PopperProps {
  anchorEl: HTMLElement | null;
  placement?: 'right-start' | 'bottom-start' | 'bottom-end';
  open: boolean;
  id?: string;
  style?: CSSProperties;
  children: ReactNode;
}

function TinyPopper({
  anchorEl,
  placement = 'bottom-start',
  open,
  id,
  style,
  children
}: PopperProps) {
  const popperRef = useRef<HTMLDivElement>(null);
  const [popperStyles, setPopperStyles] = useState<CSSProperties>({
    visibility: 'hidden',
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    left: 0,
    ...style
  });

  const updatePopperPosition = () => {
    if (anchorEl && open) {
      const anchorRect = anchorEl.getBoundingClientRect();
      const popperRect = popperRef.current?.getBoundingClientRect();

      if (popperRect) {
        let top = 0;
        let left = 0;

        switch (placement) {
          case 'right-start':
            top = anchorRect.top;
            left = anchorRect.right;
            break;
          case 'bottom-start':
            top = anchorRect.bottom;
            left = anchorRect.left;
            break;
          case 'bottom-end':
            top = anchorRect.bottom;
            left = anchorRect.right - popperRect.width;
            break;
          default:
            break;
        }

        // Ensure the popper stays within the viewport horizontally
        if (left + popperRect.width > window.innerWidth) {
          left = window.innerWidth - popperRect.width;
        }

        // Ensure the popper stays within the viewport vertically
        if (top + popperRect.height > window.innerHeight) {
          top = window.innerHeight - popperRect.height;
        }

        setPopperStyles({
          top: `${top}px`,
          left: `${left}px`,
          position: 'fixed',
          zIndex: 9999,
          visibility: 'visible',
          ...style
        });
      }
    }
  };

  useEffect(() => {
    updatePopperPosition();
    window.addEventListener('resize', updatePopperPosition);

    return () => {
      window.removeEventListener('resize', updatePopperPosition);
    };
  }, [anchorEl, open, placement, style]);

  if (!open) {
    return null;
  }

  return (
    <div id={id} ref={popperRef} style={popperStyles}>
      {children}
    </div>
  );
}

export default TinyPopper;
