import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgArrowRight = (props) => (
  <SvgIcon {...props} title="ArrowRight">
    <svg viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 9h19.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59a.996.996 0 0 0 0-1.41L16.71.7a.996.996 0 1 0-1.41 1.41L20.17 7H1c-.55 0-1 .45-1 1s.45 1 1 1Z" />
    </svg>
  </SvgIcon>
);

export default SvgArrowRight;
