import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing, containers } = lightTheme;

const styles: Record<string, SxProps> = {
  root: {
    ...containers.type2,
    marginTop: '5.625rem',
    textAlign: 'center',
    minHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ctaWrapper: {
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    justifyContent: 'center'
  },
  button: {
    textTransform: 'inherit',
    width: {
      xs: '100%',
      md: 'max-content'
    }
  },
  mainHeading: {
    marginBottom: spacing(1),
    color: palette.grey.main
  },
  subHeading: {
    color: palette.grey.main
  },
  iconStyling: {
    marginLeft: spacing(1),
    fill: '#FFF',
    fontSize: {
      xs: '5rem',
      md: '10rem'
    },
    circle: {
      display: 'none'
    },
    marginBottom: spacing(1)
  }
};

export default styles;
