import { useState, useEffect, useCallback } from 'react';

interface Options {
  parseJson?: boolean;
  eventName?: string;
}

/**
 * Custom hook to read and subscribe to a particular localStorage key.
 * @param key - The localStorage key to read and subscribe to.
 * @param options - Options to configure the hook.
 * @returns A tuple containing the current value and a function to set the value.
 */
export function useLocalStorage<T>(
  key: string,
  options: Options = {}
): [T | null, (value: T | ((val: T | null) => T)) => void] {
  const { parseJson = false, eventName = 'localStorageChange' } = options;

  /**
   * Function to read the value from localStorage.
   * @returns The value from localStorage, parsed if necessary.
   */
  const readValue = useCallback((): T | null => {
    if (typeof window === 'undefined') {
      return null;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? (parseJson ? JSON.parse(item) : item) : null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error reading localStorage key “${key}”:`, error);

      return null;
    }
  }, [key, parseJson]);

  // Initialize state with the value read from localStorage
  const [storedValue, setStoredValue] = useState<T | null>(readValue);

  /**
   * Function to set the value in localStorage and update the state.
   * @param value - The new value to set, or a function that returns the new value.
   */
  const setValue = useCallback(
    (value: T | ((val: T | null) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        if (typeof window !== 'undefined') {
          window.localStorage.setItem(
            key,
            parseJson
              ? JSON.stringify(valueToStore)
              : (valueToStore as unknown as string)
          );
          window.dispatchEvent(
            new CustomEvent(eventName, { detail: { key, value: valueToStore } })
          );
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, parseJson, storedValue, eventName]
  );

  useEffect(() => {
    /**
     * Event handler for the storage event.
     * @param event - The storage event.
     */
    const handleStorageChange = (event: StorageEvent) => {
      if (event.storageArea === window.localStorage && event.key === key) {
        setStoredValue(readValue());
      }
    };

    /**
     * Event handler for the custom event.
     * @param event - The custom event.
     */
    const handleCustomEvent = (event: CustomEvent) => {
      if (event.detail.key === key) {
        setStoredValue(event.detail.value);
      }
    };

    // Add event listeners for storage and custom events
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener(eventName, handleCustomEvent);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(eventName, handleCustomEvent);
    };
  }, [key, readValue, eventName]);

  return [storedValue, setValue];
}
