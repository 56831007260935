import App, { AppContext } from 'next/app';
import { renameKeys } from './index';
import { httpCMSRequest } from './httpRequest/http-clients';

export const MyAppGetInitialProps = async (context: AppContext) => {
  // check consent on every request before making API call to get modal data on portal as getInitialProps will run every time page is reloaded as portal is client side rendered.
  // if (/^\/account/g.test(context.router.asPath)) {
  const value = `; ${context?.ctx?.req?.headers?.cookie}`;
  const separator = `; cookie_preferences=`;
  const parts = value.split(separator);

  const isPreviewCookies = value.split(`; __next_preview_data=`)?.[1];

  if (context?.router?.isPreview || isPreviewCookies) {
    context?.ctx?.res?.setHeader('Cache-Control', 'no-store');
  }

  let consent = '';

  if (parts.length === 2) {
    consent = parts.pop().split(';').shift();

    if (consent) {
      consent = JSON.parse(decodeURIComponent(consent))?.necessary;
    }
  }

  if (consent) {
    const ctx = await App.getInitialProps(context);

    return {
      ...ctx
    };
  }

  const [ctx, cookieData] = await Promise.all([
    App.getInitialProps(context),
    httpCMSRequest({
      url: `/api/get-single-type-content?strapiAPI=api/cookies-policy?populate=deep`
    })
  ]);

  const cookieContent = await renameKeys(
    cookieData?.data?.data?.attributes || {}
  );

  return {
    ...ctx,
    pageProps: {
      ...ctx.pageProps,
      cookieContent
    }
  };
};
