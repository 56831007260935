import { useState, useEffect } from 'react';
import { hasConsent } from '../../components/CookieModal/consent';

const useFirstInteraction = () => {
  const [hasInteracted, setHasInteracted] = useState(() => {
    if (typeof window === 'undefined') {
      return false;
    }

    return hasConsent();
  });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => {};
    }

    if (hasConsent()) {
      setHasInteracted(true);

      return () => {};
    }

    if (hasInteracted) return () => {};

    const onInteraction = () => setHasInteracted(true);

    window.addEventListener('mousemove', onInteraction, { once: true });
    window.addEventListener('keydown', onInteraction, { once: true });
    window.addEventListener('touchstart', onInteraction, { once: true });
    window.addEventListener('scroll', onInteraction, { once: true });
    window.addEventListener('click', onInteraction, { once: true });

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', onInteraction);
      window.removeEventListener('keydown', onInteraction);
      window.removeEventListener('touchstart', onInteraction);
      window.removeEventListener('scroll', onInteraction);
      window.removeEventListener('click', onInteraction);
    };
  }, [hasInteracted, hasConsent]);

  return hasInteracted;
};

export default useFirstInteraction;
