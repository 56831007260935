/* app entry point */
import './_global.css';
import type { AppProps } from 'next/app';
import theme from '@theme/index';
// import { ResponsiveFontSizes } from '@theme/ResponsiveFontSizes';
import createEmotionCache from '@theme/createEmotionCache';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/system/ThemeProvider';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useEffect, useState, useCallback } from 'react';
import ComponentWrapper from '@components/ComponentWrapper';
import ErrorBoundary from '@foxtons/fdl/components/ErrorBoundary/index';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { MyAppGetInitialProps } from '@foxtons/fdl/utils/customApp';
import {
  deleteCookie,
  getCookie,
  hasConsent,
  hasConsentCookie
} from '@foxtons/fdl/components/CookieModal/consent';
import useClientOnly from '@utils/customHooks/ClientOnly';
import { setAllCookies } from '@foxtons/fdl/components/CookieModal/utils';
import { getUserDetail } from '@foxtons/fdl/utils/httpRequest/index';
import { useUserFromStorage } from '@foxtons/fdl/utils/customHooks/useUserFromStorage';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { isSearchPage } from '@foxtons/fdl/utils';
import useFirstInteraction from '@foxtons/fdl/utils/customHooks/useFirstInteraction';
import { AuthWebComponentScript } from '@foxtons/fdl/components/Header/AuthWebComponent';
import PreviewBar, { usePreviewBar } from '@components/PreviewBar';
import FoxtonsLoader from '@foxtons/fdl/components/FoxtonsLoader/index';
import Breadcrumbs from '@components/Breadcrumbs';
import useGoogleTagManager from '@foxtons/fdl/utils/customHooks/useGoogleTagManager';
import GetHeader from '@components/GetHeader';

const FooterWebsiteSection = dynamic(
  () => import('@foxtons/fdl/components/FooterWebsite/index')
);
const FooterWebsite = withHydrationOnDemand({ on: ['visible'] })(
  FooterWebsiteSection
);
const BackToTop = dynamic(
  () => import('@foxtons/fdl/components/BackToTop/index'),
  { ssr: false }
);
const CookieModal = dynamic(
  () => import('@foxtons/fdl/components/CookieModal/index'),
  { ssr: false }
);
const AcquisitionModal = dynamic(
  () => import('@components/AcquisitionModal/index'),
  { ssr: false }
);

import {
  hasSeenModal as hasSeenAcquisitionModal,
  type CompanySlug
} from '@components/AcquisitionModal';

const clientSideEmotionCache = createEmotionCache();

interface AppInterface extends AppProps {
  emotionCache: EmotionCache;
}

const lastClicked = (e) => {
  if (e.target?.type === 'button') {
    sessionStorage.setItem(
      'lastClickedLink',
      e?.target?.parentNode?.href || ''
    );
  } else {
    sessionStorage.setItem('lastClickedLink', e?.target?.href || '');
  }

  return true;
};

function hideLoadingSpinner() {
  const loadingElement = document.getElementById('loading-spinner');

  if (loadingElement) {
    loadingElement.style.display = 'none';
  }
}

// function pageHydrated() {}

function showLoadingSpinner() {
  const lastClickedLink = sessionStorage.getItem('lastClickedLink');

  if (
    lastClickedLink?.indexOf('mailto:') === 0 ||
    lastClickedLink?.indexOf('tel:') === 0 ||
    lastClickedLink?.indexOf('component:') === 0
  ) {
    return;
  }

  const loadingElement = document.getElementById('loading-spinner');

  if (loadingElement) {
    loadingElement.style.display = 'block';
  }
}

function MyApp(props: AppInterface) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const hasInteracted = useFirstInteraction();
  const router = useRouter();
  const { hasMounted } = useClientOnly();
  const { isExitingPreviewMode } = usePreviewBar();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [userKey, setUserKey] = useState('');
  const [currentKey, setCurrentKey] = useState(false);
  const [urlPath, setUrlPath] = useState(router.asPath || '/');
  const [consentCookieExists, setConsentCookieExists] =
    useState(hasConsentCookie);

  const companySlug = router.query.source as CompanySlug;
  const [userInStorage, setUserInStorage] = useUserFromStorage();

  const getUserData = async () => {
    if (isFetchingUser) {
      return;
    }

    const isLoggedInCookieLabel = 'is_logged_in';

    const isLoggedIn =
      getCookie(isLoggedInCookieLabel) === 'True' || userInStorage?.isLoggedIn;

    // api call to get user and set into local storage
    // if token && token !== authenticated_user

    if (isLoggedIn) {
      setIsFetchingUser(true);
      const { response } = await getUserDetail();

      setIsFetchingUser(false);

      if (response) {
        const userData = {
          profile: response || {},
          userName: response?.emailAddress || '',
          contactId: response?.contactId || '',
          isLoggedIn: true
        };

        setUserInStorage(userData);
        setUserKey(response?.emailAddress || new Date().toDateString());
      } else if (isLoggedIn) {
        setUserInStorage({});
        setUserKey('');
        deleteCookie(isLoggedInCookieLabel);
      }
    }
  };

  useEffect(() => {
    const start = (url: string) => {
      showLoadingSpinner();
      setUrlPath(url);
    };

    const end = () => {
      hideLoadingSpinner();
      setCurrentKey((prev) => !prev);
    };

    const callTrackingAnalytics = () => {
      const isCookieUpdated = sessionStorage.getItem('cookiesUpdated');

      if (isCookieUpdated) {
        sessionStorage.removeItem('cookiesUpdated');
      }
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    window.addEventListener('storage', callTrackingAnalytics);
    window.addEventListener('load', hideLoadingSpinner);
    window.addEventListener('beforeunload', showLoadingSpinner);
    window.addEventListener('pagehide', hideLoadingSpinner);
    // window.addEventListener('DOMContentLoaded', pageHydrated);
    window.addEventListener('click', lastClicked);

    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
      window.removeEventListener('storage', callTrackingAnalytics);
      window.removeEventListener('load', hideLoadingSpinner);
      window.removeEventListener('beforeunload', showLoadingSpinner);
      window.removeEventListener('pagehide', hideLoadingSpinner);
      // window.removeEventListener('DOMContentLoaded', pageHydrated);
      window.removeEventListener('click', lastClicked);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGoogleTagManager({
    gtmId: process.env.NEXT_PUBLIC_GTAG_CONTAINER_ID as string,
    environment: process.env.NEXT_PUBLIC_ENV as string,
    afterScriptLoads: setAllCookies as () => void
  });

  useEffect(() => {
    if (hasMounted && typeof window !== 'undefined') {
      getUserData();
    }
  }, [hasMounted]);

  // useEffect(() => {
  //   // 404 back button does not rerender page and displays 404 on static pages
  //   router.beforePopState(({ as }) => {
  //     if (as !== router.asPath) {
  //       router.replace(as);
  //       return false;
  //     }
  //     return true;
  //   });

  //   return () => {
  //     router.beforePopState(() => true);
  //   };
  // }, [router]);

  //useGenesysChat(router.asPath, false);

  // if (!isSearchPage(router?.asPath) && isLoading) {
  //   return null;
  // }

  const removeSourceQuery = useCallback(() => {
    /* Softly removes 'source' query param from URL */
    if (router.query.source) {
      const { source, ...restQuery } = router.query;
      const newQuery = new URLSearchParams(
        restQuery as Record<string, string>
      ).toString();
      const newUrl = `${window.location.pathname}${
        newQuery ? `?${newQuery}` : ''
      }`;

      window.history.replaceState(null, '', newUrl);
    }
  }, [router]);

  const hasSeenModal = hasSeenAcquisitionModal(companySlug);

  useEffect(() => {
    /* Remove source query from url if user already seen the modal */
    if (hasMounted && hasSeenModal && companySlug) {
      removeSourceQuery();
    }
  }, [companySlug, hasMounted, hasSeenModal, removeSourceQuery]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        {/* TODO: changes made to address zooming issue, delete the other meta tag after deployment if successful */}
        {/* <meta name="viewport" content="initial-scale=1, width=device-width" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* Defines <auth-component>; used in rightSideNavigation.tsx (Header)
            Shows login based elements before hydration */}
        <AuthWebComponentScript />

        <CssBaseline />
        {/* <ResponsiveFontSizes /> */}
        {isLoading || router.isFallback ? null : (
          <PreviewBar key={`${currentKey}${router.isPreview}`} />
        )}

        {!pageProps?.headerProps ? null : (
          <GetHeader headerProps={pageProps.headerProps} />
        )}
        {pageProps?.breadcrumbsProps?.items.length > 0 &&
          !pageProps?.breadcrumbsProps?.hide && (
            <ComponentWrapper
              otherProps={{
                breadcrumbsItems: pageProps.breadcrumbsProps.items,
                breadcrumbsTheme: pageProps.breadcrumbsProps.theme
              }}
              key={JSON.stringify({ isLoading })}
            >
              <Breadcrumbs
                items={pageProps.breadcrumbsProps.items}
                theme={pageProps.breadcrumbsProps.theme}
              />
            </ComponentWrapper>
          )}

        <div
          style={{
            minHeight: '60vh',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {router.isFallback || isLoading ? (
            <FoxtonsLoader darkBg={true} open hasBackdrop />
          ) : null}

          <div id="loading-spinner" style={{ display: 'none' }}>
            <FoxtonsLoader darkBg={true} open hasBackdrop />
          </div>

          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </div>

        {/* Footer Integration Here */}
        {pageProps?.hasNoFooter || !pageProps?.footerProps ? null : (
          <FooterWebsite linksData={pageProps?.footerProps} />
        )}

        {!hasMounted ? null : <BackToTop />}
        {hasMounted &&
        router.isReady &&
        hasInteracted &&
        pageProps?.cookieContent &&
        !hasConsent() ? (
          <CookieModal
            cookieContent={pageProps?.cookieContent}
            onFinish={() => setConsentCookieExists(true)}
          />
        ) : null}

        {hasMounted &&
          consentCookieExists &&
          !hasSeenAcquisitionModal(companySlug) && (
            <AcquisitionModal
              consentCookieExists={consentCookieExists}
              companySlug={companySlug}
              onClose={removeSourceQuery}
            />
          )}
      </ThemeProvider>
      <script>{`window.__googleMapsCallback = function() {}`}</script>
    </CacheProvider>
  );
}

MyApp.getInitialProps = MyAppGetInitialProps;

export default MyApp;
