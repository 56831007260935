// This is a duplicate of packages\fdl\src\components\Header\index.tsx
// with the portal specific code removed. Until I can rewrite the code to
// be more efficient.
import React, { useEffect, useRef, useState, Suspense } from 'react';
import {
  Box,
  Typography,
  Button,
  List,
  ListItemText,
  Collapse,
  useMediaQuery,
  Link,
  Drawer,
  Divider,
  ClickAwayListener
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { dynamicImport } from '@foxtons/fdl/utils/reactUtils';

// src
import lightTheme from '@theme/index';
import useClientOnly from '@foxtons/fdl/utils/customHooks/useClientOnly/index';
import RightSideNavigation from './rightSideNavigation';
import TinyPopper from '@foxtons/fdl/components/Header/TinyPopper';

// styles
import styles, {
  firstDropdownPopperStyles,
  secondDropdownPopperStyles
} from '@foxtons/fdl/components/Header/styles';
import { getMyFoxtonsPortalUrl } from '@foxtons/fdl/utils/index';
import { useUserFromStorage } from '@foxtons/fdl/utils/customHooks/useUserFromStorage';

const MuiButton = dynamicImport(
  () => import('@foxtons/fdl/components/Button/index')
);
const SearchPropertyBox = React.lazy(
  () => import('@foxtons/fdl/components/SearchPropertyBox/index')
);
const SearchOfficeBox = React.lazy(
  () => import('@foxtons/fdl/components/SearchOfficeBox/index')
);

export interface LibHeaderMenuProps {
  iconMain?: JSX.Element | string;
  titleMainLogo?: JSX.Element | string;
  pathName: string;
  labelValuation?: string;
  pathValuation?: string;
  labelContactUs?: string;
  pathContactUs?: string;
  labelLogin?: string;
  pathLogin?: string;
  isHomePage?: boolean;
  onSignoutClick?: () => void;
  user: {
    email: string;
    lastName?: string;
    firstName?: string;
  };
  propsHeader?: Array<{
    id: string;
    title: string;
    titleLink?: string;
    subtitle?: Array<{
      id: string;
      tag?: string;
      subtitle: string;
      subtitleLink?: string;
      identifier?: string;
      childNav?: Array<{
        id: string;
        tag?: string;
        childName: string;
        childNavLink: string;
      }>;
    }>;
  }>;
  apiPropertySearchWidget?: {
    baseUrl: string;
    endpoint: string;
  };
  onMouseNearAuthElements?: () => void;
}

export type PropertyType = 'properties-for-sale' | 'properties-to-rent';

const COMPONENT_MAP = {
  Buy: () => import('@foxtons/fdl/components/SearchPropertyBox/index'),
  Rent: () => import('@foxtons/fdl/components/SearchPropertyBox/index'),
  Contact: () => import('@foxtons/fdl/components/SearchOfficeBox/index')
};

const prefetchComponent = async (menuTitle) => {
  const importFn = COMPONENT_MAP[menuTitle];

  if (!importFn) return;

  try {
    await importFn();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error prefetching component for ${menuTitle}:`, error);
  }
};

function LibHeaderMenu({
  iconMain,
  titleMainLogo,
  pathName,
  labelValuation = 'Get a valuation',
  pathValuation,
  labelLogin = 'Login',
  pathLogin,
  isHomePage,
  onSignoutClick,
  user,
  propsHeader,
  apiPropertySearchWidget = {
    baseUrl: '',
    endpoint: ''
  },
  onMouseNearAuthElements
}: LibHeaderMenuProps) {
  // Checking Viewport: For desktop/mobile
  const { palette, breakpoints } = lightTheme;
  const [storageUser] = useUserFromStorage();
  const isLoggedIn = storageUser?.user?.isLoggedIn;

  const isDesktopView = useMediaQuery(breakpoints.up('lg'));

  const { hasMounted } = useClientOnly();
  const wrapperRefMenu = useRef<HTMLElement | null>(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [openId, setOpenId] = useState('');
  const [openIdTwo, setOpenIdTwo] = useState('');
  // Displaying PropertyBox Button on Scroll
  const [scrollPosition, setScrollPosition] = useState(false);

  // =====================================================================================================================
  const [propTypeDropdown, setPropTypeDropdown] = useState<PropertyType>(
    'properties-for-sale'
  );

  /*
  isHomePage: allows to have widget on homepage and on mobile view only.
  For rest of the pages, tis widget has to be manually opened via navbar
  */

  // PropertySearch and Office-Search Widget
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOfficeWidget, setIsOpenOfficeWidget] = useState(false);

  // WebApp Desktop Proppers dropdown management
  const [firstMenuAnchorEl, setFirstMenuAnchorEl] = useState(null);
  const [firstMenuEl, setFirstMenuEl] = useState('');

  const [secondMenuAnchorEl, setSecondMenuAnchorEl] = useState(null);
  const [secondMenuEl, setSecondMenuEl] = useState('');

  // handle first dropdown (web-desktop)
  const handleMenuClick = (e, menuTitle) => {
    prefetchComponent(menuTitle);

    /*
        Purpose of if block here: 
          If same tab is clicked again, it would close the dropwdown
          If different tab is clicked while another was open, it would open that other tab
      */
    if (firstMenuAnchorEl === e.target) {
      setFirstMenuEl('');
      setFirstMenuAnchorEl(null);

      // closing second menu
      setSecondMenuEl('');
      setSecondMenuAnchorEl(null);

      return null;
    }

    setFirstMenuEl(menuTitle);
    setFirstMenuAnchorEl(e.target);

    // closing second menu
    setSecondMenuEl('');
    setSecondMenuAnchorEl(null);

    return null;
  };

  // handle second dropdown (web-desktop)
  const handleSubMenuClick = (e, itemType) => {
    /*
        Purpose of if block here: 
          If same tab is clicked again, it would close the dropwdown
          If different tab is clicked while another was open, it would open that other tab
      */
    if (secondMenuAnchorEl === e.target) {
      setSecondMenuEl('');
      setSecondMenuAnchorEl(null);

      return null;
    }

    setSecondMenuEl(itemType);
    setSecondMenuAnchorEl(e.target);

    return null;
  };

  // handle close all dropdowns (web-desktop)
  const handleCloseMenuDropdown = () => {
    setFirstMenuEl('');
    setFirstMenuAnchorEl(null);
    setSecondMenuEl('');
    setSecondMenuAnchorEl(null);
  };

  // =====================================================================================================================

  // Handle for closing dropwdown Menus and removing anchors--------------------------------------------
  const handleClose = () => {
    setOpenId('');
    setOpenIdTwo('');
  };

  // Handle for changing tabs and menu list -------------------------------------------------------------

  const handleClickTwo = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    e.stopPropagation();
    setOpenIdTwo(openIdTwo === id ? '' : id);
  };

  const closeMenuList = () => {
    setOpenId('');
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    if (openId === id) {
      closeMenuList();
    } else {
      setOpenId(id);
    }
  };

  // Property Search and Office Search Widgets handles ------------------------------------------------------
  const handleOpenSearchPropertyBox = (typeProp: string) => {
    // Based on sub-menu position, dropdown position vlaue of property-type is evaluated

    if (typeProp === 'Buy') {
      setPropTypeDropdown('properties-for-sale');
    } else {
      setPropTypeDropdown('properties-to-rent');
    }

    // in mobile-view, when clicked on PropertySearch, drawer must close if opened
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }

    // if page is homepage
    if (isHomePage) {
      if (isDesktopView) {
        if (window.pageYOffset > 250) {
          setIsOpen(true);
          handleCloseMenuDropdown();

          handleClose();

          return;
        }

        handleCloseMenuDropdown();
        setIsOpen(false);
        handleCloseMenuDropdown();

        return;
      }

      // if home page but mobile view: close drawer
      setIsOpen(true);

      return;
    }

    // if page is not homepage
    setIsOpen(true);
    handleCloseMenuDropdown();

    handleClose();
  };

  const handleOpenSearchOfficeBox = () => {
    // in mobile-view, when clicked on Office search, side drawer must close if open and display only the widget
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }

    setIsOpenOfficeWidget(true);
    handleCloseMenuDropdown();

    handleClose();
  };

  // Handle for closing side Drawer -------------------------------------------------------------------------
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    handleClose();
  };

  // shows search widget when value is true from SearchpropertiesBox
  useEffect(() => {
    const listenStorage = () => {
      const showWidget = sessionStorage.getItem('showSearchWidget');

      if (showWidget) {
        const searchType = sessionStorage.getItem('searchType');
        const lastSearch = localStorage.getItem('last_search');
        const lastSearchType =
          lastSearch && JSON.parse(lastSearch)?.search_type;

        setIsOpen(true);

        if (searchType === 'longLet') {
          setPropTypeDropdown('properties-to-rent');
        } else if (lastSearchType) {
          setPropTypeDropdown(lastSearchType);
        } else {
          setPropTypeDropdown('properties-for-sale');
        }

        sessionStorage.removeItem('showSearchWidget');
        sessionStorage.removeItem('searchType');
      }
    };

    window.addEventListener('storage', listenStorage);

    return () => {
      window.removeEventListener('storage', listenStorage);
    };
  }, []);

  // UseEffect -----------------------------------------------------------------------------------------------
  useEffect(() => {
    const updatePosition = () => {
      if (isHomePage && window.pageYOffset > 250) {
        setScrollPosition(true);
      } else if (isHomePage && !isDesktopView && window.pageYOffset < 250) {
        // initially true for mobile
        setScrollPosition(false);
      } else if (isHomePage && isDesktopView && window.pageYOffset < 250) {
        setScrollPosition(false);
        setIsOpen(false);
      } else {
        setScrollPosition(true);
      }
    };

    window.addEventListener('scroll', updatePosition);

    updatePosition();

    return () => window.removeEventListener('scroll', updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHomePage, isDesktopView]);

  useEffect(() => {
    const handleScroll = () => {
      // to close menu when user scroll down
      handleClose();
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const preloadBigOnesOnMobile = () => {
    if (!isDesktopView) {
      prefetchComponent('Buy');
      prefetchComponent('Contact');
    }
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrapperRefMenu.current &&
        !wrapperRefMenu.current.contains(event.target)
      ) {
        const popover = document.getElementsByClassName(
          'MuiBackdrop-invisible'
        );

        if (popover.length) {
          closeMenuList();
        } else {
          handleClose();
        }
      }
    }

    // Bind the event listener
    if (isDesktopView) {
      document.addEventListener('mousedown', handleClickOutside);

      if (isDrawerOpen) {
        handleCloseDrawer();
      }
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRefMenu, isDesktopView]);

  // If view is changed from desktop to mobile while the dropdowns are open,
  // close the dropdowns.
  useEffect(() => {
    if (!isDesktopView) {
      handleCloseMenuDropdown();
    }
  }, [isDesktopView]);

  return (
    <Box sx={styles.main}>
      {/* Left Side Logo (dektop) */}
      <Box>
        <Link
          href={pathName}
          sx={{ ...styles.link, ...styles.logoLink }}
          aria-label="Logo"
        >
          <Box sx={styles.icon}>{iconMain}</Box>
          <Box sx={styles.internalBox}>
            <Box sx={{ svg: { path: { fill: palette.grey['500'] } } }}>
              {titleMainLogo}
            </Box>
          </Box>
        </Link>
      </Box>

      {/* -------------------- Website: Mega Menu Body (desktops) -------------------------- */}

      <Box sx={styles.menuHeaderContainer}>
        <Box
          sx={styles.menuHeaderBoxWeb}
          ref={wrapperRefMenu}
          id="megamenu-box"
        >
          {propsHeader.map((itemW) => (
            <Button
              key={itemW?.id}
              type="button"
              disableFocusRipple
              disableTouchRipple
              onClick={(e) => handleMenuClick(e, itemW?.title)}
              sx={
                firstMenuEl === itemW?.title
                  ? styles.navButtonHighlighted
                  : styles.navButtonNormal
              }
            >
              <Box sx={styles.linkBtnTypographyNavBarBox}>
                <Typography variant="body1" sx={styles.linkBtnTypographyNavBar}>
                  {itemW?.title}
                </Typography>
              </Box>
            </Button>
          ))}
        </Box>
        {/* -------------------- Dropdowns using Poppers ------------------------ */}
        {(isDesktopView || !hasMounted) && (
          <Box visibility={hasMounted ? 'visible' : 'hidden'}>
            {propsHeader.map((itemW, index) => (
              <TinyPopper
                key={`${itemW?.title}-${index}`}
                anchorEl={firstMenuAnchorEl}
                open={firstMenuEl === itemW?.title || !hasMounted}
                placement="bottom-start"
                id="popper-first-level"
                style={firstDropdownPopperStyles}
              >
                <ClickAwayListener onClickAway={handleCloseMenuDropdown}>
                  <Box id="clickaway">
                    {itemW?.subtitle?.map((itemX) => (
                      <Box
                        sx={styles.btnLinkUnit}
                        id="submenu-unit"
                        key={itemX?.id}
                      >
                        {itemX?.childNav?.length > 0 ? (
                          <>
                            {/* First dropwdown buttons only */}
                            <Box sx={styles.btnContainer}>
                              <Button
                                type="button"
                                onClick={(e) =>
                                  handleSubMenuClick(e, itemX.subtitle)
                                }
                                sx={
                                  secondMenuEl === itemX.subtitle
                                    ? styles.btnFirstDropdownOpen
                                    : styles.btnFirstDropdown
                                }
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={styles.linkBtnTypography}
                                >
                                  {itemX.subtitle}
                                </Typography>
                              </Button>
                              <ExpandMore
                                sx={
                                  secondMenuEl === itemX.subtitle
                                    ? styles.dropdownIconOpen
                                    : styles.dropdownIconClose
                                }
                              />
                            </Box>
                            {/* Nested Menu */}
                            <TinyPopper
                              anchorEl={secondMenuAnchorEl}
                              placement="right-start"
                              open={
                                secondMenuEl === itemX.subtitle || !hasMounted
                              }
                              id="popper-second-level"
                              disablePortal
                              style={secondDropdownPopperStyles}
                            >
                              {/* Second dropdown starts from here */}
                              {itemX?.childNav?.map((itemY) => (
                                <Box sx={styles.btnLinkUnit} key={itemY?.id}>
                                  {itemY?.childNavLink ? (
                                    // second-dropdown: anchor-links
                                    <Box sx={styles.firstDropdownLinkBox}>
                                      <Link
                                        href={
                                          itemY?.childName ===
                                            'Register to find a new home?' &&
                                          hasMounted &&
                                          user?.email
                                            ? process.env
                                                .NEXT_PUBLIC_PORTAL_VERSION ===
                                              'old'
                                              ? '/account/applicant/lettings/register'
                                              : '/account/my-lettings/my-properties-search?jumpTheQueue=true'
                                            : itemY?.childNavLink
                                        }
                                        sx={styles.linkFirstDropdown}
                                        variant="medium"
                                      >
                                        <Typography variant="subtitle2">
                                          {itemY?.childName}
                                        </Typography>
                                      </Link>
                                    </Box>
                                  ) : (
                                    // second-dropdown: button
                                    <Box sx={styles.btnContainer}>
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          handleOpenSearchPropertyBox(
                                            itemX?.tag
                                          );
                                        }}
                                        sx={styles.btnFirstDropdown}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          sx={styles.linkBtnTypography}
                                        >
                                          {itemY?.childName}
                                        </Typography>
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </TinyPopper>
                          </>
                        ) : (
                          // First dropwdown Links only
                          <Box>
                            {/* Special Property/Office Search Widget in first dropdown */}
                            {itemX?.identifier === 'Buy' ||
                            itemX?.identifier === 'Long Let' ||
                            itemX?.identifier === 'Office' ||
                            itemX?.identifier === 'MyFoxtons' ? (
                              <Box sx={styles.btnContainerWidget}>
                                {itemX?.identifier === 'MyFoxtons' ? (
                                  <Link
                                    href={
                                      hasMounted && isLoggedIn
                                        ? getMyFoxtonsPortalUrl()
                                        : itemX?.subtitleLink
                                    }
                                    sx={{
                                      ...styles.linkFirstDropdown,
                                      paddingLeft: '1rem',
                                      paddingY: 0
                                    }}
                                    variant="medium"
                                  >
                                    <MuiButton
                                      variant="tertiary"
                                      fullWidth
                                      sx={{
                                        ...styles.btnFirstDropdown,
                                        pb: '1rem'
                                      }}
                                    >
                                      {itemX.subtitle}
                                    </MuiButton>
                                  </Link>
                                ) : (
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      itemX?.identifier === 'Buy' ||
                                      itemX?.identifier === 'Long Let'
                                        ? handleOpenSearchPropertyBox(
                                            itemX?.identifier
                                          )
                                        : handleOpenSearchOfficeBox()
                                    }
                                    sx={styles.btnFirstWidget}
                                  >
                                    <SearchIcon sx={styles.searchIconWeb} />
                                    <Typography
                                      variant="subtitle2"
                                      sx={styles.linkBtnTypography}
                                    >
                                      {itemX.subtitle}
                                    </Typography>
                                  </Button>
                                )}

                                <Divider
                                  variant="middle"
                                  sx={styles.dividerWeb}
                                />
                              </Box>
                            ) : (
                              <Box sx={styles.firstDropdownLinkBox}>
                                <Link
                                  href={itemX?.subtitleLink}
                                  sx={styles.linkFirstDropdown}
                                  variant="medium"
                                >
                                  <Typography variant="subtitle2">
                                    {itemX?.subtitle}
                                  </Typography>
                                </Link>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </ClickAwayListener>
              </TinyPopper>
            ))}
          </Box>
        )}
      </Box>

      {/* Property Search Box - currently only for Web : && isHomePage && window.pageYOffset > 250 */}
      {scrollPosition && isOpen ? (
        <ClickAwayListener
          onClickAway={() => {
            setIsOpen(false);
          }}
        >
          <Box sx={styles.propertySearchComponent}>
            <Suspense fallback={null}>
              <SearchPropertyBox
                isInNavbar
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                propertyTypeDrowdownOption={propTypeDropdown}
                apiPropSearch={apiPropertySearchWidget}
              />
            </Suspense>
          </Box>
        </ClickAwayListener>
      ) : null}

      {/* Property Office Box - currently only for Web */}
      {isOpenOfficeWidget ? (
        <ClickAwayListener
          onClickAway={() => {
            setIsOpenOfficeWidget(false);
          }}
        >
          <Box sx={styles.officeSearchComponent}>
            <Suspense fallback={null}>
              <SearchOfficeBox
                isOpen={isOpenOfficeWidget}
                onClose={() => setIsOpenOfficeWidget(false)}
              />
            </Suspense>
          </Box>
        </ClickAwayListener>
      ) : null}

      {/* Right Side Btns/Drawer */}
      <Box sx={styles.rightBox}>
        {/* Right-side Navigation */}
        {propsHeader ? (
          <RightSideNavigation
            userDetails={{
              email: user?.email,
              firstName: user?.firstName,
              lastName: user?.lastName
            }}
            valuation={{ label: labelValuation, href: pathValuation }}
            login={{ label: labelLogin, href: pathLogin }}
            handleSignout={onSignoutClick}
            handleDrawerToggle={() => {
              preloadBigOnesOnMobile();
              setIsDrawerOpen(!isDrawerOpen);
            }}
            drawerState={isDrawerOpen}
            handleDrawerClose={() => setIsDrawerOpen(false)}
            hasNotifications
            onMouseEnter={onMouseNearAuthElements}
          />
        ) : null}

        {/* Drawer for Menu items in mobile view */}
        <Drawer
          open={isDrawerOpen}
          anchor="top"
          variant="persistent"
          onClose={() => handleCloseDrawer()}
          sx={{
            '& .MuiPaper-root': {
              transform: 'translateY(5.6rem) !important'
            }
          }}
        >
          <Box sx={styles.drawer}>
            {/* MegaMenu */}
            <Box id="megaMenuMobile" sx={styles.mobileMegaMenuBox}>
              <List sx={styles.mainMobile} component="nav">
                {propsHeader?.map((item) =>
                  item.titleLink ? (
                    <Link
                      href={item.titleLink}
                      key={item.id}
                      sx={
                        item.title === 'Valuation'
                          ? styles.link
                          : styles.firstHeading
                      }
                    >
                      {item.title === 'Valuation' ? (
                        <MuiButton
                          variant="primary"
                          sx={styles.ValuationDrawer}
                        >
                          {labelValuation}
                        </MuiButton>
                      ) : (
                        item.title
                      )}
                    </Link>
                  ) : (
                    <Button
                      onClick={(e) => handleClick(e, item.id)}
                      sx={styles.firstButton}
                      key={item.id}
                    >
                      <Box sx={styles.firstHeadingExpanded}>
                        {openId === item.id ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText
                          primary={item.title}
                          primaryTypographyProps={{ variant: 'subtitle2' }}
                        />
                      </Box>
                      <Collapse
                        in={openId === item.id}
                        timeout="auto"
                        unmountOnExit
                        sx={styles.mobileCollapseSecondBox}
                      >
                        <List
                          component="div"
                          disablePadding
                          sx={styles.textLeft}
                        >
                          {item.subtitle?.map((subtArray, idX) =>
                            subtArray.childNav?.length > 0 ? (
                              <Box
                                role="button"
                                onClick={(e) => handleClickTwo(e, subtArray.id)}
                                sx={styles.secondButton}
                                key={subtArray.id}
                              >
                                <Box
                                  sx={
                                    openIdTwo === subtArray.id
                                      ? isDesktopView
                                        ? {
                                            ...styles.secondHeadingExpandableOpen,
                                            ...styles.leftPaddingLong
                                          }
                                        : {
                                            ...styles.secondHeadingExpandableOpen,
                                            ...styles.leftPaddingShort
                                          }
                                      : isDesktopView
                                      ? {
                                          ...styles.secondHeadingExpanded,
                                          ...styles.leftPaddingLong
                                        }
                                      : {
                                          ...styles.secondHeadingExpanded,
                                          ...styles.leftPaddingShort
                                        }
                                  }
                                  key={subtArray.id}
                                >
                                  <ListItemText
                                    primary={subtArray.subtitle}
                                    primaryTypographyProps={{
                                      variant: 'subtitle2'
                                    }}
                                  />
                                  {openIdTwo === subtArray.id ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </Box>
                                {idX === item.subtitle.length - 1 ? null : (
                                  <Divider
                                    variant="middle"
                                    sx={styles.divider}
                                  />
                                )}
                                <Collapse
                                  in={openIdTwo === subtArray.id}
                                  timeout="auto"
                                  unmountOnExit
                                  sx={styles.w100}
                                >
                                  <div />
                                  <List
                                    component="div"
                                    disablePadding
                                    sx={styles.flexCol}
                                  >
                                    {subtArray.childNav?.map((childNavArr) => (
                                      <React.Fragment key={childNavArr.id}>
                                        {childNavArr.childName ===
                                        'Find a Property' ? (
                                          <Box sx={styles.linkFindPropertyMb}>
                                            <Typography
                                              variant="subtitle2"
                                              component="span"
                                              onClick={() => {
                                                handleOpenSearchPropertyBox(
                                                  childNavArr?.tag
                                                );
                                              }}
                                              sx={styles.thirdHeading}
                                              style={{
                                                paddingLeft: isDesktopView
                                                  ? '5rem'
                                                  : '3rem'
                                              }}
                                            >
                                              {childNavArr?.childName}
                                            </Typography>
                                          </Box>
                                        ) : (
                                          <Link
                                            sx={styles.thirdHeading}
                                            href={
                                              childNavArr.childName ===
                                                'Register to find a new home?' &&
                                              hasMounted &&
                                              user?.email
                                                ? process.env
                                                    .NEXT_PUBLIC_PORTAL_VERSION ===
                                                  'old'
                                                  ? '/account/applicant/lettings/register'
                                                  : '/account/my-lettings/my-properties-search?jumpTheQueue=true'
                                                : childNavArr.childNavLink
                                            }
                                            style={{
                                              paddingLeft: isDesktopView
                                                ? '5rem'
                                                : '3rem'
                                            }}
                                          >
                                            {childNavArr.childName}
                                          </Link>
                                        )}

                                        <Divider
                                          variant="middle"
                                          sx={styles.divider}
                                        />
                                      </React.Fragment>
                                    ))}
                                  </List>
                                </Collapse>
                              </Box>
                            ) : (
                              <Box key={subtArray.id}>
                                <Box sx={styles.subtLinkBox} key={subtArray.id}>
                                  {/* Special: Property Search Button */}
                                  {subtArray?.identifier === 'Buy' ||
                                  subtArray?.identifier === 'Long Let' ||
                                  subtArray?.identifier === 'Office' ||
                                  subtArray?.identifier === 'MyFoxtons' ? (
                                    <>
                                      {subtArray?.identifier === 'MyFoxtons' ? (
                                        <Link
                                          href={
                                            hasMounted && isLoggedIn
                                              ? getMyFoxtonsPortalUrl()
                                              : subtArray?.subtitleLink
                                          }
                                          variant="medium"
                                          style={{ paddingLeft: '0.5rem' }}
                                        >
                                          <MuiButton
                                            variant="tertiary"
                                            sx={{
                                              ...styles.secondHeading,
                                              ...(isDesktopView
                                                ? styles.headingLeftPaddingLong
                                                : styles.leftPaddingShort)
                                            }}
                                          >
                                            <Typography
                                              component="span"
                                              variant="subtitle2"
                                              fontWeight={400}
                                            >
                                              {subtArray.subtitle}
                                            </Typography>
                                          </MuiButton>
                                        </Link>
                                      ) : (
                                        <MuiButton
                                          sx={
                                            isDesktopView
                                              ? {
                                                  ...styles.propertySearchBtn,
                                                  ...styles.leftPaddingLong
                                                }
                                              : {
                                                  ...styles.propertySearchBtn,
                                                  ...styles.leftPaddingShort
                                                }
                                          }
                                          onClick={() =>
                                            subtArray?.identifier === 'Buy' ||
                                            subtArray?.identifier === 'Long Let'
                                              ? handleOpenSearchPropertyBox(
                                                  subtArray?.identifier
                                                )
                                              : handleOpenSearchOfficeBox()
                                          }
                                        >
                                          <SearchIcon sx={styles.searchIcon} />
                                          {/* {subtArray?.subtitle} */}
                                          <Typography
                                            component="span"
                                            variant="subtitle2"
                                            fontWeight={400}
                                          >
                                            {subtArray.subtitle}
                                          </Typography>
                                        </MuiButton>
                                      )}
                                      {isDesktopView && (
                                        <Divider
                                          variant="middle"
                                          sx={styles.divider}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        sx={
                                          isDesktopView
                                            ? {
                                                ...styles.secondHeading,
                                                ...styles.headingLeftPaddingLong
                                              }
                                            : {
                                                ...styles.secondHeading,
                                                ...styles.leftPaddingShort
                                              }
                                        }
                                        href={subtArray.subtitleLink}
                                      >
                                        {subtArray?.subtitle}
                                      </Link>
                                      {isDesktopView && (
                                        <Divider
                                          variant="middle"
                                          sx={styles.divider}
                                        />
                                      )}
                                    </>
                                  )}
                                </Box>
                                {idX === item.subtitle.length - 1 ? null : (
                                  <Divider
                                    variant="middle"
                                    sx={styles.divider}
                                  />
                                )}
                              </Box>
                            )
                          )}
                        </List>
                      </Collapse>
                    </Button>
                  )
                )}
              </List>
            </Box>
            <Box sx={styles.footerBtns}>
              <Box sx={styles.signOutButtonWrapperMobile} gap="0.15rem">
                <Box sx={styles.webBox}>
                  <Link
                    href={pathValuation}
                    sx={{
                      ...styles.link,
                      display: { md: 'none', xs: 'flex' }
                    }}
                  >
                    <MuiButton
                      color="primary"
                      variant="primary-dark"
                      sx={styles.ValuationDrawer}
                    >
                      {labelValuation}
                    </MuiButton>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
}

export const Header = React.memo(LibHeaderMenu);

export default Header;
