import React from 'react';
import { SvgIcon } from '@mui/material';
const SvgFlagEn = (props) => (
  <SvgIcon {...props} title="FlagEn">
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#flagEN_svg__a)">
        <path
          d="M13.75 17.165h-7.5a2.917 2.917 0 0 1-2.916-2.916v-7.5a2.917 2.917 0 0 1 2.917-2.917h7.5a2.917 2.917 0 0 1 2.916 2.917v7.5a2.917 2.917 0 0 1-2.916 2.916Z"
          fill="#41479B"
        />
        <path
          d="M16.667 14.252v-.64l-2.068-1.355h2.068v-.702h-5.614v5.614h.701v-4.26l4.452 2.917c.157-.245.28-.516.358-.804M3.53 15.305c.071.184.16.358.265.521l4.451-2.916v4.259h.702v-5.614H3.334v.702h2.068L3.334 13.61v.641c0 .112.007.223.019.332M3.334 6.848v.541l2.068 1.355H3.334v.702h5.614V3.832h-.702v4.259L3.795 5.174a2.9 2.9 0 0 0-.38.89M16.467 5.685c-.07-.18-.158-.35-.261-.51L11.755 8.09V3.832h-.702v5.614h5.614v-.702h-2.068l2.068-1.355v-.64c0-.116-.007-.231-.02-.344"
          fill="#F5F5F5"
        />
        <path
          d="M11.053 3.832H8.948v5.614H3.334v2.105h5.614v5.614h2.105v-5.614h5.614V9.446h-5.614V3.832Z"
          fill="#FF4B55"
        />
        <path
          d="m6.939 12.258-3.585 2.327c.028.252.088.494.177.722l4.697-3.05h-1.29ZM12.305 12.258l4.26 2.765a2.92 2.92 0 0 0 .103-.77l-3.074-1.995h-1.29ZM7.545 8.747l-4.13-2.68c-.053.22-.081.448-.081.684v.1l2.922 1.896h1.289ZM13.045 8.739l3.603-2.34a2.895 2.895 0 0 0-.18-.72l-4.712 3.06h1.289Z"
          fill="#FF4B55"
        />
      </g>
      <defs>
        <clipPath id="flagEN_svg__a">
          <path
            fill="#fff"
            transform="translate(3.334 3.832)"
            d="M0 0h13.333v13.333H0z"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
export default SvgFlagEn;
