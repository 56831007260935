import { SxProps } from '@mui/material';
import theme from '@theme/index';

const { spacing, palette } = theme;

const styles: Record<string, SxProps> = {
  modal: {
    '--modal-margin': spacing(0.5),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    width: 'calc(100% - var(--modal-margin) * 2)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '2rem 2rem 1.5rem',
    outline: 0,
    boxSizing: 'border-box'
  },
  modalContent: {
    backgroundColor: palette.background.paper,
    'h2, h3, h4, h5, h6, p': {
      marginBottom: theme.spacing(1),
      textAlign: 'center'
    },
    a: {
      color: palette.grey.main
    },
    h2: {
      color: palette.primary.main,
      fontWeight: '400',
      fontSize: {
        xs: '1.25rem',
        md: '1.5rem'
      }
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: spacing(0.5)
  }
};

export default styles;
