import { createTheme } from '@mui/material/styles';
import colorPalette from './colorPalette';

export const FONT_FAMILY = 'var(--open-sans)'; // From: _global.css

/*
  FONTS VARIANTS (Weights)
  BP-LightItalic: 300
  BP-Regular    : 400
  BP-Medium     : 500
  BP-Bold       : 700
  BP-ExtraBold  : 800
  BP-Heavy      : 900
*/

export const lightTheme = createTheme({
  spacing: (factor) => {
    if (typeof factor === 'string') return factor;

    return `${Math.round(19 * factor)}px`;
  },
  typography: {
    fontFamily: FONT_FAMILY
  },
  palette: {
    ...colorPalette,
    mode: 'light'
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: { color: colorPalette.primary.main }
        }
      ]
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: FONT_FAMILY
        },
        h2: {
          fontWeight: 800,
          fontSize: '4.67rem',
          lineHeight: '4rem'
        }
      },

      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontWeight: 700,
            fontSize: '3.5rem',
            lineHeight: '3.5rem'
          }
        },
        {
          props: { variant: 'h2' },
          style: {
            fontWeight: 800,
            fontSize: '4rem',
            lineHeight: '4rem'
          }
        },
        {
          props: { variant: 'body1' },
          style: { fontFamily: FONT_FAMILY }
        },

        {
          props: { variant: 'light-italic' },
          style: {
            fontSize: '1.25rem',
            lineHeight: '1.25rem',
            fontWeight: 300,
            fontStyle: 'italic'
          }
        },
        {
          props: { variant: 'meduim-italic' },
          style: {
            fontStyle: 'italic',
            fontWeight: 500,
            fontSize: '3.5rem',
            lineHeight: '3.5rem'
          }
        },
        {
          props: { variant: 'regular' },
          style: { fontFamily: FONT_FAMILY, fontWeight: 400 }
        },
        {
          props: { variant: 'medium' },
          style: { fontWeight: 500 }
        },
        {
          props: { variant: 'bold' },
          style: { fontWeight: 700 }
        },
        {
          props: { variant: 'extra-bold' },
          style: { fontWeight: 800 }
        },
        {
          props: { variant: 'heavy' },
          style: { fontWeight: 800 }
        }
      ]
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: 'currentColor'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      ss: 410,
      sm: 600,
      smd: 760,
      md: 900,
      mm: 1050,
      lg: 1200,
      ml: 1400,
      xl: 1536,
      xxl: 1800
    }
  }
});

export const darkTheme = createTheme(lightTheme, {
  palette: {
    mode: 'dark'
  }
});

const { spacing } = lightTheme;

lightTheme.containers = {
  type1: {
    padding: {
      xs: spacing(2),
      md: spacing(4)
    }
  },
  type2: {
    paddingX: { xs: spacing(1), md: spacing(4) },
    paddingY: spacing(2)
  }
};

export const globalSectionPadding = {
  xs: spacing(1),
  md: spacing(4)
};

export const globalLayout = {
  paddingX: globalSectionPadding,
  paddingY: spacing(2),
  maxWidth: '140rem',
  marginLeft: 'auto',
  marginRight: 'auto'
};
