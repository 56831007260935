import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette } = lightTheme;

const layoutCommon = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

const styles: Record<string, SxProps> = {
  layoutBackdrop: {
    ...layoutCommon,
    backgroundColor: `${palette.semiTransparentWhite.main}`
  },
  layoutSimple: {
    ...layoutCommon
  },
  loaderSize: {
    width: '100%',
    height: '4.5rem'
  }
};

export default styles;
