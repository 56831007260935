import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, breakpoints, spacing } = lightTheme;

const foxtonsBtn = {
  fontSize: '1rem',
  lineHeight: '1rem',
  color: palette.primary.main,
  backgroundColor: palette.primary['400'],
  padding: '0.75rem 1.5rem',
  border: `0.063rem solid ${palette.primary['400']}`,
  borderRadius: '0.25rem',
  width: '100%'
};

export const dropdownUnitLinkBtn: React.CSSProperties = {
  paddingLeft: '2rem',
  paddingRight: '0',
  paddingTop: '0',
  paddingBottom: '0',
  textTransform: 'none',
  fontSize: '1rem',
  color: palette.grey.main
};

export const firstDropdownPopperStyles: React.CSSProperties = {
  background: palette.lightishAqua['400'],
  cursor: 'pointer',
  minWidth: '19rem',
  border: `1px solid ${palette.primary['200']}`,
  paddingTop: '1rem',
  paddingBottom: '1rem'
};

export const secondDropdownPopperStyles: React.CSSProperties = {
  background: palette.lightishAqua['400'],
  cursor: 'pointer',
  minWidth: '20rem',
  border: `1px solid ${palette.primary['200']}`,
  paddingTop: '1rem',
  paddingBottom: '1rem'
};

export const dropdownArrowsBasic = {
  marginLeft: 'auto',
  position: 'absolute',
  right: '2rem'
};

const styles: Record<string, SxProps> = {
  main: {
    top: 0,
    left: '0',
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'flexStart',
    alignItems: 'center',
    gap: {
      xs: '0',
      sm: '3rem',
      md: '0.25rem'
    },
    height: '5.625rem',
    px: {
      xs: '1.125rem',
      sm: '1.125rem',
      md: '1rem',
      lg: '1rem'
    },
    transition: '0.5s',
    zIndex: '100',
    backgroundColor: palette.grey['50'],
    boxShadow: `${palette.grey.A100} 0 0.5rem 1.75rem 0`,
    '& .MuiTabs-flexContainer ': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .MuiTypography-body1 > div': {
      padding: '0'
    },
    '& .MuiTabs-root': {
      minHeight: '0'
    }
  },
  leftPaddingShort: {
    paddingLeft: '1.5rem'
  },
  leftPaddingLong: {
    paddingLeft: '2rem'
  },
  headingLeftPaddingLong: {
    paddingLeft: '2.5rem'
  },
  tabStyles: {
    '& button, a': {
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1rem',
      textTransform: 'capitalize',
      color: palette.grey['50']
    },
    '& .MuiTab-root.Mui-selected': {
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1rem',
      color: palette.primary.main
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },

  menuHeaderBox: {
    position: 'relative',
    display: {
      xs: 'none',
      lg: 'flex'
    },
    gap: '1.5rem',
    marginLeft: '2.25rem',
    height: '100%'
  },

  headerButton: {
    marginInline: '0rem',
    fontFamily: 'Bliss Pro',
    fontWeight: 700,
    paddingBottom: '1.8rem',
    borderBottom: `0.25rem solid transparent`,
    // paddingBottom: '2.05rem', // Robert test padding changes between applications

    // styles to apply on navbar items when each item is hovered
    '&:hover': {
      color: palette.primary.main,
      borderBottom: `0.25rem solid ${palette.primary.main}`,
      '&:after': {
        height: '0.25rem',
        background: palette.primary.main,
        width: '100%'
      }
    }
  },
  portalLinkStyles: {
    textDecoration: 'none'
  },
  highlightedTab: {
    paddingBottom: '1.8rem',
    borderBottom: `0.25rem solid ${palette.primary.main}`,
    fontWeight: 700,
    marginInline: '0rem',
    color: palette.primary.main,
    '&:hover': {
      color: palette.primary.main
    }
  },

  tabStylesWhite: {
    height: '100%',
    alignItems: 'center',
    '& div': {
      height: '100%'
    },
    '& button': {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
      textTransform: 'none',
      color: palette.grey.main,
      padding: 0,
      marginX: '1.22rem',
      minWidth: 'auto',
      ':hover': {
        background: 'none'
      }
    },
    '& a': {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
      textTransform: 'none',
      color: palette.grey.main,
      padding: 0,
      marginX: '1.22rem',
      minWidth: 'auto',
      alignItems: 'end',
      ':hover': {
        background: 'none'
      }
    },

    // styles applied when tab is selected on click
    '& .MuiTab-root.Mui-selected': {
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: '1rem',
      color: palette.primary.main,
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '0',
        bottom: '0',
        height: '0.25rem',
        background: palette.primary.main,
        width: '100%'
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },

  tabPanelBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5rem'
  },

  dropDownBtns: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1rem',
    textTransform: 'capitalize',
    color: palette.primary.main,
    padding: '1.75rem 2rem',
    gap: '0.5rem',
    width: '100%',
    justifyContent: 'flex-start',
    height: '4.5rem'
  },

  dropDownBtnsNotSelected: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1rem',
    textTransform: 'capitalize',
    color: palette.grey.main,
    padding: '1.75rem 2rem',
    gap: '0.5rem',
    width: '100%',
    justifyContent: 'flex-start',
    height: '4.5rem'
  },

  menuDropdown: {
    width: '100%',
    height: '100%',
    left: '0.063rem',
    zIndex: '-1300',
    top: '5.625rem',
    '& .MuiBackdrop-root': {
      top: '5.625rem',
      display: 'none'
    },
    '& .MuiPaper-root': {
      // NOTE: Using important to override MUI inline styles
      transform: 'translateY(-5.625rem) !important',
      width: '19%',
      boxShadow: 'none',
      borderRadius: '0',
      border: 'none',
      backgroundColor: '#E6F6F6'
    },
    '& .MuiList-root': {
      padding: '0'
    },
    '& .MuiMenuItem-root': {
      padding: '0'
    },
    '& .MuiButton-text': {
      width: '100%',
      padding: '1.5rem 2rem',
      fontSize: '1rem',
      lineHeight: '1rem',
      fontWeight: '500',
      color: palette.grey.main,
      textTransform: 'capitalize',
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'left'
    }
  },
  w100: {
    width: '100%'
  },
  textLeft: {
    textAlign: 'left'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },

  mainMobile: {
    width: '100%',
    bgcolor: 'background.paper',
    display: 'flex',
    flexDirection: 'column'
  },
  firstHeading: {
    width: '100%',
    padding: '0.75rem 0.5rem 0.75rem 0rem',
    color: palette.grey.main,
    textDecoration: 'none',
    ':hover': {
      backgroundColor: palette.primary[100]
    }
  },
  firstHeadingExpanded: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem',
    ':hover': {
      backgroundColor: palette.primary[100]
    },
    '& span': {
      color: palette.grey.main
    },
    '& svg': {
      fill: palette.grey.main
    },
    '& div': {
      textAlign: 'left'
    }
  },
  firstButton: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    width: '100%',
    textTransform: 'none'
  },
  secondHeading: {
    width: '100%',
    padding: '1.75rem 2rem',
    lineHeight: '1rem',
    color: palette.grey.main,
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    height: '4.5rem',

    ':hover': {
      backgroundColor: palette.primary[100]
    }
  },
  secondHeadingExpanded: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '1.75rem 2rem',

    height: '4.5rem',
    ':hover': {
      backgroundColor: palette.primary[100]
    },
    '& span': {
      color: palette.grey.main,
      textTransform: 'none'
    },
    '& svg': {
      fill: palette.grey.main,
      mr: '0rem'
    },
    '& div': {
      textAlign: 'left'
    }
  },
  secondHeadingExpandableOpen: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '1.75rem 2rem',
    height: '4.5rem',
    ':hover': {
      backgroundColor: palette.primary[100]
    },
    '& span': {
      fontSize: '0.875rem',
      fontWeight: '600',
      lineHeight: '1rem',
      color: palette.primary.main,
      textTransform: 'none'
    },
    '& svg': {
      fill: palette.primary.main,
      mr: '0rem'
    },
    '& div': {
      textAlign: 'left'
    }
  },
  secondButton: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    width: '100%'
  },
  thirdHeading: {
    fontSize: '.85rem',
    width: '100%',
    padding: '1.5rem 2rem',
    textAlign: 'left',
    color: palette.grey.main,
    textTransform: 'none',
    textDecoration: 'none',
    display: 'inline-block',
    // height: '3.7rem',

    ':hover': {
      backgroundColor: palette.primary[100]
    }
  },

  webBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '1rem',
    marginBottom: '1rem'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    width: {
      xs: '100%',
      md: 'auto'
    },
    pt: { xs: '0.4rem', md: '0.3rem' }
  },
  logoLink: {
    overflow: 'hidden'
  },
  icon: {
    '& svg': {
      height: {
        xs: '2.25rem',
        sm: '3.5rem'
      },
      width: {
        xs: '2.25rem',
        sm: '3.5rem'
      }
    }
  },
  internalBox: {
    display: 'flex',
    alignItems: 'center',
    height: {
      xs: '1.25rem',
      sm: '1.5625rem'
    },
    ml: '0.5rem',
    svg: {
      height: 'auto',
      width: {
        xs: '5rem',
        sm: '6.875rem'
      }
    }
  },
  drawer: {
    width: {
      xs: '100vw'
    },
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  signOutButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  signOutButtonWrapperMobile: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '1rem',
    marginBottom: '4rem'
  },
  loggedInUserText: {
    fontSize: '0.75rem',
    color: palette.grey[300],
    '& span': {
      color: palette.grey.main
    }
  },
  signoutBtn: {
    textTransform: 'uppercase',
    padding: 0,
    ':hover': {
      background: 'transparent'
    },
    height: 'auto'
  },
  signoutArrow: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    position: 'relative',
    top: '0.125rem',

    svg: { path: { fill: palette.darkestBlack[100] } }
  },
  rightBtnLinkBox: {
    display: { lg: 'flex', xs: 'none' },
    gap: '1rem'
  },
  btnMyfoxton: {
    ...foxtonsBtn,
    padding: {
      [breakpoints.up('xs')]: { padding: '0.75rem 0.5rem' },
      [breakpoints.up('ss')]: { padding: '0.75rem 1.5rem' }
    },
    height: '2.8rem'
  },
  btnLogin: {
    ...foxtonsBtn,
    backgroundColor: palette.grey['50'],
    border: `1px solid ${palette.grey['50']}`,
    color: palette.grey.main
  },
  btnContactUs: {
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: '900',
    color: palette.primary.main,
    backgroundColor: palette.grey['50'],
    padding: '0.75rem 1.5rem',
    border: `0.063rem solid ${palette.primary['400']}`,
    borderRadius: '0.25rem',
    width: '100%'
  },
  ValuationDrawer: {
    width: '100%',
    height: '2.8rem',
    textTransform: 'none'
  },
  btnContactUsDrawer: {
    fontSize: '1.5rem',
    color: palette.primary.main
  },

  iconButtonClose: {
    display: {
      lg: 'none',
      xs: 'flex'
    },
    backgroundColor: '#e6f6f6',

    marginLeft: '0.5rem',
    borderRadius: '25%',
    color: 'blue'
  },
  subtLinkBox: {
    display: 'flex'
  },
  mobileMegaMenuBox: {
    pt: '0',
    pb: '2.5rem',
    pr: '1rem',
    pl: '1rem',
    '.MuiLink-root': {
      fontSize: '.85rem',
      fontWeight: '400'
    }
  },
  // TODO: to find suitable alternative before deleting the bottom block
  // subtitleBlueBox: {
  //   position: 'fixed',
  //   top: '5.6rem'
  // },

  dropdownMainBox: {
    position: 'absolute',
    top: '4.55rem',
    left: '0',
    display: 'flex',
    justifyContent: 'center'
  },

  secondPanelBox: {
    backgroundColor: '#E6F6F6',
    border: '0.047rem solid #80B8B1'
  },

  widthOfTabBox: {
    width: '23rem',
    marginLeft: '-0.063rem'
  },

  mobileLogoBox: {
    padding: '1rem',
    backgroundColor: palette.grey['50']
  },
  footerBtns: {
    pt: '1rem',
    pb: '2.5rem',
    px: '2rem',
    marginTop: 'auto'
  },

  propertySearchBtn: {
    fontSize: '1rem',
    lineHeight: '1rem',
    textTransform: 'none',
    color: palette.primary.main,
    padding: '1.75rem 2rem',
    gap: '0.5rem',
    width: '100%',
    justifyContent: 'flex-start',
    height: '4.5rem'
  },

  searchIcon: {
    fill: palette.primary.main
  },

  propertySearchComponent: {
    position: 'absolute',
    top: {
      xs: `calc(var(--header-height) + ${spacing(0.5)})`,
      md: '8rem'
    },
    left: {
      xs: spacing(0.5),
      md: 'calc(50% - 22rem)'
    },
    width: {
      xs: `calc(100% - ${spacing(0.5)} * 2)`,
      md: 'auto'
    }
  },

  officeSearchComponent: {
    position: 'absolute',
    top: {
      xs: '5.6rem',
      md: '6rem'
    },
    left: '50%',
    transform: 'translateX(-50%)',
    width: {
      xs: '100%',
      md: 'auto'
    }
  },

  divider: {
    marginLeft: '1.875rem',
    marginRight: '1.875rem',
    borderColor: '#CCE3E0'
  },

  dividerWeb: {
    borderColor: palette.primary['200'],
    borderLeftWidth: '15rem',
    marginLeft: '1.875rem',
    marginRight: '1.875rem'
  },

  // Right Side Btn Box/Drawer
  rightBox: {
    minWidth: {
      xs: 'auto',
      lg: '16rem'
    },
    marginLeft: 'auto',
    zIndex: '-1'
  },

  contactUsBtn: {
    textDecoration: 'none',
    display: 'flex',
    button: {
      color: palette.primary.main,
      textTransform: 'capitalize',
      fontWeight: '700',
      ':hover': {
        background: 'transparent'
      }
    }
  },

  mobileCollapseSecondBox: {
    background: palette.lightishAqua['400'],
    width: '100%',
    border: `0.063rem solid ${palette.grey['200']}`
  },

  mapHeaderShadow: {
    boxShadow: `0rem 0.063rem 1.5rem 0rem ${palette.lightGray['100']}`
  },

  // Desktop MegaMenu Website ========================================================|
  menuHeaderContainer: {
    height: '100%'
  },

  menuHeaderBoxWeb: {
    position: 'relative',
    display: {
      xs: 'none',
      lg: 'flex'
    },
    gap: '1.44rem',
    marginLeft: '2.25rem',
    height: '100%'
  },

  navButtonHighlighted: {
    padding: '0',
    minWidth: '0',
    color: palette.primary.main,
    fontSize: '1rem',
    textTransform: 'none',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '0.25rem',
      background: palette.primary.main,
      width: 'calc(100% - 1rem)',
      left: '1rem'
    },
    // styles to apply on navbar items when each item is hovered
    '&:hover': {
      color: palette.primary.main,
      background: 'transparent'
    }
  },

  navButtonNormal: {
    padding: '0',
    minWidth: '0',
    fontSize: '1rem',
    color: palette.grey.main,
    textTransform: 'none',
    // styles to apply on navbar items when each item is hovered
    '&:hover': {
      color: palette.primary.main,
      background: 'transparent',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '0',
        height: '0.25rem',
        background: palette.primary.main,
        width: 'calc(100% - 1rem)',
        left: '1rem'
      }
    }
  },

  btnLinkUnit: {
    width: '100%'
  },

  // dropdown-first: button
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },

  btnFirstDropdown: {
    ...dropdownUnitLinkBtn,
    display: 'flex',
    width: '100%',
    zIndex: '5',
    justifyContent: 'flex-start'
  },

  btnFirstDropdownOpen: {
    ...dropdownUnitLinkBtn,
    display: 'flex',
    width: '100%',
    zIndex: '5',
    justifyContent: 'flex-start',
    color: palette.primary.main
  },

  // Search and office widget
  btnFirstWidget: {
    ...dropdownUnitLinkBtn,
    display: 'flex',
    width: '100%',
    zIndex: '5',
    justifyContent: 'flex-start',
    color: palette.primary.main,
    gap: '0.25rem'
  },

  btnContainerWidget: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },

  searchIconWeb: {
    fill: palette.primary.main,
    fontSize: '1.25rem'
  },

  // dropdown-first: arrow icons
  dropdownIconOpen: {
    ...dropdownArrowsBasic,
    color: palette.primary.main,
    transform: 'rotateZ(-90deg)'
  },
  dropdownIconClose: {
    ...dropdownArrowsBasic,
    transform: 'rotateZ(-90deg)'
  },

  // dropdown-first: anchor links box
  firstDropdownLinkBox: {
    ...dropdownUnitLinkBtn,
    width: '100%',
    '&:hover': {
      background: palette.primary['400']
    }
  },

  linkFirstDropdown: {
    display: 'inline-block',
    textDecoration: 'none',
    width: '100%',
    color: palette.grey.main,
    py: '1rem',
    '&:hover': {
      color: palette.primary.main
    }
  },

  linkBtnTypography: {
    width: '100%',
    display: 'flex',
    py: '1rem',
    '&:hover': {
      color: palette.primary.main
    }
  },

  linkBtnTypographyNavBarBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  linkBtnTypographyNavBar: {
    width: '100%',
    display: 'flex',
    py: '1rem',
    pl: '1rem',
    alignItems: 'center',
    height: '100%',
    fontWeight: '600',
    color: palette.grey['500']
  },

  linkFindPropertyMb: {
    // paddingY: '1rem',
    width: '100%',
    ':hover': {
      backgroundColor: palette.primary[100]
    }
  }
};

export default styles;
